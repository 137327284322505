import { Line } from 'react-chartjs-2';
import { forwardRef, useMemo } from 'react';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
// utils, interfaces
import { LineChartProps } from 'interfaces/PropsTypes';
import { defaultLineChartDataset, labels, showIntegerValues } from './utils';

const options: ChartOptions<'line'> = {
  responsive: true,

  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: showIntegerValues,
      },
    },
  },
};

const LineChart = forwardRef<ChartJSOrUndefined<'line'>, LineChartProps>(({ id, data }, ref) => {
  const datasets = useMemo<ChartData<'line'>>(() => {
    const chartData: ChartData<'line'> = {
      labels: labels,
      datasets: [defaultLineChartDataset],
    };

    if (data?.length) {
      const datasets: ChartDataset<'line'>[] = [
        {
          ...defaultLineChartDataset,
          data: data,
        },
      ];

      chartData.datasets = datasets;

      return chartData;
    }
    return chartData;
  }, [data]);

  return <Line ref={ref} data={datasets} key={id} id={id} options={options} />;
});

export default LineChart;
