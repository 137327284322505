import { ChartArea, ChartDataset } from 'chart.js';
import { SimplePaletteColorOptions } from '@mui/material';
// constants
import palette from 'theme/palette';

const { primary } = palette || {};
const { main } = (primary as SimplePaletteColorOptions) || {};

type ChartColors = {
  red: string;
  orange: string;
  yellow: string;
  yellowLight: [number, number, number];
  green: string;
  blue: string;
  purple: string;
  grey: string;
};

export const CHART_COLORS: ChartColors = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  yellowLight: [255, 206, 86],

  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)',
};

export const createGradient = (
  ctx: CanvasRenderingContext2D,
  chartArea: ChartArea,
  color: [number, number, number],
) => {
  const [red, green, blue] = color;
  const gradient = ctx.createLinearGradient(0, 0, 0, chartArea.height);

  gradient.addColorStop(0, `rgba(${red}, ${green}, ${blue}, 0.8)`); // Start color (semi-transparent)
  gradient.addColorStop(1, `rgba(${red}, ${green}, ${blue}, 0)`); // End color (fully transparent)
  return gradient;
};

export const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const defaultLineChartDataset: ChartDataset<'line'> = {
  data: [0],
  borderColor: CHART_COLORS.yellow,
  backgroundColor: (context) => {
    const chart = context.chart;
    const { ctx, chartArea } = chart;
    if (!chartArea) {
      // Skip if chart area is not yet available (e.g., during initialization)
      return CHART_COLORS.yellow;
    }
    return createGradient(ctx, chartArea, CHART_COLORS.yellowLight);
  },
  fill: true,
  tension: 0.4,
};

export const defaultBarHorizontalDataset: ChartDataset<'bar'> = {
  data: [0],
  barThickness: 10,
  borderRadius: 10,
  backgroundColor: main || CHART_COLORS.yellow,
};

export const chartPieColors = ['#826AF9', '#B3995B', '#2D99FF', '#FFCE56'];

export const showIntegerValues = (value: string | number): string | number | null => {
  if (Number.isInteger(value)) {
    return value;
  }
  return null;
};
