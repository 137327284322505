import { FC } from 'react';
import { View, Text, StyleSheet, Svg, Path, Defs, LinearGradient, Stop } from '@react-pdf/renderer';
import { IconProps } from 'interfaces';

interface HeaderProps {
  title: string;
}

export const LogoIcon: FC<IconProps> = ({ width = 165, height = 55 }) => (
  <Svg width={width} height={height} viewBox="0 0 973 327" fill="none">
    <Path
      d="M376.962 155.948L388.39 83.8655H445.267C469.578 83.8655 477.036 93.1625 477.036 105.659C477.036 118.746 470.938 133.371 441.682 133.371H407.344L403.814 155.948H376.962ZM438.866 115.354C446.036 115.354 448.852 112.059 448.852 107.705C448.852 103.915 446.325 101.883 440.803 101.883H412.316L410.187 115.354H438.866Z"
      fill="#AD8E55"
    />
    <Path
      d="M536.62 144.316H496.417L489.439 155.948H461.969L506.924 83.8655H542.869L569.323 155.948H540.479L536.62 144.316ZM522.184 101.402L507.171 126.272H530.617L522.184 101.402Z"
      fill="#AD8E55"
    />
    <Path
      d="M595.241 103.531H565.203L568.307 83.8655H655.305L652.119 103.531H622.204L613.866 155.948H586.904L595.241 103.531Z"
      fill="#AD8E55"
    />
    <Path
      d="M665.099 83.8655H691.937L687.817 109.339H730.931L734.915 83.8655H761.753L750.312 155.948H723.514L727.882 128.483H684.864L680.496 155.948H653.657L665.099 83.8655Z"
      fill="#AD8E55"
    />
    <Path
      d="M87.4931 189.579L84.6774 207.212H35.2307L33.7748 216.22H82.26L79.5129 233.949H30.9041L26.5501 261.647H0L11.4277 189.579H87.4931Z"
      fill="#AD8E55"
    />
    <Path
      d="M89.6221 230.749C89.6185 228.091 89.779 225.436 90.1029 222.798C92.0395 209.725 99.2093 188.219 144.165 188.219H148.532C189.216 188.219 197.265 206.525 197.265 219.997C197.282 222.554 197.121 225.11 196.784 227.646C194.834 241.021 187.966 263.007 142.612 263.007H138.258C97.7534 263.007 89.6221 244.372 89.6221 230.749ZM145.14 207.596C124.207 207.596 118.878 216.797 118.013 224.254C117.857 225.41 117.789 226.576 117.807 227.742C117.807 235.006 122.559 243.534 141.747 243.534C162.35 243.534 167.707 234.237 168.97 226.286C169.166 225.037 169.262 223.774 169.259 222.51C169.259 215.245 164.122 207.596 145.14 207.596Z"
      fill="#AD8E55"
    />
    <Path
      d="M227.386 261.647H200.534L211.975 189.579H276.215C295.018 189.579 302.668 196.445 302.668 206.813C302.668 218.156 292.985 223.581 285.527 225.325C293.177 227.549 298.891 232.878 297.435 242.175L295.595 253.518C295.011 256.038 295.043 258.662 295.691 261.167L295.595 261.647H268.125C267.74 260.975 267.644 259.903 268.028 257.528L269.292 249.576C270.666 241.337 267.451 236.984 256.697 236.984H231.259L227.386 261.647ZM265.089 219.516C271.778 219.516 274.099 215.932 274.099 212.54C274.099 209.148 272.259 207.115 266.737 207.115H236.012L233.979 219.475L265.089 219.516Z"
      fill="#AD8E55"
    />
    <Path
      d="M324.658 261.647L307.119 189.579H336.292L345.907 237.039L366.345 189.579H396.384L404.432 236.462L426.409 189.579H454.634L417.549 261.647H386.384L377.374 216.028L357.403 261.647H324.658Z"
      fill="#AD8E55"
    />
    <Path
      d="M507.583 250.03H467.367L460.389 261.647H432.919L477.984 189.579H513.929L540.383 261.647H511.553L507.583 250.03ZM493.093 207.115L478.121 232.013H501.567L493.093 207.115Z"
      fill="#AD8E55"
    />
    <Path
      d="M572.592 261.647H545.753L557.181 189.579H621.421C640.21 189.579 647.874 196.445 647.874 206.813C647.874 218.156 638.177 223.581 630.719 225.325C638.37 227.549 644.097 232.878 642.641 242.175L640.801 253.518C640.209 256.037 640.242 258.663 640.897 261.167L640.801 261.647H613.331C612.946 260.975 612.85 259.903 613.234 257.528L614.498 249.576C615.872 241.337 612.657 236.984 601.903 236.984H576.465L572.592 261.647ZM610.281 219.516C616.957 219.516 619.292 215.932 619.292 212.54C619.292 209.148 617.451 207.115 611.929 207.115H581.204L579.171 219.475L610.281 219.516Z"
      fill="#AD8E55"
    />
    <Path
      d="M661.239 189.579H708.227C745.82 189.579 756.671 204.3 756.671 220.573C756.68 223.362 756.487 226.148 756.094 228.909C753.855 243.823 743.595 261.744 702.801 261.647H649.797L661.239 189.579ZM679.548 242.573H699.601C727.511 242.573 728.569 227.549 728.569 222.798C728.569 216.316 725.08 208.461 704.93 208.461H684.973L679.548 242.573Z"
      fill="#AD8E55"
    />
    <Path
      d="M282.739 291.941V300.346H258.455V304.644H282.23V313.035H258.455V326.232H245.475V291.9L282.739 291.941Z"
      fill="#D6D6D6"
    />
    <Path
      d="M313.904 291.296C336.059 291.296 338.929 301.815 338.929 308.324V309.698C338.929 316.125 336.182 326.918 313.904 326.918H311.789C289.538 326.918 286.722 316.125 286.722 309.698V308.324C286.722 301.815 289.469 291.296 311.789 291.296H313.904ZM312.832 300.524C302.49 300.524 300.32 305.001 300.32 308.654V309.203C300.32 312.815 302.682 317.649 312.832 317.649C322.983 317.649 325.345 312.952 325.345 309.299V308.654C325.345 305.001 323.038 300.524 312.832 300.524Z"
      fill="#D6D6D6"
    />
    <Path
      d="M356.648 326.287H343.544V291.955H374.751C384.818 291.955 389.2 295.182 389.2 300.772V301.28C389.2 306.265 384.86 308.297 382.332 308.942C386.535 309.917 389.392 312.678 389.392 317.182V322.263C389.267 323.588 389.489 324.923 390.038 326.136V326.369H376.66C376.314 325.837 376.164 325.201 376.234 324.57V320.56C376.234 316.591 374.435 314.6 369.27 314.6H356.703L356.648 326.287ZM356.648 306.21H371.756C374.627 306.21 375.726 304.919 375.726 303.257C375.726 301.65 374.668 300.36 371.756 300.36H356.648V306.21Z"
      fill="#D6D6D6"
    />
    <Path
      d="M394.282 291.941H412.289L422.81 311.236L433.523 291.941H450.802V326.273H437.823V304.85L425.79 326.287H419.088L407.083 304.864V326.287H394.296L394.282 291.941Z"
      fill="#D6D6D6"
    />
    <Path
      d="M456.2 291.941H469.358V307.322C469.358 311.442 471.199 316.55 479.88 316.55C488.56 316.55 490.36 311.51 490.36 307.322V291.941H503.477V308.324C503.477 314.985 500.331 326.836 480.855 326.836H479.001C459.016 326.836 456.2 314.985 456.2 308.324V291.941Z"
      fill="#D6D6D6"
    />
    <Path d="M521.937 291.941V316.454H545.753V326.287H508.875V291.955L521.937 291.941Z" fill="#D6D6D6" />
    <Path
      d="M583.745 320.752H564.145L561.508 326.245H548.212L565.203 291.914H582.839L600.804 326.245H586.492L583.745 320.752ZM573.773 300.346L568.142 312.156H579.625L573.773 300.346Z"
      fill="#D6D6D6"
    />
    <Path
      d="M610.268 301.321H595.447V291.941H638.205V301.321H623.371V326.287H610.268V301.321Z"
      fill="#D6D6D6"
    />
    <Path
      d="M667.749 291.296C689.904 291.296 692.775 301.815 692.775 308.324V309.698C692.775 316.125 689.959 326.918 667.749 326.918H665.675C643.411 326.918 640.595 316.125 640.595 309.698V308.324C640.595 301.815 643.411 291.296 665.675 291.296H667.749ZM666.692 300.524C656.349 300.524 654.179 305.001 654.179 308.654V309.203C654.179 312.815 656.528 317.649 666.692 317.649C676.856 317.649 679.205 312.952 679.205 309.299V308.654C679.205 305.001 676.883 300.524 666.692 300.524Z"
      fill="#D6D6D6"
    />
    <Path
      d="M710.507 326.287H697.39V291.955H728.596C738.664 291.955 743.046 295.182 743.046 300.772V301.28C743.046 306.265 738.719 308.297 736.178 308.942C740.381 309.917 743.252 312.678 743.252 317.182V322.263C743.118 323.588 743.341 324.925 743.897 326.136V326.369H730.505C730.165 325.835 730.019 325.199 730.093 324.57V320.56C730.093 316.591 728.28 314.6 723.116 314.6H710.562L710.507 326.287ZM710.507 306.21H725.616C728.486 306.21 729.599 304.919 729.599 303.257C729.599 301.65 728.528 300.36 725.616 300.36H710.507V306.21Z"
      fill="#D6D6D6"
    />
    <Path
      d="M813.287 298.561V298.478C814.236 297.484 815.542 296.907 816.916 296.875C818.29 296.843 819.621 297.358 820.615 298.307C821.61 299.255 822.186 300.56 822.219 301.934C822.251 303.308 821.736 304.639 820.787 305.633C820.726 305.7 820.662 305.764 820.595 305.825V305.894C819.614 306.824 818.309 307.334 816.958 307.315C815.606 307.296 814.316 306.75 813.362 305.792C812.408 304.835 811.866 303.543 811.852 302.192C811.838 300.84 812.353 299.537 813.287 298.561Z"
      fill="url(#paint0_linear_2281_70)"
    />
    <Path
      d="M833.506 277.33L833.643 277.179C834.727 276.043 836.218 275.383 837.788 275.346C839.358 275.309 840.878 275.896 842.015 276.98C843.151 278.063 843.811 279.554 843.848 281.124C843.885 282.693 843.297 284.213 842.214 285.35L841.967 285.57L841.802 285.721C840.691 286.826 839.186 287.445 837.619 287.441C836.051 287.437 834.549 286.811 833.444 285.7C832.338 284.589 831.719 283.085 831.723 281.518C831.727 279.951 832.353 278.449 833.464 277.344L833.506 277.33Z"
      fill="url(#paint1_linear_2281_70)"
    />
    <Path
      d="M853.792 256.141L853.944 255.976C854.536 255.297 855.259 254.746 856.071 254.355C856.882 253.964 857.764 253.741 858.664 253.701C859.564 253.66 860.462 253.803 861.305 254.119C862.149 254.436 862.919 254.92 863.569 255.543C864.22 256.166 864.738 256.914 865.091 257.742C865.444 258.57 865.626 259.462 865.625 260.362C865.624 261.263 865.44 262.154 865.085 262.981C864.73 263.809 864.21 264.556 863.558 265.177L863.325 265.41L863.174 265.561C861.904 266.704 860.244 267.315 858.537 267.269C856.83 267.223 855.205 266.523 854 265.314C852.794 264.105 852.098 262.478 852.057 260.771C852.016 259.064 852.632 257.406 853.779 256.141H853.792Z"
      fill="url(#paint2_linear_2281_70)"
    />
    <Path
      d="M874.079 234.883L874.23 234.732C875.591 233.337 877.448 232.535 879.396 232.5C881.345 232.465 883.229 233.2 884.639 234.544C886.049 235.889 886.872 237.736 886.929 239.684C886.986 241.632 886.273 243.523 884.944 244.949L884.669 245.21L884.518 245.361C883.109 246.644 881.259 247.334 879.353 247.288C877.447 247.242 875.633 246.462 874.287 245.112C872.942 243.762 872.17 241.945 872.131 240.039C872.092 238.134 872.79 236.287 874.079 234.883Z"
      fill="url(#paint3_linear_2281_70)"
    />
    <Path
      d="M894.38 213.638L894.531 213.474C896.04 211.991 898.067 211.153 900.183 211.139C902.299 211.124 904.337 211.933 905.867 213.395C907.397 214.856 908.298 216.856 908.379 218.969C908.46 221.083 907.715 223.146 906.302 224.721L906.013 225.009L905.849 225.146C904.302 226.565 902.267 227.33 900.17 227.283C898.072 227.236 896.073 226.38 894.592 224.894C893.111 223.408 892.262 221.406 892.222 219.309C892.183 217.211 892.956 215.179 894.38 213.638Z"
      fill="url(#paint4_linear_2281_70)"
    />
    <Path
      d="M914.667 192.38L914.763 192.188C915.567 191.345 916.53 190.669 917.596 190.197C918.662 189.726 919.81 189.47 920.975 189.442C922.14 189.415 923.299 189.617 924.386 190.038C925.473 190.458 926.467 191.089 927.31 191.893C928.153 192.697 928.83 193.66 929.301 194.725C929.772 195.791 930.029 196.939 930.056 198.104C930.083 199.269 929.881 200.428 929.461 201.515C929.04 202.601 928.41 203.594 927.605 204.438C927.507 204.55 927.401 204.655 927.289 204.753L927.138 204.904C925.464 206.529 923.218 207.429 920.885 207.409C918.552 207.39 916.321 206.453 914.674 204.801C913.027 203.149 912.097 200.915 912.086 198.583C912.074 196.251 912.981 194.007 914.612 192.339L914.667 192.38Z"
      fill="url(#paint5_linear_2281_70)"
    />
    <Path
      d="M934.967 171.136L935.118 170.971C936.88 169.117 939.305 168.039 941.862 167.973C944.418 167.908 946.896 168.86 948.75 170.621C950.605 172.382 951.683 174.807 951.749 177.363C951.815 179.919 950.862 182.397 949.101 184.251L948.757 184.594L948.593 184.745C946.763 186.414 944.359 187.313 941.882 187.254C939.406 187.195 937.048 186.182 935.3 184.427C933.552 182.673 932.549 180.311 932.499 177.835C932.45 175.358 933.359 172.959 935.036 171.136H934.967Z"
      fill="url(#paint6_linear_2281_70)"
    />
    <Path
      d="M935.105 143.245L934.953 143.094C933.148 141.293 932.133 138.849 932.13 136.299C932.128 133.75 933.138 131.304 934.94 129.499C936.741 127.694 939.186 126.679 941.736 126.676C944.286 126.674 946.733 127.684 948.538 129.485L948.689 129.636C950.539 131.392 951.616 133.81 951.683 136.36C951.75 138.909 950.802 141.381 949.046 143.231C947.29 145.082 944.871 146.159 942.321 146.226C939.771 146.293 937.298 145.344 935.448 143.588L935.105 143.245Z"
      fill="url(#paint7_linear_2281_70)"
    />
    <Path
      d="M955.46 164.229L955.296 164.064C953.379 162.153 952.301 159.56 952.297 156.855C952.293 154.15 953.364 151.553 955.275 149.638C957.186 147.722 959.779 146.644 962.485 146.64C965.191 146.636 967.788 147.707 969.704 149.617L969.869 149.782C971.721 151.663 972.771 154.19 972.795 156.83C972.819 159.47 971.815 162.016 969.997 163.93C968.179 165.844 965.687 166.977 963.049 167.089C960.411 167.201 957.833 166.284 955.859 164.531L955.46 164.229Z"
      fill="url(#paint8_linear_2281_70)"
    />
    <Path
      d="M914.763 121.946L914.612 121.795C913.044 120.115 912.189 117.892 912.227 115.594C912.266 113.296 913.195 111.103 914.818 109.477C916.441 107.85 918.633 106.917 920.931 106.873C923.229 106.829 925.454 107.679 927.138 109.243L927.303 109.394C928.148 110.197 928.827 111.158 929.3 112.223C929.774 113.287 930.033 114.435 930.063 115.6C930.093 116.765 929.893 117.924 929.475 119.012C929.057 120.1 928.428 121.094 927.626 121.939C926.823 122.784 925.862 123.463 924.797 123.936C923.732 124.409 922.584 124.669 921.419 124.699C920.254 124.729 919.094 124.529 918.007 124.111C916.919 123.693 915.924 123.064 915.079 122.262L914.763 121.946Z"
      fill="url(#paint9_linear_2281_70)"
    />
    <Path
      d="M894.517 100.647L894.366 100.496C892.84 98.9715 891.982 96.9035 891.98 94.7466C891.979 92.5897 892.835 90.5207 894.359 88.9947C895.884 87.4686 897.952 86.6106 900.11 86.6093C902.267 86.608 904.336 87.4636 905.863 88.9878L906.014 89.1388C906.788 89.8746 907.411 90.7558 907.845 91.732C908.279 92.7081 908.516 93.7603 908.544 94.8282C908.571 95.8962 908.388 96.9591 908.005 97.9563C907.621 98.9534 907.045 99.8653 906.309 100.64C905.573 101.414 904.692 102.036 903.715 102.471C902.739 102.905 901.687 103.142 900.619 103.169C899.55 103.197 898.487 103.014 897.49 102.63C896.493 102.247 895.581 101.671 894.806 100.935L894.517 100.647Z"
      fill="url(#paint10_linear_2281_70)"
    />
    <Path
      d="M874.23 79.3613L874.079 79.1965C872.691 77.8089 871.912 75.9268 871.912 73.9644C871.912 72.002 872.691 70.1199 874.079 68.7323C875.467 67.3446 877.35 66.5651 879.312 66.5651C881.275 66.5651 883.158 67.3446 884.545 68.7323L884.71 68.8833C885.456 69.5431 886.062 70.3466 886.49 71.2457C886.918 72.1449 887.161 73.1212 887.203 74.1162C887.245 75.1113 887.085 76.1045 886.734 77.0365C886.383 77.9686 885.848 78.8202 885.16 79.5404C884.472 80.2606 883.646 80.8346 882.731 81.228C881.815 81.6214 880.83 81.8261 879.834 81.8299C878.838 81.8337 877.852 81.6365 876.933 81.2501C876.015 80.8636 875.185 80.296 874.491 79.581L874.23 79.3613Z"
      fill="url(#paint11_linear_2281_70)"
    />
    <Path
      d="M853.943 58.0621L853.792 57.8973C853.175 57.2805 852.684 56.5481 852.35 55.7419C852.015 54.9358 851.842 54.0716 851.842 53.1987C851.841 52.3258 852.012 51.4614 852.346 50.6547C852.679 49.8481 853.169 49.115 853.785 48.4973C854.402 47.8797 855.135 47.3895 855.941 47.0549C856.748 46.7203 857.612 46.5477 858.485 46.5471C859.358 46.5465 860.223 46.7177 861.029 47.0512C861.836 47.3846 862.569 47.8737 863.187 48.4904L863.338 48.6415C864.053 49.2206 864.639 49.9413 865.062 50.7582C865.484 51.5751 865.732 52.4706 865.791 53.3882C865.85 54.3058 865.718 55.2257 865.404 56.0899C865.09 56.9541 864.6 57.744 863.966 58.4097C863.331 59.0753 862.566 59.6025 861.718 59.9579C860.87 60.3133 859.957 60.4892 859.037 60.4746C858.118 60.46 857.211 60.2552 856.375 59.8731C855.538 59.491 854.79 58.9398 854.177 58.2543L853.943 58.0621Z"
      fill="url(#paint12_linear_2281_70)"
    />
    <Path
      d="M833.643 36.7628L833.492 36.6118C832.398 35.4992 831.787 33.9997 831.793 32.4394C831.799 30.8791 832.421 29.3843 833.523 28.2801C834.626 27.1759 836.12 26.5516 837.681 26.5432C839.241 26.5347 840.742 27.1427 841.857 28.2349L842.008 28.3859C842.574 28.9216 843.029 29.5634 843.348 30.275C843.666 30.9865 843.841 31.7538 843.863 32.5329C843.884 33.312 843.752 34.0878 843.474 34.8159C843.196 35.5441 842.777 36.2103 842.241 36.7765C841.705 37.3428 841.063 37.798 840.352 38.1162C839.64 38.4344 838.873 38.6093 838.093 38.631C837.314 38.6527 836.538 38.5207 835.81 38.2426C835.082 37.9644 834.415 37.5456 833.849 37.01L833.643 36.7628Z"
      fill="url(#paint13_linear_2281_70)"
    />
    <Path
      d="M813.356 15.4636V15.395C812.422 14.4183 811.907 13.1152 811.921 11.764C811.935 10.4128 812.477 9.12054 813.43 8.16325C814.384 7.20596 815.675 6.65949 817.026 6.64053C818.378 6.62156 819.683 7.13162 820.663 8.06177C821.157 8.52975 821.555 9.09047 821.832 9.71192C822.11 10.3334 822.262 11.0034 822.28 11.6837C822.299 12.364 822.183 13.0413 821.94 13.6769C821.697 14.3125 821.33 14.894 820.862 15.3881C820.394 15.8822 819.833 16.2793 819.212 16.5568C818.59 16.8342 817.92 16.9865 817.24 17.005C816.559 17.0235 815.882 16.9078 815.246 16.6645C814.61 16.4213 814.029 16.0552 813.535 15.5872L813.356 15.4636Z"
      fill="url(#paint14_linear_2281_70)"
    />
    <Path
      d="M767.013 303.491L767.096 303.422C767.564 302.921 768.127 302.518 768.752 302.237C769.378 301.955 770.053 301.801 770.738 301.782C771.423 301.764 772.106 301.881 772.745 302.129C773.385 302.376 773.969 302.747 774.464 303.222C774.958 303.697 775.354 304.265 775.627 304.893C775.901 305.522 776.046 306.199 776.056 306.884C776.066 307.57 775.939 308.25 775.684 308.887C775.428 309.523 775.049 310.102 774.568 310.59L774.389 310.769V310.837C773.415 311.806 772.095 312.348 770.721 312.345C769.347 312.341 768.031 311.791 767.062 310.817C766.093 309.843 765.55 308.523 765.554 307.149C765.558 305.776 766.108 304.459 767.082 303.491H767.013Z"
      fill="url(#paint15_linear_2281_70)"
    />
    <Path
      d="M785.364 284.347L785.501 284.21C786.03 283.657 786.663 283.214 787.364 282.906C788.065 282.598 788.819 282.431 789.585 282.414C790.35 282.398 791.111 282.532 791.824 282.81C792.538 283.087 793.189 283.502 793.742 284.032C794.295 284.561 794.738 285.194 795.046 285.894C795.355 286.595 795.522 287.349 795.538 288.114C795.555 288.879 795.42 289.64 795.143 290.354C794.865 291.067 794.45 291.718 793.921 292.271L793.715 292.463L793.577 292.601C792.484 293.693 791.002 294.307 789.457 294.307C787.911 294.307 786.429 293.693 785.336 292.601C784.243 291.508 783.629 290.026 783.629 288.481C783.629 286.936 784.243 285.454 785.336 284.361L785.364 284.347Z"
      fill="url(#paint16_linear_2281_70)"
    />
    <Path
      d="M803.783 265.135L803.92 264.998C805.107 263.758 806.739 263.04 808.456 263.003C810.173 262.966 811.834 263.612 813.074 264.799C814.315 265.986 815.033 267.618 815.07 269.334C815.107 271.051 814.461 272.712 813.274 273.952L813.04 274.172L812.903 274.309C811.679 275.485 810.043 276.133 808.346 276.114C806.649 276.095 805.027 275.41 803.831 274.206C802.634 273.003 801.959 271.378 801.95 269.681C801.941 267.984 802.599 266.352 803.783 265.135Z"
      fill="url(#paint17_linear_2281_70)"
    />
    <Path
      d="M822.201 245.924L822.339 245.786C823.645 244.424 825.438 243.636 827.325 243.597C829.212 243.557 831.037 244.268 832.4 245.573C833.762 246.879 834.55 248.672 834.59 250.559C834.63 252.445 833.919 254.27 832.613 255.633C832.537 255.722 832.455 255.804 832.365 255.88L832.228 256.017C830.884 257.319 829.081 258.038 827.209 258.02C825.338 258.002 823.549 257.247 822.23 255.92C820.912 254.592 820.169 252.799 820.164 250.928C820.158 249.057 820.89 247.259 822.201 245.924Z"
      fill="url(#paint18_linear_2281_70)"
    />
    <Path
      d="M840.593 226.712L840.73 226.574C842.17 225.148 844.11 224.34 846.138 224.323C848.165 224.306 850.118 225.081 851.582 226.483C853.045 227.886 853.903 229.804 853.972 231.83C854.041 233.856 853.316 235.828 851.952 237.327L851.691 237.588L851.54 237.725C850.067 239.103 848.116 239.854 846.099 239.821C844.083 239.787 842.158 238.971 840.732 237.545C839.306 236.119 838.49 234.195 838.456 232.179C838.422 230.162 839.174 228.212 840.552 226.739L840.593 226.712Z"
      fill="url(#paint19_linear_2281_70)"
    />
    <Path
      d="M859.025 207.5L859.163 207.363C860.72 205.808 862.824 204.925 865.025 204.903C867.225 204.881 869.347 205.72 870.936 207.242C872.525 208.765 873.455 210.848 873.526 213.047C873.598 215.246 872.805 217.386 871.318 219.008L871.03 219.296L870.879 219.433C869.274 220.888 867.169 221.668 865.004 221.61C862.838 221.553 860.778 220.664 859.251 219.127C857.725 217.59 856.85 215.524 856.807 213.359C856.765 211.193 857.56 209.095 859.025 207.5Z"
      fill="url(#paint20_linear_2281_70)"
    />
    <Path
      d="M877.444 188.288L877.582 188.151C878.399 187.265 879.384 186.552 880.48 186.051C881.576 185.551 882.761 185.274 883.965 185.237C885.169 185.2 886.369 185.403 887.494 185.835C888.619 186.266 889.646 186.918 890.516 187.751C891.386 188.585 892.081 189.583 892.561 190.688C893.041 191.793 893.295 192.983 893.31 194.187C893.324 195.392 893.098 196.587 892.645 197.704C892.192 198.82 891.521 199.835 890.671 200.689L890.355 201.004L890.204 201.142C888.476 202.714 886.208 203.559 883.873 203.499C881.537 203.44 879.315 202.481 877.669 200.823C876.023 199.165 875.081 196.936 875.039 194.601C874.997 192.265 875.859 190.004 877.444 188.288Z"
      fill="url(#paint21_linear_2281_70)"
    />
    <Path
      d="M895.863 169.076L895.987 168.939C897.778 167.125 900.212 166.089 902.761 166.054C905.31 166.018 907.771 166.987 909.613 168.75C911.454 170.514 912.528 172.93 912.602 175.478C912.676 178.026 911.745 180.502 910.01 182.369L909.681 182.713L909.543 182.836C907.722 184.657 905.252 185.68 902.676 185.68C900.1 185.68 897.629 184.657 895.808 182.836C893.987 181.015 892.963 178.545 892.963 175.97C892.963 173.395 893.987 170.925 895.808 169.104L895.863 169.076Z"
      fill="url(#paint22_linear_2281_70)"
    />
    <Path
      d="M914.419 150.166L914.57 150.015C916.439 148.067 919.006 146.94 921.705 146.884C924.405 146.827 927.016 147.845 928.965 149.713C930.914 151.582 932.04 154.148 932.097 156.847C932.154 159.545 931.136 162.156 929.267 164.105L928.91 164.448L928.773 164.599C926.858 166.502 924.266 167.567 921.567 167.559C918.867 167.552 916.281 166.472 914.378 164.558C912.475 162.644 911.41 160.053 911.418 157.354C911.425 154.655 912.505 152.069 914.419 150.166Z"
      fill="url(#paint23_linear_2281_70)"
    />
    <Path
      d="M895.945 145.03L895.808 144.879C894.906 143.978 894.191 142.907 893.703 141.729C893.215 140.551 892.963 139.288 892.963 138.013C892.963 135.438 893.987 132.968 895.808 131.147C897.629 129.326 900.1 128.303 902.676 128.303C905.252 128.303 907.722 129.326 909.543 131.147L909.681 131.284C910.625 132.161 911.386 133.217 911.918 134.39C912.45 135.564 912.744 136.832 912.781 138.12C912.818 139.408 912.598 140.69 912.134 141.893C911.671 143.095 910.972 144.193 910.08 145.123C909.187 146.052 908.119 146.795 906.936 147.308C905.754 147.82 904.481 148.092 903.192 148.108C901.903 148.124 900.624 147.883 899.43 147.399C898.235 146.916 897.149 146.199 896.234 145.291L895.945 145.03Z"
      fill="url(#paint24_linear_2281_70)"
    />
    <Path
      d="M877.54 125.558L877.403 125.407C875.713 123.702 874.769 121.396 874.779 118.996C874.79 116.596 875.753 114.298 877.458 112.608C879.163 110.918 881.469 109.974 883.87 109.985C886.271 109.995 888.569 110.958 890.259 112.663L890.397 112.8C891.308 113.611 892.047 114.597 892.568 115.7C893.089 116.802 893.383 117.999 893.431 119.218C893.478 120.437 893.28 121.652 892.846 122.793C892.413 123.933 891.754 124.974 890.908 125.853C890.063 126.733 889.049 127.432 887.926 127.911C886.804 128.389 885.597 128.636 884.377 128.636C883.157 128.637 881.949 128.391 880.827 127.914C879.704 127.437 878.689 126.738 877.843 125.86C877.735 125.766 877.634 125.665 877.54 125.558Z"
      fill="url(#paint25_linear_2281_70)"
    />
    <Path
      d="M859.122 106.085L858.984 105.948C857.53 104.342 856.75 102.239 856.807 100.073C856.864 97.9082 857.754 95.8483 859.291 94.3221C860.828 92.7959 862.894 91.9208 865.06 91.8787C867.226 91.8366 869.325 92.6309 870.92 94.0963L871.071 94.2336C872.621 95.7931 873.499 97.8971 873.518 100.096C873.537 102.294 872.695 104.413 871.171 105.998C869.648 107.584 867.565 108.511 865.367 108.581C863.169 108.651 861.031 107.859 859.41 106.373C859.307 106.284 859.211 106.188 859.122 106.085Z"
      fill="url(#paint26_linear_2281_70)"
    />
    <Path
      d="M840.717 86.6121L840.593 86.4473C839.136 84.9904 838.317 83.0145 838.317 80.9543C838.317 78.894 839.136 76.9181 840.593 75.4612C842.05 74.0044 844.026 73.1859 846.087 73.1859C848.148 73.1859 850.124 74.0044 851.581 75.4612L851.718 75.5986C852.454 76.3046 853.044 77.1486 853.454 78.0825C853.863 79.0163 854.085 80.0217 854.106 81.0412C854.127 82.0607 853.947 83.0744 853.576 84.0244C853.205 84.9743 852.651 85.842 851.945 86.5777C851.239 87.3135 850.395 87.903 849.461 88.3126C848.527 88.7222 847.521 88.9439 846.501 88.9649C845.482 88.9859 844.468 88.806 843.518 88.4353C842.567 88.0645 841.7 87.5104 840.964 86.8043L840.717 86.6121Z"
      fill="url(#paint27_linear_2281_70)"
    />
    <Path
      d="M822.298 67.1393L822.16 67.002C820.831 65.6617 820.088 63.8482 820.096 61.9604C820.103 60.0727 820.861 58.2654 822.201 56.936C823.542 55.6066 825.356 54.8641 827.244 54.8719C829.132 54.8796 830.94 55.6369 832.269 56.9772L832.407 57.1145C833.769 58.422 834.556 60.2171 834.595 62.1048C834.634 63.9926 833.921 65.8183 832.613 67.1805C831.305 68.5426 829.51 69.3296 827.622 69.3682C825.733 69.4068 823.907 68.694 822.545 67.3865C822.456 67.3111 822.373 67.2284 822.298 67.1393Z"
      fill="url(#paint28_linear_2281_70)"
    />
    <Path
      d="M803.892 47.6802L803.755 47.5292C802.542 46.3127 801.862 44.6643 801.865 42.9465C801.867 41.2287 802.552 39.5824 803.769 38.3695C804.985 37.1567 806.634 36.4768 808.352 36.4794C810.07 36.482 811.717 37.1668 812.93 38.3833L813.081 38.5206C814.32 39.7097 815.035 41.3421 815.07 43.0586C815.105 44.775 814.456 46.435 813.267 47.6733C812.077 48.9117 810.445 49.6269 808.728 49.6616C807.011 49.6964 805.351 49.0479 804.112 47.8587L803.892 47.6802Z"
      fill="url(#paint29_linear_2281_70)"
    />
    <Path
      d="M785.474 28.2075L785.336 28.0564C784.795 27.5154 784.366 26.8731 784.073 26.1662C783.78 25.4594 783.629 24.7017 783.629 23.9366C783.629 22.3914 784.243 20.9095 785.336 19.8168C786.429 18.7242 787.911 18.1104 789.457 18.1104C791.002 18.1104 792.484 18.7242 793.577 19.8168L793.715 19.9542C794.835 21.0231 795.484 22.4932 795.52 24.041C795.556 25.5888 794.976 27.0875 793.907 28.2075C792.838 29.3274 791.367 29.9768 789.819 30.0129C788.271 30.0489 786.772 29.4687 785.652 28.3997L785.474 28.2075Z"
      fill="url(#paint30_linear_2281_70)"
    />
    <Path
      d="M767.068 8.73465H766.986C766.057 7.75277 765.549 6.44669 765.57 5.09538C765.592 3.74408 766.141 2.45469 767.1 1.50265C768.06 0.550607 769.353 0.0113889 770.705 0.000178349C772.057 -0.0110323 773.359 0.506654 774.334 1.44265L774.417 1.51132C774.907 1.9829 775.3 2.54648 775.573 3.16989C775.846 3.79329 775.993 4.4643 776.006 5.14462C776.02 5.82493 775.899 6.50122 775.651 7.13487C775.403 7.76852 775.033 8.34712 774.561 8.83764C774.089 9.32816 773.526 9.721 772.902 9.99371C772.278 10.2664 771.607 10.4137 770.927 10.4271C770.246 10.4405 769.57 10.3197 768.936 10.0717C768.303 9.82377 767.724 9.45342 767.233 8.98184L767.068 8.73465Z"
      fill="url(#paint31_linear_2281_70)"
    />
    <Path
      d="M787.026 242.834V242.751C787.493 242.246 788.055 241.838 788.68 241.551C789.305 241.263 789.98 241.101 790.667 241.074C791.355 241.047 792.041 241.156 792.686 241.395C793.332 241.633 793.924 241.996 794.429 242.463C794.934 242.93 795.342 243.492 795.63 244.117C795.918 244.742 796.08 245.417 796.106 246.104C796.133 246.792 796.024 247.477 795.786 248.123C795.548 248.768 795.184 249.36 794.717 249.865L794.415 250.153L794.333 250.222C793.345 251.111 792.053 251.586 790.725 251.549C789.397 251.513 788.133 250.967 787.196 250.025C786.259 249.083 785.72 247.816 785.691 246.488C785.662 245.16 786.145 243.871 787.039 242.889L787.026 242.834Z"
      fill="url(#paint32_linear_2281_70)"
    />
    <Path
      d="M804.249 224.281L804.373 224.158C804.916 223.515 805.583 222.989 806.335 222.612C807.086 222.235 807.907 222.014 808.746 221.962C809.586 221.911 810.427 222.03 811.219 222.313C812.011 222.596 812.737 223.036 813.354 223.608C813.972 224.179 814.466 224.87 814.809 225.638C815.151 226.405 815.335 227.235 815.348 228.076C815.361 228.916 815.203 229.751 814.884 230.529C814.566 231.307 814.092 232.013 813.493 232.603L813.136 232.974L812.999 233.097C811.808 234.145 810.263 234.699 808.677 234.646C807.092 234.593 805.587 233.936 804.47 232.81C803.353 231.685 802.708 230.175 802.667 228.589C802.626 227.004 803.192 225.463 804.249 224.281Z"
      fill="url(#paint33_linear_2281_70)"
    />
    <Path
      d="M821.501 205.687L821.625 205.55C822.932 204.139 824.747 203.304 826.67 203.231C828.593 203.158 830.466 203.851 831.878 205.159C833.29 206.466 834.124 208.281 834.197 210.203C834.271 212.126 833.577 213.999 832.269 215.41C832.132 215.561 831.995 215.698 831.844 215.836L831.706 215.959C830.32 217.188 828.517 217.839 826.665 217.781C824.814 217.722 823.055 216.957 821.75 215.643C820.445 214.33 819.692 212.566 819.645 210.715C819.599 208.864 820.263 207.065 821.501 205.687Z"
      fill="url(#paint34_linear_2281_70)"
    />
    <Path
      d="M838.766 187.08L838.89 186.942C840.385 185.331 842.46 184.379 844.657 184.296C846.854 184.214 848.994 185.008 850.606 186.503C852.218 187.998 853.17 190.072 853.252 192.269C853.334 194.466 852.541 196.605 851.045 198.217L850.565 198.697L850.427 198.821C848.87 200.367 846.762 201.231 844.568 201.223C842.373 201.216 840.271 200.337 838.725 198.78C837.179 197.223 836.314 195.115 836.322 192.921C836.33 190.727 837.209 188.626 838.766 187.08Z"
      fill="url(#paint35_linear_2281_70)"
    />
    <Path
      d="M856.017 168.472L856.155 168.335C857.838 166.514 860.175 165.436 862.653 165.338C865.131 165.24 867.547 166.13 869.368 167.813C871.189 169.495 872.268 171.833 872.365 174.31C872.463 176.788 871.573 179.202 869.89 181.023L869.34 181.559L869.203 181.683C867.423 183.279 865.098 184.132 862.708 184.063C860.318 183.995 858.045 183.011 856.359 181.315C854.674 179.619 853.704 177.341 853.651 174.951C853.597 172.561 854.464 170.241 856.072 168.472H856.017Z"
      fill="url(#paint36_linear_2281_70)"
    />
    <Path
      d="M856.114 145.731L855.99 145.593C854.358 143.821 853.474 141.486 853.526 139.078C853.577 136.669 854.559 134.374 856.266 132.673C857.972 130.972 860.271 129.997 862.68 129.954C865.089 129.91 867.422 130.8 869.189 132.438L869.327 132.575C871.148 134.256 872.227 136.591 872.326 139.067C872.426 141.544 871.537 143.958 869.856 145.779C868.174 147.6 865.839 148.679 863.362 148.778C860.885 148.877 858.471 147.988 856.649 146.308C856.471 146.088 856.278 145.909 856.114 145.731Z"
      fill="url(#paint37_linear_2281_70)"
    />
    <Path
      d="M873.461 163.995L873.324 163.844C871.659 161.904 870.79 159.405 870.892 156.851C870.993 154.296 872.058 151.875 873.873 150.073C875.687 148.271 878.116 147.223 880.672 147.138C883.227 147.054 885.72 147.939 887.65 149.617L887.801 149.754C889.617 151.612 890.651 154.097 890.688 156.695C890.725 159.293 889.763 161.806 888 163.715C886.237 165.624 883.808 166.783 881.215 166.952C878.622 167.122 876.062 166.29 874.065 164.627C873.846 164.393 873.64 164.187 873.461 163.995Z"
      fill="url(#paint38_linear_2281_70)"
    />
    <Path
      d="M838.862 126.945L838.739 126.807C837.189 125.254 836.319 123.149 836.322 120.954C836.324 118.76 837.199 116.657 838.752 115.107C840.306 113.557 842.412 112.688 844.606 112.691C846.801 112.693 848.905 113.568 850.455 115.121L850.592 115.258C851.391 115.997 852.037 116.885 852.492 117.873C852.948 118.861 853.204 119.929 853.247 121.016C853.29 122.104 853.118 123.188 852.741 124.209C852.365 125.23 851.791 126.166 851.052 126.965C850.314 127.764 849.425 128.41 848.437 128.865C847.448 129.321 846.38 129.577 845.293 129.62C844.206 129.663 843.12 129.491 842.1 129.114C841.079 128.738 840.142 128.164 839.343 127.425C839.219 127.274 839.013 127.109 838.862 126.945Z"
      fill="url(#paint39_linear_2281_70)"
    />
    <Path
      d="M821.611 108.172L821.473 108.035C820.212 106.656 819.533 104.843 819.576 102.975C819.619 101.107 820.381 99.3279 821.705 98.0084C823.028 96.689 824.81 95.9312 826.678 95.8931C828.547 95.855 830.358 96.5396 831.734 97.8041L831.871 97.9414C833.24 99.2577 834.037 101.058 834.092 102.956C834.148 104.854 833.456 106.697 832.167 108.091C830.877 109.485 829.092 110.317 827.196 110.409C825.299 110.501 823.442 109.846 822.023 108.584C821.885 108.419 821.734 108.31 821.611 108.172Z"
      fill="url(#paint40_linear_2281_70)"
    />
    <Path
      d="M804.346 89.3998L804.222 89.2625C803.17 88.0756 802.612 86.5319 802.66 84.9469C802.709 83.362 803.361 81.8554 804.483 80.735C805.605 79.6146 807.113 78.965 808.699 78.919C810.284 78.873 811.827 79.434 813.012 80.4873L813.15 80.6247C814.32 81.7546 815.001 83.2979 815.047 84.9238C815.093 86.5496 814.5 88.1288 813.395 89.3229C812.291 90.517 810.762 91.2312 809.137 91.3123C807.513 91.3934 805.921 90.835 804.703 89.7568L804.346 89.3998Z"
      fill="url(#paint41_linear_2281_70)"
    />
    <Path
      d="M787.094 70.6136C786.164 69.6334 785.654 68.3284 785.673 66.9773C785.692 65.6261 786.238 64.3359 787.196 63.3822C788.153 62.4285 789.446 61.8869 790.797 61.873C792.149 61.8591 793.452 62.3741 794.429 63.3079C794.969 63.7594 795.411 64.3159 795.729 64.9437C796.047 65.5714 796.234 66.2573 796.279 66.9595C796.324 67.6618 796.225 68.3659 795.99 69.0289C795.754 69.6919 795.386 70.3001 794.907 70.8164C794.429 71.3327 793.851 71.7465 793.208 72.0324C792.565 72.3184 791.87 72.4707 791.166 72.48C790.463 72.4893 789.764 72.3554 789.114 72.0865C788.463 71.8176 787.874 71.4193 787.383 70.9157L787.094 70.6136Z"
      fill="url(#paint42_linear_2281_70)"
    />
    <Path
      d="M750.2 289.6V282.22H753.94C754.24 282.22 754.567 282.307 754.92 282.48C755.273 282.647 755.573 282.907 755.82 283.26C756.073 283.607 756.2 284.047 756.2 284.58C756.2 285.12 756.07 285.58 755.81 285.96C755.55 286.333 755.233 286.617 754.86 286.81C754.493 287.003 754.147 287.1 753.82 287.1H751.12V285.9H753.32C753.54 285.9 753.777 285.79 754.03 285.57C754.29 285.35 754.42 285.02 754.42 284.58C754.42 284.127 754.29 283.82 754.03 283.66C753.777 283.5 753.553 283.42 753.36 283.42H751.82V289.6H750.2ZM754.66 286.12L756.5 289.6H754.72L752.92 286.12H754.66ZM753 294C751.893 294 750.857 293.793 749.89 293.38C748.923 292.967 748.073 292.393 747.34 291.66C746.607 290.927 746.033 290.077 745.62 289.11C745.207 288.143 745 287.107 745 286C745 284.893 745.207 283.857 745.62 282.89C746.033 281.923 746.607 281.073 747.34 280.34C748.073 279.607 748.923 279.033 749.89 278.62C750.857 278.207 751.893 278 753 278C754.107 278 755.143 278.207 756.11 278.62C757.077 279.033 757.927 279.607 758.66 280.34C759.393 281.073 759.967 281.923 760.38 282.89C760.793 283.857 761 284.893 761 286C761 287.107 760.793 288.143 760.38 289.11C759.967 290.077 759.393 290.927 758.66 291.66C757.927 292.393 757.077 292.967 756.11 293.38C755.143 293.793 754.107 294 753 294ZM753 292.08C754.12 292.08 755.14 291.807 756.06 291.26C756.98 290.713 757.713 289.98 758.26 289.06C758.807 288.14 759.08 287.12 759.08 286C759.08 284.88 758.807 283.86 758.26 282.94C757.713 282.02 756.98 281.287 756.06 280.74C755.14 280.193 754.12 279.92 753 279.92C751.88 279.92 750.86 280.193 749.94 280.74C749.02 281.287 748.287 282.02 747.74 282.94C747.193 283.86 746.92 284.88 746.92 286C746.92 287.12 747.193 288.14 747.74 289.06C748.287 289.98 749.02 290.713 749.94 291.26C750.86 291.807 751.88 292.08 753 292.08Z"
      fill="#D6D6D6"
    />
    <Defs>
      <LinearGradient id="paint0_linear_2281_70" x1="776.093" y1="302.104" x2="976.228" y2="302.104">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint1_linear_2281_70" x1="776.092" y1="281.395" x2="976.228" y2="281.395">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint2_linear_2281_70" x1="776.092" y1="260.659" x2="976.228" y2="260.659">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint3_linear_2281_70" x1="776.092" y1="239.923" x2="976.228" y2="239.923">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint4_linear_2281_70" x1="776.092" y1="219.173" x2="976.228" y2="219.173">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint5_linear_2281_70" x1="776.093" y1="198.436" x2="976.227" y2="198.436">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint6_linear_2281_70" x1="776.092" y1="177.7" x2="976.228" y2="177.7">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint7_linear_2281_70" x1="776.092" y1="136.544" x2="976.228" y2="136.544">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint8_linear_2281_70" x1="776.093" y1="157.143" x2="976.228" y2="157.143">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint9_linear_2281_70" x1="776.093" y1="115.766" x2="976.228" y2="115.766">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint10_linear_2281_70" x1="776.092" y1="94.9752" x2="976.228" y2="94.9752">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint11_linear_2281_70" x1="776.092" y1="74.1841" x2="976.228" y2="74.1841">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint12_linear_2281_70" x1="776.092" y1="53.4067" x2="976.228" y2="53.4067">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint13_linear_2281_70" x1="776.093" y1="32.6156" x2="976.228" y2="32.6156">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint14_linear_2281_70" x1="776.092" y1="11.8657" x2="976.228" y2="11.8657">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint15_linear_2281_70" x1="776.092" y1="307.047" x2="976.227" y2="307.047">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint16_linear_2281_70" x1="776.092" y1="288.316" x2="976.228" y2="288.316">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint17_linear_2281_70" x1="776.093" y1="269.544" x2="976.227" y2="269.544">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint18_linear_2281_70" x1="776.092" y1="250.785" x2="976.228" y2="250.785">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint19_linear_2281_70" x1="776.092" y1="232.026" x2="976.228" y2="232.026">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint20_linear_2281_70" x1="776.093" y1="213.254" x2="976.228" y2="213.254">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint21_linear_2281_70" x1="776.093" y1="194.495" x2="976.228" y2="194.495">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint22_linear_2281_70" x1="776.092" y1="175.736" x2="976.228" y2="175.736">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint23_linear_2281_70" x1="776.092" y1="157.143" x2="976.228" y2="157.143">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint24_linear_2281_70" x1="776.092" y1="138.301" x2="976.228" y2="138.301">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint25_linear_2281_70" x1="776.092" y1="119.268" x2="976.228" y2="119.268">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint26_linear_2281_70" x1="776.093" y1="100.248" x2="976.228" y2="100.248">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint27_linear_2281_70" x1="776.092" y1="81.2152" x2="976.228" y2="81.2152">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint28_linear_2281_70" x1="776.092" y1="62.1956" x2="976.228" y2="62.1956">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint29_linear_2281_70" x1="776.092" y1="43.1759" x2="976.227" y2="43.1759">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint30_linear_2281_70" x1="776.092" y1="24.1426" x2="976.228" y2="24.1426">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint31_linear_2281_70" x1="776.092" y1="5.15044" x2="976.228" y2="5.15044">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint32_linear_2281_70" x1="776.092" y1="246.322" x2="976.229" y2="246.322">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint33_linear_2281_70" x1="776.092" y1="228.456" x2="976.228" y2="228.456">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint34_linear_2281_70" x1="776.092" y1="210.549" x2="976.228" y2="210.549">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint35_linear_2281_70" x1="776.092" y1="192.655" x2="976.228" y2="192.655">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint36_linear_2281_70" x1="776.092" y1="174.748" x2="976.228" y2="174.748">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint37_linear_2281_70" x1="776.093" y1="139.373" x2="976.228" y2="139.373">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint38_linear_2281_70" x1="776.092" y1="157.088" x2="976.228" y2="157.088">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint39_linear_2281_70" x1="776.092" y1="121.287" x2="976.228" y2="121.287">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint40_linear_2281_70" x1="776.092" y1="103.215" x2="976.228" y2="103.215">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint41_linear_2281_70" x1="776.092" y1="85.1289" x2="976.227" y2="85.1289">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
      <LinearGradient id="paint42_linear_2281_70" x1="776.093" y1="67.0844" x2="976.228" y2="67.0844">
        <Stop offset="0" stopColor="#92A29C" />
        <Stop offset="1" stopColor="#A8876C" />
      </LinearGradient>
    </Defs>
  </Svg>
);

const styles = StyleSheet.create({
  logo: {
    width: 100,
    height: 100,
  },
  container: {
    marginBottom: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
  },
  title: {
    fontSize: 32,
    fontFamily: 'Helvetica-Bold',
  },
  hr: {
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    marginVertical: 5,
  },
});

const Header: FC<HeaderProps> = ({ title }) => (
  <View style={styles.container} fixed>
    <View style={styles.header}>
      <View>
        <LogoIcon />
      </View>
      <Text style={styles.title}>
        {title} - {new Date().toLocaleDateString()}
      </Text>
    </View>
    <View style={styles.hr} />
  </View>
);

export default Header;
