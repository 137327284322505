import { FC } from 'react';
import { CalendarMonth, CloseRounded } from '@mui/icons-material';
import { TextField, IconButton, Box } from '@mui/material';
// interfaces
import { DateTextFieldProps } from 'interfaces';

const DateField: FC<DateTextFieldProps> = (params) => {
  const { onChange, setOpen, onDateChange, open, inputProps, ...rest } = params || {};

  const onClick = () => setOpen(!open);

  return (
    <TextField
      {...rest}
      fullWidth
      inputProps={{
        ...inputProps,
        readOnly: true,
        onChange: () => {},
        onClick,
      }}
      InputProps={{
        endAdornment: (
          <Box display="flex">
            <IconButton onClick={() => onDateChange('')}>
              <CloseRounded fontSize="large" color="secondary" />
            </IconButton>
            <IconButton onClick={onClick}>
              <CalendarMonth fontSize="large" color="secondary" />
            </IconButton>
          </Box>
        ),
      }}
    />
  );
};

export default DateField;
