import { FC, JSX } from 'react';
import { Grid } from '@mui/material';
// components
import InputController from 'controller/InputController';
import SandComponentSelector from 'components/common/selectors/sandComponent';
// constants, styles, interfaces
import { CoatingFormulationComponentFormProps } from 'interfaces';
import { forInputLabelBg } from 'styles/commonComponentStyle';
import { SAND_COMPONENT_TEXT, WEIGHT_TEXT } from 'constants/index';

const CoatingFormulationComponentForm: FC<CoatingFormulationComponentFormProps> = ({
  loading = false,
}): JSX.Element => {
  return (
    <Grid container spacing={2} sx={forInputLabelBg}>
      <Grid item xs={12} lg={12}>
        <InputController
          isRequired
          fieldType="number"
          name="weight"
          disabled={loading}
          title={`${WEIGHT_TEXT} (mg)`}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <SandComponentSelector
          isRequired
          title={SAND_COMPONENT_TEXT}
          disabled={loading}
          name="sandComponent"
        />
      </Grid>
    </Grid>
  );
};

export default CoatingFormulationComponentForm;
