import { forwardRef, memo } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { CardHeader, Card, Grid, CardContent, Typography } from '@mui/material';
// components
import FormulaCounts from './FormulaCounts';
import PieChart from '../components/Charts/PieChart';
import DataLoader from 'components/common/DataLoader';
import LegendLabel from '../components/Charts/LegendLabel';
// constants, interfaces, utils
import { DosageFormPercentageProps } from 'interfaces';
import { chartPieColors } from '../components/Charts/utils';
import { DOSAGE_FORM_TEXT, TOTAL_FORMULAS_TEXT } from 'constants/index';

const DosageFormPercentage = forwardRef<ChartJSOrUndefined<'pie'>, DosageFormPercentageProps>(
  ({ data, labels, dataSet, totalFormulas, loading = false }, ref) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Card>
            <CardHeader title={`${DOSAGE_FORM_TEXT} (%)`} />
            <CardContent>
              {loading ? (
                <DataLoader columns={12} rows={5} />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <PieChart dataSet={dataSet} labels={labels} total={totalFormulas} ref={ref} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography variant="h6" gutterBottom>
                      {TOTAL_FORMULAS_TEXT}
                    </Typography>

                    <Typography variant="h3" gutterBottom>
                      {totalFormulas}
                    </Typography>

                    <Grid container spacing={1}>
                      {labels?.map((name, index) => {
                        return (
                          <Grid item xs={6} key={name || '-'}>
                            <LegendLabel label={name} bgColor={chartPieColors[index]} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <FormulaCounts data={data} loading={loading} />
        </Grid>
      </Grid>
    );
  },
);

export default memo(DosageFormPercentage);
