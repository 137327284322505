import { Bar } from 'react-chartjs-2';
import { forwardRef, memo, useMemo } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
// constants
import { ChartProps } from 'interfaces';
import { showIntegerValues } from './utils';

const options: ChartOptions<'bar'> = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
  },

  scales: {
    x: {
      stacked: true,
      ticks: {
        // color: '#fff',
        maxRotation: 90,
        minRotation: 90,
        // autoSkip: false,
      },
      grid: {
        display: false, // Enable grid lines
      },
    },
    y: {
      stacked: true,
      ticks: {
        callback: showIntegerValues,
      },
      grid: {
        display: false, // Enable grid lines
      },
    },
  },
};

const StackBarChart = forwardRef<ChartJSOrUndefined<'bar'>, ChartProps>(({ dataSet, labels }, ref) => {
  const data = useMemo<ChartData<'bar'>>(() => {
    if (dataSet?.length) {
      const datasets = dataSet?.map<ChartDataset<'bar'>>((v, index) => {
        return {
          data: Array<number>(dataSet?.length)
            ?.fill(0)
            ?.map<number>((val, i) => (i === index ? v : val)),
          maxBarThickness: 10,
          borderRadius: 10,
        };
      });

      return {
        labels: labels,
        datasets: datasets,
      };
    }

    return {
      datasets: [],
    };
  }, [dataSet, labels]);

  return <Bar data={data} options={options} ref={ref} />;
});

export default memo(StackBarChart);
