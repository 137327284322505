import { StyleSheet, PageProps } from '@react-pdf/renderer';

export const pdfColors = {
  gray: '#EEEEEE',
  black: '#000000',
  charcoal: '#333333',
  white: '#FFFFFF',
  lightGray: '#666666',
};

export const pageSize: PageProps['size'] = { width: 1440, height: 2038 };

// Create styles
export const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
  },
  container: {
    padding: 20,
  },

  table: {
    borderWidth: 1,
    borderColor: pdfColors.lightGray,
    borderStyle: 'solid',
    marginBottom: 10,
  },

  th: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    borderRightWidth: 1,
    borderRightColor: pdfColors.lightGray,
    borderRightStyle: 'solid',
  },

  thLast: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
  },

  thV: {
    backgroundColor: pdfColors.gray,
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: pdfColors.lightGray,
    borderBottomStyle: 'solid',
  },

  thVLast: {
    backgroundColor: pdfColors.gray,
    flex: 1,
    flexDirection: 'row',
    padding: 10,
  },

  tdVLast: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    borderLeftWidth: 1,
    borderLeftColor: pdfColors.lightGray,
    borderLeftStyle: 'solid',
  },

  tdV: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    borderLeftWidth: 1,
    borderLeftColor: pdfColors.lightGray,
    borderLeftStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: pdfColors.lightGray,
    borderBottomStyle: 'solid',
  },

  tdLast: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    borderTopWidth: 1,
    borderTopColor: pdfColors.lightGray,
    borderTopStyle: 'solid',
  },

  td: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    borderRightWidth: 1,
    borderRightColor: pdfColors.lightGray,
    borderRightStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: pdfColors.lightGray,
    borderTopStyle: 'solid',
  },

  tr: {
    flexDirection: 'row',
    backgroundColor: pdfColors.gray,
  },

  trBody: {
    flexDirection: 'row',
  },

  thText: {
    fontSize: 16,
    color: pdfColors.charcoal,
    fontFamily: 'Helvetica-Bold',
  },

  tdText: {
    fontSize: 16,
    flexWrap: 'wrap',
    color: pdfColors.charcoal,
  },
  flex1: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  flexRow: {
    flexDirection: 'row',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  flexCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  // margins
  mb10: {
    marginBottom: 10,
  },

  mt10: {
    marginTop: 10,
  },

  mb5: {
    marginBottom: 10,
  },
  ml5: {
    marginLeft: 5,
  },

  ml10: {
    marginLeft: 10,
  },
  mr10: {
    marginRight: 10,
  },
  mV10: {
    marginVertical: 10,
  },
  mH10: {
    marginHorizontal: 10,
  },
  // widths
  w100: {
    width: '100%',
  },
  w50: {
    width: '50%',
  },

  graph: {
    width: '100%',
    height: 'auto',
  },
  border1: {
    border: 1,
    borderColor: pdfColors.lightGray,
  },
  p10: {
    padding: 10,
  },
  circleIcon: { borderRadius: '100%', width: 12, height: 12, marginRight: 5 },
  graphHeader: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 },
});

export const userPdfStyles = StyleSheet.create({
  container: { flexDirection: 'row', marginVertical: 10 },
  graphContainer: {
    flex: 2,
    border: 1,
    padding: 10,
    marginRight: 10,
    borderColor: pdfColors.lightGray,
  },
});

export const ingredientPdfStyles = StyleSheet.create({
  trendContainer: {
    flex: 2,
    marginRight: 10,
  },
  graphContainer: { flexDirection: 'row', marginVertical: 10 },
  usageGraph: {
    marginHorizontal: 10,
  },
  usageContainer: { flex: 1, border: 1, padding: 10, borderColor: pdfColors.lightGray },
  trendGraph: { marginTop: 5, border: 1, padding: 10, borderColor: pdfColors.lightGray },
});
