import { PaletteColor, PaletteColorOptions, PaletteOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    myCustomColor: PaletteColor;
    customCommonColor: PaletteColor;
    customBlackColor: PaletteColor;
    customGrayColor: PaletteColor;
    customShadowColor: PaletteColor;
    customSkyBlueColor: PaletteColor;
    customRedColor: PaletteColor;
    customBlueColor: PaletteColor;
    customGreenColor: PaletteColor;
    customBoxShadowColor: PaletteColor;
    bluePaletteColor: PaletteColor;
    redRgbaColor: PaletteColor;
    customWhiteColor: PaletteColor;
  }

  interface PaletteOptions {
    myCustomColor?: PaletteColorOptions;
    customCommonColor?: PaletteColorOptions;
    customBlackColor?: PaletteColorOptions;
    customGrayColor?: PaletteColorOptions;
    customShadowColor?: PaletteColorOptions;
    customSkyBlueColor?: PaletteColorOptions;
    customRedColor?: PaletteColorOptions;
    customBlueColor?: PaletteColorOptions;
    customGreenColor?: PaletteColorOptions;
    customBoxShadowColor?: PaletteColorOptions;
    bluePaletteColor?: PaletteColorOptions;
    redRgbaColor?: PaletteColorOptions;
    customWhiteColor?: PaletteColorOptions;
  }
}

const palette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#B3995B',
  },

  secondary: {
    main: '#161C24',
    light: '#FFF2EB',
    contrastText: '#303030',
  },

  common: {
    white: '#ffffff',
    black: '#000000',
  },

  customBlackColor: {
    main: '#B42318',
    dark: '#212B36',
    light: '#C4CDD5',
    contrastText: '#757575',
  },

  customGrayColor: {
    main: '#919EAB',
    light: 'rgba(145, 158, 171, 1)',
    contrastText: 'rgba(0, 0, 0, 0.24)',
    dark: 'rgba(145, 158, 171, 0.24)',
  },

  customShadowColor: {
    main: 'rgba(145, 158, 171, 0.16)',
    dark: 'rgba(33, 43, 54, 1)',
    light: '#FFC107',
    contrastText: 'rgba(255, 193, 7, 0.16)',
  },

  customSkyBlueColor: {
    main: '#D0F2FF',
    dark: 'rgba(24, 144, 255, 0.16)',
    light: '#0C53B7',
    contrastText: '#1890FF',
  },

  customRedColor: {
    main: '#FF4842',
    dark: '#B72136',
    light: 'rgba(255, 72, 66, 0.16)',
    contrastText: '#f44336',
  },

  customBlueColor: {
    main: '#04297A',
    dark: '#3366FF',
    light: 'rgba(24, 144, 255, 0.24)',
    contrastText: 'rgba(145, 158, 171, 0.24)',
  },

  customGreenColor: {
    main: '#229A16',
    light: 'rgba(84, 214, 44, 0.16)',
    dark: '#54D62C',
    contrastText: '#ECFFEE',
  },

  myCustomColor: {
    main: '#637381',
    light: 'rgba(145, 158, 171, 0.08)',
    dark: 'rgba(145, 158, 171, 0.2)',
    contrastText: 'rgba(145, 158, 171, 0.12)',
  },

  customCommonColor: {
    main: 'rgba(179, 153, 91, 0.08)',
    dark: 'rgba(145, 158, 171, 0.32)',
    light: 'rgba(22, 28, 36, 0.5)',
    contrastText: 'rgba(179, 153, 91, 0.24)',
  },

  customBoxShadowColor: {
    main: 'rgba(0, 0, 0, 0.20)',
    contrastText: 'rgba(0, 0, 0, 0.12)',
  },

  bluePaletteColor: {
    main: '#2D99FF',
    light: 'rgba(24, 144, 255, 0.08)',
  },

  redRgbaColor: {
    main: 'rgba(255, 72, 66, 0.08)',
  },

  customWhiteColor: {
    main: '#FAFAFA',
    dark: '#A3A3A3',
  },
};

export default palette;
