import { createContext, FC, useReducer } from 'react';
// interfaces, reducer
import { ChildrenType, UserReportContextType } from 'interfaces';
import { initialState, userReportReducer } from 'reducer/userReportReducer';

export const UserReportContext = createContext<UserReportContextType>({
  state: initialState,
  dispatch: () => {},
});

export const UserReportContextProvider: FC<ChildrenType> = ({ children }) => {
  const [state, dispatch] = useReducer(userReportReducer, initialState);

  return (
    <UserReportContext.Provider
      value={{
        state,
        dispatch,
      }}>
      {children}
    </UserReportContext.Provider>
  );
};
