import { SelectType } from 'interfaces';
import { selectTypeInitialValues } from 'constants/index';
import { DosageFormulasPayload } from 'generated/graphql';

export type State = {
  toDate: string;
  fromDate: string;
  productType: SelectType;
  organization: SelectType;
  subProductType: SelectType;
  data: DosageFormulasPayload['data'];

  open: boolean;
  pieCanvas: string;
  formCanvas: string;
  dosageCanvas: string;
  formulationCanvas: string;
};

export const initialState: State = {
  data: [],
  toDate: '',
  fromDate: '',
  productType: selectTypeInitialValues,
  organization: selectTypeInitialValues,
  subProductType: selectTypeInitialValues,
  open: false,
  pieCanvas: '',
  formCanvas: '',
  dosageCanvas: '',
  formulationCanvas: '',
};

export enum ActionType {
  SET_OPEN = 'setOpen',
  SET_DATA = 'setData',
  SET_TO_DATE = 'setToDate',
  SET_FROM_DATE = 'setFromDate',
  SET_PRODUCT_TYPE = 'setProductType',
  SET_ORGANIZATION = 'setOrganization',
  SET_SUB_PRODUCT_TYPE = 'setSubProductType',
  SET_PIE_CANVAS = 'setPieCanvas',
  SET_FORM_CANVAS = 'setFormCanvas',
  SET_DOSAGE_CANVAS = 'setDosageCanvas',
  SET_FORMULATION_CANVAS = 'setFormulationCanvas',
}

export type Action =
  | { type: ActionType.SET_OPEN; open: State['open'] }
  | { type: ActionType.SET_DATA; data: State['data'] }
  | { type: ActionType.SET_TO_DATE; toDate: State['toDate'] }
  | { type: ActionType.SET_FROM_DATE; fromDate: State['fromDate'] }
  | { type: ActionType.SET_PRODUCT_TYPE; productType: State['productType'] }
  | { type: ActionType.SET_ORGANIZATION; organization: State['organization'] }
  | { type: ActionType.SET_SUB_PRODUCT_TYPE; subProductType: State['subProductType'] }
  | { type: ActionType.SET_PIE_CANVAS; pieCanvas: State['pieCanvas'] }
  | { type: ActionType.SET_FORM_CANVAS; formCanvas: State['formCanvas'] }
  | { type: ActionType.SET_DOSAGE_CANVAS; dosageCanvas: State['dosageCanvas'] }
  | { type: ActionType.SET_FORMULATION_CANVAS; formulationCanvas: State['formulationCanvas'] };

export const dosageReportReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_OPEN:
      return {
        ...state,
        open: action.open,
      };

    case ActionType.SET_DATA:
      return {
        ...state,
        data: action.data,
      };

    case ActionType.SET_SUB_PRODUCT_TYPE:
      return {
        ...state,
        subProductType: action.subProductType,
      };

    case ActionType.SET_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.productType,
      };

    case ActionType.SET_ORGANIZATION:
      return {
        ...state,
        organization: action.organization,
      };

    case ActionType.SET_FROM_DATE:
      return {
        ...state,
        fromDate: action.fromDate,
      };

    case ActionType.SET_TO_DATE:
      return {
        ...state,
        toDate: action.toDate,
      };

    case ActionType.SET_DOSAGE_CANVAS:
      return {
        ...state,
        dosageCanvas: action.dosageCanvas,
      };

    case ActionType.SET_FORMULATION_CANVAS:
      return {
        ...state,
        formulationCanvas: action.formulationCanvas,
      };

    case ActionType.SET_FORM_CANVAS:
      return {
        ...state,
        formCanvas: action.formCanvas,
      };

    case ActionType.SET_PIE_CANVAS:
      return {
        ...state,
        pieCanvas: action.pieCanvas,
      };
  }
};
