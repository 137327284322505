import { Bar } from 'react-chartjs-2';
import { ChartOptions, ChartData } from 'chart.js';
import { forwardRef, useMemo } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
// interfaces
import { BarHorizontalXProps } from 'interfaces';
import { showIntegerValues } from './utils';

// TODO: decrease the spacing between the bars

const options: ChartOptions<'bar'> = {
  indexAxis: 'y',
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      offset: 10,
      textAlign: 'right',
      anchor: 'end',
      align: 'top',
      // color: '#000', // White labels
      // formatter: (value: number) => value, // Show bar values
      font: {
        size: 14,
        weight: 'bold',
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        callback: showIntegerValues,
      },
    },
    y: {
      ticks: {
        // color: '#fff', // White for y-axis labels
        crossAlign: 'far',
        font: {
          size: 12,
        },
      },
      grid: {
        display: false, // Enable grid lines
      },
    },
  },
};

const BarHorizontalX = forwardRef<ChartJSOrUndefined<'bar'>, BarHorizontalXProps>(
  ({ dataSet, labels }, ref) => {
    const data = useMemo<ChartData<'bar'>>(() => {
      return {
        labels: labels,
        datasets: [
          {
            data: dataSet,
            backgroundColor: '#bfa251', // Gold color for bars
            maxBarThickness: 10,
            borderRadius: 10,
          },
        ],
      };
    }, [dataSet, labels]);

    return <Bar data={data} options={options} ref={ref} />;
  },
);

export default BarHorizontalX;
