import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
// components
import Header from '../components/Pdf/Header';
import TableHeader from '../components/Pdf/TableHeader';
// interfaces, constants, styles
import {
  COMMON_NAME_TEXT,
  DATE_RANGE_TEXT,
  DOSAGE_FORM_TEXT,
  DOSAGE_TYPE_TEXT,
  INGREDIENT_TEXT,
  INGREDIENTS_REPORT_TEXT,
  ORGANIZATION_TEXT,
  PRIMARY_CATEGORY_TEXT,
  RANK_TEXT,
  SECONDARY_CATEGORY_TEXT,
  SUPPLIER_TEXT,
  TOP_TEXT,
  TREND_ANALYSIS_TEXT,
  USAGE_TEXT,
  YEAR_TEXT,
} from 'constants/index';
import { IngredientPdfProps } from 'interfaces';
import { ingredientPdfStyles, pageSize, pdfStyles } from 'styles/PdfStyles';
import { chartPieColors } from '../components/Charts/utils';

const IngredientPdf: FC<IngredientPdfProps> = ({
  top,
  data,
  dateRange,
  supplierName,
  dosageTypeName,
  formulationName,
  organizationName,
  ingredientName,
  ingredientUsage,
  productTypeName,
  subProductTypeName,
  year,
  lineCanvas,
  pieCanvas,
  labels,
}) => {
  return (
    <Document title={INGREDIENTS_REPORT_TEXT}>
      <Page size={pageSize} style={pdfStyles.page}>
        <Header title={INGREDIENTS_REPORT_TEXT} />
        <View style={pdfStyles.container}>
          {/* global_filters */}
          <View style={pdfStyles.table}>
            <TableHeader headers={[SUPPLIER_TEXT, ORGANIZATION_TEXT]} />
            <View style={pdfStyles.trBody}>
              <View style={pdfStyles.td}>
                <Text style={pdfStyles.tdText}>{supplierName}</Text>
              </View>
              <View style={pdfStyles.tdLast}>
                <Text style={pdfStyles.tdText}>{organizationName}</Text>
              </View>
            </View>
          </View>

          <View style={ingredientPdfStyles.graphContainer}>
            {/* trend container */}
            <View style={ingredientPdfStyles.trendContainer}>
              {/* trend filters */}
              <View style={pdfStyles.table}>
                <TableHeader headers={[INGREDIENT_TEXT, DOSAGE_FORM_TEXT, DOSAGE_TYPE_TEXT, YEAR_TEXT]} />
                <View style={pdfStyles.trBody}>
                  <View style={pdfStyles.td}>
                    <Text style={pdfStyles.tdText}>{ingredientName}</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text style={pdfStyles.tdText}>{productTypeName}</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text style={pdfStyles.tdText}>{subProductTypeName}</Text>
                  </View>
                  <View style={pdfStyles.tdLast}>
                    <Text style={pdfStyles.tdText}>{year}</Text>
                  </View>
                </View>
              </View>
              {lineCanvas && (
                <View style={ingredientPdfStyles.trendGraph}>
                  <View style={pdfStyles.mb10}>
                    <Text style={pdfStyles.thText}>{TREND_ANALYSIS_TEXT}</Text>
                  </View>

                  <Image src={lineCanvas} style={pdfStyles.graph} />
                </View>
              )}
            </View>

            {/* usage container */}
            <View style={ingredientPdfStyles.usageContainer}>
              <View style={pdfStyles.mb10}>
                <Text style={pdfStyles.thText}>{USAGE_TEXT}</Text>
              </View>

              <View style={pdfStyles.mb10}>
                <Text style={pdfStyles.tdText}>{ingredientUsage}</Text>
              </View>
              <View style={ingredientPdfStyles.usageGraph}>
                {pieCanvas && <Image src={pieCanvas} style={pdfStyles.graph} />}

                {/* dosage form pie chart labels */}
                <View style={[pdfStyles.w100, pdfStyles.flexRow, pdfStyles.flexWrap, pdfStyles.mt10]}>
                  {labels?.map((name, index) => {
                    return (
                      <View style={pdfStyles.w50}>
                        <View style={[pdfStyles.mb5, pdfStyles.flexCenter]}>
                          <View
                            style={[
                              pdfStyles.circleIcon,
                              {
                                backgroundColor: chartPieColors[index],
                              },
                            ]}
                          />
                          <View>
                            <Text style={pdfStyles.tdText}>{name || ''} </Text>
                          </View>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
          </View>

          {/* ingredient filters */}
          <View style={pdfStyles.table}>
            <TableHeader headers={[TOP_TEXT, DOSAGE_FORM_TEXT, DOSAGE_TYPE_TEXT, DATE_RANGE_TEXT]} />
            <View style={pdfStyles.trBody}>
              <View style={pdfStyles.td}>
                <Text style={pdfStyles.tdText}>{top}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text style={pdfStyles.tdText}>{dosageTypeName}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text style={pdfStyles.tdText}>{formulationName}</Text>
              </View>
              <View style={pdfStyles.tdLast}>
                <Text style={pdfStyles.tdText}>{dateRange}</Text>
              </View>
            </View>
          </View>

          {/* ingredient table */}
          <View style={pdfStyles.table}>
            <TableHeader
              headers={[
                RANK_TEXT,
                COMMON_NAME_TEXT,
                PRIMARY_CATEGORY_TEXT,
                SECONDARY_CATEGORY_TEXT,
                USAGE_TEXT,
              ]}
            />
            {data?.map((item) => {
              const { rank, name, usage, category, subCategory } = item || {};

              return (
                <View style={pdfStyles.trBody}>
                  <View style={pdfStyles.td}>
                    <Text style={pdfStyles.tdText}>{rank || '--'}</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text style={pdfStyles.tdText}>{name || '--'}</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text style={pdfStyles.tdText}>{category || '--'}</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text style={pdfStyles.tdText}>{subCategory || '--'}</Text>
                  </View>

                  <View style={pdfStyles.tdLast}>
                    <Text style={pdfStyles.tdText}>{usage || '--'}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default IngredientPdf;
