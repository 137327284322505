// imports
import { FC, memo, useCallback, useMemo, useState } from 'react';
// components
import InfiniteSelect from './InfiniteSelect';
// interfaces, constants, graphql
import { HTTP_STATUS } from 'constants/index';
import { IngredientSelectProps, SelectType } from 'interfaces';
import { IngredientsPayload, useFindAllIngredientsSelectorLazyQuery } from 'generated/graphql';

const IngredientSelect: FC<IngredientSelectProps> = ({
  name,
  title,
  value,
  handleChange,
  margin = 'dense',
  isClearable = false,
}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [getLoading, setGetLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<IngredientsPayload['data']>([]);

  const updatedOptions = useMemo(() => {
    if (options?.length) {
      return options?.map<SelectType>((item) => {
        const { id, name } = item || {};

        return {
          value: id,
          name: name || '',
        };
      });
    }
    return [];
  }, [options]);

  const [fetchSelectorData, { loading }] = useFindAllIngredientsSelectorLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllIngredients } = data;
      const { data: ingredients, response, pagination } = findAllIngredients || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { totalPages: pages } = pagination || {};
        setTotalPages(pages);
        setOptions([
          ...(getLoading ? [] : options || []),
          ...((ingredients as IngredientsPayload['data']) ?? []),
        ]);
        setGetLoading(false);
      } else {
        setGetLoading(false);
      }
    },
    onError: () => {
      setOptions([]);
      setGetLoading(false);
    },
  });

  const fetchData = useCallback(
    async (page?: number, query?: string) => {
      if (!page) setGetLoading(true);
      await fetchSelectorData({
        variables: {
          findAllIngredientsInput: {
            ...(query && { search: query }),
            paginationOptions: { page: page || 1, limit: 10 },
          },
        },
      });
    },
    [fetchSelectorData],
  );

  return (
    <InfiniteSelect
      name={name}
      title={title}
      value={value}
      margin={margin}
      loading={loading}
      fetchData={fetchData}
      setOptions={setOptions}
      totalPages={totalPages}
      getLoading={getLoading}
      options={updatedOptions}
      isClearable={isClearable}
      handleChange={handleChange}
    />
  );
};

export default memo(IngredientSelect);
