import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FC, useContext, useRef, useState } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, Grid, Typography, Divider } from '@mui/material';
import { CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
// components
import UserPdf from './UserPdf';
import UsersTable from './UserTable';
import UserStatsCard from './UserStatsCard';
import MostUsersCard from './MostUsersCard';
import UserGraphCard from './UserGraphCard';
import PdfViewer from '../components/Pdf/PdfViewer';
import DateRangePicker from 'components/common/DateRangePicker';
import OrgUserSelect from 'components/common/simpleSelectors/OrgUser';
// styles, constants, contexts
import { formatMomentStamp } from 'lib/helper';
import { SelectType, Nullable } from 'interfaces';
import { ActionType } from 'reducer/userReportReducer';
import { UserReportContext } from 'contexts/UserReportContext';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  ALL_TEXT,
  ALL_TIME_TEXT,
  ORG_OR_ADMIN_TEXT,
  USERS_REPORT_TEXT,
  DOWNLOAD_REPORT_TEXT,
} from 'constants/index';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

const Users: FC = () => {
  const canvasRef = useRef<ChartJSOrUndefined<'line'>>(null);
  const [userCanvas, setUserCanvas] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const { state, dispatch } = useContext(UserReportContext);
  const { fromDate, toDate, organization, data, userStats, users, year } = state;
  const { name } = organization || {};

  const handleOpen = () => {
    const canvasPng = canvasRef.current?.toBase64Image('image/png');
    setUserCanvas(canvasPng || '');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value: SelectType) => {
    dispatch({ type: ActionType.SET_ORGANIZATION, organization: value });
  };

  const fromDateChange = (value: Nullable<string>) => {
    dispatch({ type: ActionType.SET_FROM_DATE, fromDate: value || '' });
  };

  const toDateChange = (value: Nullable<string>) => {
    dispatch({ type: ActionType.SET_TO_DATE, toDate: value || '' });
  };

  return (
    <>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{USERS_REPORT_TEXT}</Typography>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          <FileDownloadIcon />
          {DOWNLOAD_REPORT_TEXT}
        </Button>
      </Box>

      {/* global_filters */}
      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <DateRangePicker
              fromDate={fromDate}
              toDate={toDate}
              fromDateChange={fromDateChange}
              toDateChange={toDateChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <OrgUserSelect
              isClearable
              name="org-admin"
              value={organization}
              title={ORG_OR_ADMIN_TEXT}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Box>

      <UserStatsCard />

      <Divider sx={{ my: 3 }} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <UserGraphCard ref={canvasRef} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <MostUsersCard />
        </Grid>
      </Grid>

      <Box mt={3}>
        <UsersTable />
      </Box>

      <PdfViewer
        open={open}
        title={USERS_REPORT_TEXT}
        handleClose={handleClose}
        document={
          open ? (
            <UserPdf
              year={year}
              userData={data}
              mostUsers={users}
              userStats={userStats}
              userCanvas={userCanvas}
              organizationName={name || ALL_TEXT}
              dateRange={
                fromDate
                  ? `${formatMomentStamp(fromDate)} - ${
                      toDate ? formatMomentStamp(toDate) : formatMomentStamp()
                    }`
                  : ALL_TIME_TEXT
              }
            />
          ) : (
            <></>
          )
        }
      />
    </>
  );
};

export default Users;
