// packages block
import { FC, Fragment } from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
// interfaces/types block
import { DataLoaderProps } from 'interfaces';

const skeltonReplica = (height = 80) => {
  return (
    <Box pl={2} pr={2} display="flex" justifyContent="space-between" alignItems="center">
      <Skeleton animation="wave" variant="text" width={'100%'} height={height} />
    </Box>
  );
};

const DataLoader: FC<DataLoaderProps> = ({ rows, columns, height }): JSX.Element => {
  const numberOfColumns: number = 12 / Number(columns);

  return (
    <Fragment>
      {Array.from(Array(rows).keys()).map((_, index) => (
        <Grid container key={`dataLoader-${index}`}>
          {new Array(numberOfColumns).fill(2).map((_, innerIndex) => (
            <Grid item xs={columns} key={`${innerIndex}-viewFormData`}>
              {skeltonReplica(height)}
            </Grid>
          ))}
        </Grid>
      ))}
    </Fragment>
  );
};

export default DataLoader;
