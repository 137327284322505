import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import SandCoatingFormulationForm from './FormCard';
import { Alert } from 'components/common/Alert';
import DataLoader from 'components/common/DataLoader';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { sandCoatingFormulationSchema } from 'validationSchema';
import { ParamType, SandCoatingFormulationFormType } from 'interfaces';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  useCreateSandCoatingFormulationMutation,
  useUpdateSandCoatingFormulationMutation,
  useGetSandCoatingFormulationLazyQuery,
  SandCoatingFormulationPayload,
  CoatingFormulationComponentUnit,
} from 'generated/graphql';
import {
  ADD_TEXT,
  HTTP_STATUS,
  SAND_COATING_FORMULATION_TEXT,
  sandCoatingFormulationInitialValue,
} from 'constants/index';
import { useAddTitleText, useUpdateTitleText, useAddLoadingText, useUpdateLoadingText } from '../../../hooks';

const Edit: FC = (): JSX.Element => {
  const addText = useAddTitleText(SAND_COATING_FORMULATION_TEXT);
  const updateText = useUpdateTitleText(SAND_COATING_FORMULATION_TEXT);
  const creatingText = useAddLoadingText(SAND_COATING_FORMULATION_TEXT);
  const updatingText = useUpdateLoadingText(SAND_COATING_FORMULATION_TEXT);

  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const methods = useForm<SandCoatingFormulationFormType>({
    defaultValues: sandCoatingFormulationInitialValue,
    resolver: yupResolver(sandCoatingFormulationSchema),
  });

  const { handleSubmit, setValue } = methods;

  const [createSandCoatingFormulation, { loading: createLoading }] = useCreateSandCoatingFormulationMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createSandCoatingFormulation } = data;
      const { response } = createSandCoatingFormulation || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updateSandCoatingFormulation, { loading: updateLoading }] = useUpdateSandCoatingFormulationMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateSandCoatingFormulation } = data;
      const { response } = updateSandCoatingFormulation || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getSandCoatingFormulation, { loading: getLoading }] = useGetSandCoatingFormulationLazyQuery({
    onCompleted: (data) => {
      const { getSandCoatingFormulation } = data || {};
      const { response, sandCoatingFormulation } = getSandCoatingFormulation || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(sandCoatingFormulation as SandCoatingFormulationPayload['sandCoatingFormulation']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onSubmit: SubmitHandler<SandCoatingFormulationFormType> = async (data) => {
    const { cost, sandCoating, weight, productSize, productType, subProductType } = data || {};
    const { value: sandCoatingId } = sandCoating || {};
    const { value: productSizeId } = productSize || {};
    const { value: productTypeId } = productType || {};
    const { value: subProductTypeId } = subProductType || {};

    if (id) {
      await updateSandCoatingFormulation({
        variables: {
          updateSandCoatingFormulationInput: {
            id: id || '',
            cost: parseFloat(cost),
            weight: parseFloat(weight),
            sandCoatingId,
            productTypeId: productTypeId,
            subProductTypeId: subProductTypeId ? subProductTypeId : null,
            productSizeId: productSizeId,
          },
        },
      });
    } else {
      await createSandCoatingFormulation({
        variables: {
          createSandCoatingFormulationInput: {
            cost: parseFloat(cost),
            weight: parseFloat(weight),
            sandCoatingId,
            unit: CoatingFormulationComponentUnit.Mg,
            productSizeId: productSizeId || '',
            productTypeId: productTypeId || '',
            ...(subProductTypeId && { subProductTypeId }),
          },
        },
      });
    }
  };

  const fetchSandCoatingFormulation = useCallback(async () => {
    id &&
      (await getSandCoatingFormulation({
        variables: {
          getSandCoatingFormulationInput: {
            id,
          },
        },
      }));
  }, [id, getSandCoatingFormulation]);

  const setValues = (params: SandCoatingFormulationPayload['sandCoatingFormulation']) => {
    const { weight, cost, sandCoating, productSize, productType, subProductType } = params || {};

    const { id: sandCoatingId, name: sandCoatingName } = sandCoating || {};
    const { id: productSizeId, name: productSizeName } = productSize || {};
    const { id: productTypeId, name: productTypeName } = productType || {};
    const { id: subProductTypeId, name: subProductTypeName } = subProductType || {};

    setValue('cost', cost?.toString() || '');
    setValue('weight', weight?.toString() || '');
    setValue('productSize', { value: productSizeId || '', name: productSizeName || '' });
    setValue('sandCoating', { value: sandCoatingId || '', name: sandCoatingName || '' });
    setValue('productType', { value: productTypeId || '', name: productTypeName || '' });
    setValue('subProductType', { value: subProductTypeId || '', name: subProductTypeName || '' });
  };

  useEffect(() => {
    id && fetchSandCoatingFormulation();
  }, [id, fetchSandCoatingFormulation]);

  const loading = updateLoading || createLoading;

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{id ? updateText : addText}</Typography>
            {!id && (
              <Button variant="contained" type="submit">
                {ADD_TEXT}
              </Button>
            )}
          </Box>

          {getLoading ? (
            <DataLoader columns={12} rows={6} />
          ) : (
            <SandCoatingFormulationForm loading={loading} isEdit={!!id} />
          )}
        </form>
      </FormProvider>

      <BackdropLoader open={loading} text={id ? updatingText : creatingText} />
    </Fragment>
  );
};

export default Edit;
