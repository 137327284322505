import { forwardRef } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
// components
import Selector from 'components/common/Selector';
import LineChart from '../components/Charts/LineChart';
// constants
import { UpTimeGraphProps } from 'interfaces';
import { getLastFiveYears } from 'lib/helper';
import { UP_TIME_TEXT, YEAR_TEXT } from 'constants/index';
import { ActionType } from 'reducer/platformReportReducer';

const UpTimeGraph = forwardRef<ChartJSOrUndefined<'line'>, UpTimeGraphProps>((props, ref) => {
  const { dispatch, year } = props || {};
  return (
    <Card>
      <CardHeader
        title={UP_TIME_TEXT}
        action={
          <Selector
            value={year}
            title={YEAR_TEXT}
            id="user-graph-selector"
            options={getLastFiveYears()}
            onChange={(val) => dispatch({ type: ActionType.SET_YEAR, year: val })}
          />
        }
      />
      <CardContent>
        <LineChart
          ref={ref}
          id="uptime"
          data={[100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100]}
        />
      </CardContent>
    </Card>
  );
});

export default UpTimeGraph;
