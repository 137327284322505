import { FC } from 'react';
import TableHeader from '../components/Pdf/TableHeader';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
// components
import Header from '../components/Pdf/Header';
// styles, constants, interfaces
import { PlatformPdfProps } from 'interfaces';
import { pageSize, pdfStyles } from 'styles/PdfStyles';
import {
  ANDROID_TEXT,
  ENHANCEMENTS_ADDED_TEXT,
  FEATURES_ADDED_TEXT,
  IOS_TEXT,
  PLATFORM_TEXT,
  UP_TIME_TEXT,
  USERS_TEXT,
  WEB_TEXT,
} from 'constants/index';

const PlatformPdf: FC<PlatformPdfProps> = ({ data, year, pieCanvas, lineCanvas, platformUsers }) => {
  return (
    <Document title={PLATFORM_TEXT}>
      <Page size={pageSize} style={pdfStyles.page}>
        <Header title={PLATFORM_TEXT} />
        <View style={pdfStyles.container}>
          {/* global_filters */}

          <View style={pdfStyles.table}>
            <TableHeader headers={[FEATURES_ADDED_TEXT, ENHANCEMENTS_ADDED_TEXT]} />
            <View style={pdfStyles.trBody}>
              <View style={pdfStyles.td}>
                <Text style={pdfStyles.tdText}>{data.feature}</Text>
              </View>
              <View style={pdfStyles.tdLast}>
                <Text style={pdfStyles.tdText}>{data.enhancement}</Text>
              </View>
            </View>
          </View>

          {/* graph container */}
          <View style={[pdfStyles.flexRow, pdfStyles.mV10]}>
            {/* up time container */}
            <View style={[pdfStyles.flex2, pdfStyles.mr10]}>
              {/* trend filters */}

              {lineCanvas && (
                <View style={[pdfStyles.p10, pdfStyles.border1]}>
                  <View style={pdfStyles.graphHeader}>
                    <View>
                      <Text style={pdfStyles.thText}>{UP_TIME_TEXT}</Text>
                    </View>

                    <View>
                      <Text style={pdfStyles.tdText}>{year}</Text>
                    </View>
                  </View>

                  <Image src={lineCanvas} style={pdfStyles.graph} />
                </View>
              )}
            </View>

            {/* user container */}
            <View style={[pdfStyles.p10, pdfStyles.border1, pdfStyles.flex1]}>
              <View style={pdfStyles.mb10}>
                <Text style={pdfStyles.thText}>{USERS_TEXT}</Text>
              </View>
              <View style={pdfStyles.mH10}>
                {pieCanvas && <Image src={pieCanvas} style={pdfStyles.graph} />}

                {/* dosage form pie chart labels */}
                <View style={[pdfStyles.table, pdfStyles.mt10]}>
                  <View style={pdfStyles.trBody}>
                    <View style={pdfStyles.td}>
                      <Text style={pdfStyles.tdText}>{WEB_TEXT}</Text>
                    </View>
                    <View style={pdfStyles.tdLast}>
                      <Text style={pdfStyles.tdText}>{platformUsers.web}</Text>
                    </View>
                  </View>

                  <View style={pdfStyles.trBody}>
                    <View style={pdfStyles.td}>
                      <Text style={pdfStyles.tdText}>{IOS_TEXT}</Text>
                    </View>
                    <View style={pdfStyles.tdLast}>
                      <Text style={pdfStyles.tdText}>{platformUsers.ios}</Text>
                    </View>
                  </View>

                  <View style={pdfStyles.trBody}>
                    <View style={pdfStyles.td}>
                      <Text style={pdfStyles.tdText}>{ANDROID_TEXT}</Text>
                    </View>
                    <View style={pdfStyles.tdLast}>
                      <Text style={pdfStyles.tdText}>{platformUsers.android}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PlatformPdf;
