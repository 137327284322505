import { FC, Fragment, JSX } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Card, CardActions, CardContent, Grid } from '@mui/material';
// components
import InputController from 'controller/InputController';
import SandCoatingSelector from 'components/common/selectors/sandCoating';
import ProductSizeSelector from 'components/common/selectors/productSize';
import ProductTypesSelector from 'components/common/selectors/productType';
import SubProductTypeSelector from 'components/common/selectors/productSubType';
// constants, styles, interfaces
import { SandCoatingFormulationFormProps, SandCoatingFormulationFormType } from 'interfaces';
import {
  COST_TEXT,
  PRODUCT_SIZE_TEXT,
  PRODUCT_TYPE_TEXT,
  SAND_COATING_TEXT,
  SAVE_TEXT,
  selectTypeInitialValues,
  SUB_PRODUCT_TYPE_TEXT,
  WEIGHT_TEXT,
} from 'constants/index';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';

const SandCoatingFormulationForm: FC<SandCoatingFormulationFormProps> = ({
  isEdit = false,
  loading = false,
}): JSX.Element => {
  const { watch, setValue } = useFormContext<SandCoatingFormulationFormType>();
  const { productType, subProductType } = watch();
  const { value: productTypeId } = productType || {};
  const { value: subProductTypeId } = subProductType || {};

  const onProductChange = () => {
    setValue('subProductType', selectTypeInitialValues);
    setValue('productSize', selectTypeInitialValues);
  };

  const onSubProductChange = () => {
    setValue('productSize', selectTypeInitialValues);
  };

  return (
    <Card sx={forInputLabelBg}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <InputController isRequired fieldType="number" name="cost" disabled={loading} title={COST_TEXT} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <InputController
              isRequired
              fieldType="number"
              name="weight"
              disabled={loading}
              title={`${WEIGHT_TEXT} (mg)`}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ProductTypesSelector
              isRequired
              title={PRODUCT_TYPE_TEXT}
              name="productType"
              handleChange={onProductChange}
            />
          </Grid>

          <Grid item xs={12} lg={12}>
            <SubProductTypeSelector
              name="subProductType"
              productTypeId={productTypeId}
              title={SUB_PRODUCT_TYPE_TEXT}
              disabled={!productTypeId}
              handleChange={onSubProductChange}
            />
          </Grid>
          <Grid item xs={12} xl={12}>
            <ProductSizeSelector
              isRequired
              name="productSize"
              title={PRODUCT_SIZE_TEXT}
              disabled={!productTypeId}
              productSubTypeId={subProductTypeId}
              productTypeId={productTypeId}
            />
          </Grid>
          <Grid item xs={12} xl={12}>
            <SandCoatingSelector isRequired name="sandCoating" title={SAND_COATING_TEXT} />
          </Grid>
        </Grid>
      </CardContent>

      {isEdit ? (
        <CardActions>
          <Box sx={flexMarginTopEndCenter}>
            <Button type="submit" variant="contained" size="small" disabled={loading}>
              {SAVE_TEXT}
            </Button>
          </Box>
        </CardActions>
      ) : (
        <Fragment />
      )}
    </Card>
  );
};

export default SandCoatingFormulationForm;
