import {
  CoatingFormulationComponentPayload,
  CoatingFormulationComponentsPayload,
  SandCoatingFormulationPayload,
} from 'generated/graphql';

export type State = {
  addOpen: boolean;
  editOpen: boolean;
  data: CoatingFormulationComponentsPayload['data'];
  sandCoatingFormulation: SandCoatingFormulationPayload['sandCoatingFormulation'];
  coatingFormulationComponent: CoatingFormulationComponentPayload['coatingFormulationComponent'];
};

export const initialState: State = {
  data: [],
  addOpen: false,
  editOpen: false,
  sandCoatingFormulation: null,
  coatingFormulationComponent: null,
};

export enum ActionType {
  SET_DATA = 'setData',
  SET_ADD_OPEN = 'setAddOpen',
  SET_EDIT_OPEN = 'setEditOpen',
  SET_ACTIVE_TAB = 'setActiveTab',
  SET_ROWS_PER_PAGE = 'setRowsPerPage',
  SET_SAND_COATING_FORMULATION = 'setSandCoatingFormulation',
  SET_COATING_FORMULATION_COMPONENT = 'setCoatingFormulationComponent',
}

export type Action =
  | { type: ActionType.SET_ADD_OPEN; addOpen: boolean }
  | { type: ActionType.SET_EDIT_OPEN; editOpen: boolean }
  | { type: ActionType.SET_DATA; data: CoatingFormulationComponentsPayload['data'] }
  | {
      type: ActionType.SET_SAND_COATING_FORMULATION;
      sandCoatingFormulation: SandCoatingFormulationPayload['sandCoatingFormulation'];
    }
  | {
      type: ActionType.SET_COATING_FORMULATION_COMPONENT;
      coatingFormulationComponent: CoatingFormulationComponentPayload['coatingFormulationComponent'];
    };

export const CoatingFormulationComponentReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_DATA:
      return {
        ...state,
        data: action.data,
      };

    case ActionType.SET_ADD_OPEN:
      return {
        ...state,
        addOpen: action.addOpen,
      };

    case ActionType.SET_EDIT_OPEN:
      return {
        ...state,
        editOpen: action.editOpen,
      };

    case ActionType.SET_SAND_COATING_FORMULATION:
      return {
        ...state,
        sandCoatingFormulation: action.sandCoatingFormulation,
      };

    case ActionType.SET_COATING_FORMULATION_COMPONENT:
      return {
        ...state,
        coatingFormulationComponent: action.coatingFormulationComponent,
      };
  }
};
