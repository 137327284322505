import { yupResolver } from '@hookform/resolvers/yup';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
// components
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
import CoatingFormulationComponentForm from './components/CoatingFormulationComponentForm';
// Schema, styles, graphql, constants
import { CloseIcon } from 'assets/svgs';
import { coatingFormulationComponentSchema } from 'validationSchema';
import { useUpdateCoatingFormulationComponentMutation } from 'generated/graphql';
import {
  SAVE_TEXT,
  HTTP_STATUS,
  coatingFormulationComponentInitialValues,
  EDIT_COATING_FORMULATION_COMPONENT_TEXT,
  COATING_FORMULATION_IS_UPDATED_TEXT,
} from 'constants/index';
import { EditCoatingFormulationComponentProps, CoatingFormulationComponentFormType } from 'interfaces';

const Edit: FC<EditCoatingFormulationComponentProps> = (props): JSX.Element => {
  const { coatingFormulationComponent, sandCoatingFormulation, open, onClose, fetch } = props || {};
  const { id } = coatingFormulationComponent || {};

  const methods = useForm<CoatingFormulationComponentFormType>({
    defaultValues: coatingFormulationComponentInitialValues,
    resolver: yupResolver(coatingFormulationComponentSchema),
  });

  const { handleSubmit, setValue, clearErrors } = methods;

  const [updateCoatingFormulationComponent, { loading }] = useUpdateCoatingFormulationComponentMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateCoatingFormulationComponent } = data;
      const { response } = updateCoatingFormulationComponent || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        fetch();
        onClose();
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const handleClick = () => {
    clearErrors();
    onClose();
  };

  const onSubmit: SubmitHandler<CoatingFormulationComponentFormType> = async (data) => {
    const { sandComponent, weight, sandCoatingFormulation } = data || {};
    const { value: sandComponentId } = sandComponent || {};
    const { value: sandCoatingFormulationId } = sandCoatingFormulation || {};

    await updateCoatingFormulationComponent({
      variables: {
        updateCoatingFormulationComponentInput: {
          id: id || '',
          sandComponentId: sandComponentId,
          sandCoatingFormulationId: sandCoatingFormulationId,
          weight: parseFloat(weight),
        },
      },
    });
  };

  const setValues = useCallback(() => {
    if (open && coatingFormulationComponent) {
      const { weight, sandComponent } = coatingFormulationComponent || {};
      const { id: sandComponentId, name: sandComponentName } = sandComponent || {};
      const { id: sandCoatingFormulationId } = sandCoatingFormulation || {};

      setValue('weight', `${weight ?? ''}`);
      setValue('sandComponent', {
        value: sandComponentId ?? '',
        name: sandComponentName ?? '',
      });
      setValue('sandCoatingFormulation', {
        value: sandCoatingFormulationId ?? '',
        name: '',
      });
    }
  }, [open, coatingFormulationComponent, sandCoatingFormulation, setValue]);

  useEffect(() => {
    open && setValues();
  }, [open, setValues]);

  return (
    <Fragment>
      <Dialog open={open}>
        <DialogTitle variant="h6">{EDIT_COATING_FORMULATION_COMPONENT_TEXT}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClick}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent dividers>
              <CoatingFormulationComponentForm loading={loading} />
            </DialogContent>

            <DialogActions>
              <Box>
                <Button type="submit" variant="contained" size="small" disabled={loading}>
                  {SAVE_TEXT}
                </Button>
              </Box>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      <BackdropLoader open={loading} text={COATING_FORMULATION_IS_UPDATED_TEXT} />
    </Fragment>
  );
};

export default Edit;
