import { ChangeEvent, FC, useMemo, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Grid, DialogActions, Button } from '@mui/material';
// interfaces, reducer, constants
import { EditDialogProps } from 'interfaces';
import { initialState, ActionType } from 'reducer/platformReportReducer';
import {
  SAVE_TEXT,
  CLOSE_TEXT,
  PLATFORM_TEXT,
  FEATURES_ADDED_TEXT,
  ENHANCEMENTS_ADDED_TEXT,
} from 'constants/index';

const EditDialog: FC<EditDialogProps> = ({ handleClose, dispatch, open, data }) => {
  const [selected, setSelected] = useState(initialState.data);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelected((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const onUpdate = () => {
    dispatch({ type: ActionType.SET_DATA, data: selected });
    handleClose();
  };

  useMemo(() => {
    setSelected(data);
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle> {PLATFORM_TEXT}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="outlined-feature"
              variant="outlined"
              fullWidth
              name="feature"
              label={FEATURES_ADDED_TEXT}
              value={selected.feature}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="outlined-enhancement"
              variant="outlined"
              fullWidth
              name="enhancement"
              label={ENHANCEMENTS_ADDED_TEXT}
              value={selected.enhancement}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose} sx={{ mr: 2 }}>
          {CLOSE_TEXT}
        </Button>

        <Button variant="contained" color="primary" onClick={onUpdate}>
          {SAVE_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
