import { FC, JSX } from 'react';
import { Grid } from '@mui/material';
// components
import SandCoatingFormulationForm from './Form';
// interfaces
import { SandCoatingFormulationFormProps } from 'interfaces';

const AddSandCoatingFormulationForm: FC<SandCoatingFormulationFormProps> = ({
  loading,
  isEdit = false,
}): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={12}>
        <SandCoatingFormulationForm loading={loading} isEdit={isEdit} />
      </Grid>
    </Grid>
  );
};

export default AddSandCoatingFormulationForm;
