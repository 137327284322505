import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import ProductIngredientForm from './FormCard';
import { Alert } from 'components/common/Alert';
import DataLoader from 'components/common/DataLoader';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { sandCoatingSchema } from 'validationSchema';
import { ParamType, SandCoatingFormType } from 'interfaces';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  useCreateSandCoatingMutation,
  useUpdateSandCoatingMutation,
  useGetSandCoatingLazyQuery,
  SandCoatingPayload,
} from 'generated/graphql';
import { ADD_TEXT, HTTP_STATUS, SAND_COATING_TEXT, sandCoatingInitialValue } from 'constants/index';
import { useAddTitleText, useUpdateTitleText, useAddLoadingText, useUpdateLoadingText } from '../../../hooks';

const Edit: FC = (): JSX.Element => {
  const addText = useAddTitleText(SAND_COATING_TEXT);
  const updateText = useUpdateTitleText(SAND_COATING_TEXT);
  const creatingText = useAddLoadingText(SAND_COATING_TEXT);
  const updatingText = useUpdateLoadingText(SAND_COATING_TEXT);

  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const methods = useForm<SandCoatingFormType>({
    defaultValues: sandCoatingInitialValue,
    resolver: yupResolver(sandCoatingSchema),
  });

  const { handleSubmit, setValue } = methods;

  const [createSandCoating, { loading: createLoading }] = useCreateSandCoatingMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createSandCoating } = data;
      const { response } = createSandCoating || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updateSandCoating, { loading: updateLoading }] = useUpdateSandCoatingMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateSandCoating } = data;
      const { response } = updateSandCoating || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getSandCoating, { loading: getLoading }] = useGetSandCoatingLazyQuery({
    onCompleted: (data) => {
      const { getSandCoating } = data || {};
      const { response, sandCoating } = getSandCoating || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(sandCoating as SandCoatingPayload['sandCoating']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onSubmit: SubmitHandler<SandCoatingFormType> = async (data) => {
    const { name } = data || {};

    if (id) {
      await updateSandCoating({
        variables: {
          updateSandCoatingInput: {
            id: id || '',
            name,
          },
        },
      });
    } else {
      await createSandCoating({
        variables: {
          createSandCoatingInput: {
            name,
          },
        },
      });
    }
  };

  const fetchSandCoating = useCallback(async () => {
    id &&
      (await getSandCoating({
        variables: {
          getSandCoatingInput: {
            id,
          },
        },
      }));
  }, [id, getSandCoating]);

  const setValues = (params: SandCoatingPayload['sandCoating']) => {
    const { name } = params || {};

    setValue('name', name || '');
  };

  useEffect(() => {
    id && fetchSandCoating();
  }, [id, fetchSandCoating]);

  const loading = updateLoading || createLoading;

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{id ? updateText : addText}</Typography>
            {!id && (
              <Button variant="contained" type="submit">
                {ADD_TEXT}
              </Button>
            )}
          </Box>

          {getLoading ? (
            <DataLoader columns={12} rows={6} />
          ) : (
            <ProductIngredientForm loading={loading} isEdit={!!id} />
          )}
        </form>
      </FormProvider>

      <BackdropLoader open={loading} text={id ? updatingText : creatingText} />
    </Fragment>
  );
};

export default Edit;
