import { SimplePaletteColorOptions } from '@mui/material';
// components, theme, constants
import palette from 'theme/palette';
import { DashboardStatsChartImg, TopContributorImg } from 'assets/svgs';
import { FORMULAS_CREATED_TEXT, TOTAL_INGREDIENTS_TEXT, TOTAL_USERS_TEXT } from './constant';
import { SelectType } from 'interfaces';

// palette
const {
  primary,
  customRedColor,
  bluePaletteColor,
  customCommonColor,
  redRgbaColor,
  customGreenColor,
  customShadowColor,
} = palette || {};

const { main } = (primary as SimplePaletteColorOptions) || {};
const { light: shadowLight } = (customShadowColor as SimplePaletteColorOptions) || {};
const { dark: darkGreen } = (customGreenColor as SimplePaletteColorOptions) || {};
const { contrastText } = (customRedColor as SimplePaletteColorOptions) || {};
const { main: redRgbaMain } = (redRgbaColor as SimplePaletteColorOptions) || {};
const { main: yellowRgbaMain } = (customCommonColor as SimplePaletteColorOptions) || {};
const { main: bluePaletteMain, light } = (bluePaletteColor as SimplePaletteColorOptions) || {};

export const DASHBOARD_STATS_CARD = [
  {
    title: TOTAL_USERS_TEXT,
    stats: '18,765',
    icon: <DashboardStatsChartImg color={main} />,
  },

  {
    title: TOTAL_INGREDIENTS_TEXT,
    stats: '4,876',
    icon: <DashboardStatsChartImg color={bluePaletteMain} />,
  },

  {
    title: FORMULAS_CREATED_TEXT,
    stats: '678',
    icon: <DashboardStatsChartImg color={contrastText} />,
  },
];

export const TOP_CONTRIBUTOR_CARD_DATA = [
  {
    avatar: 'https://www.thenews.com.pk/assets/uploads/updates/2023-01-20/1032535_9141370_gdgd_updates.jpg',
    name: 'Jacqueline Likoki',
    formulas: '38 Formulas',
    icon: <TopContributorImg color={main} />,
    bgColor: `${yellowRgbaMain}`,
  },

  {
    avatar: 'https://www.thenews.com.pk/assets/uploads/updates/2023-01-20/1032535_9141370_gdgd_updates.jpg',
    name: 'Patrick Win',
    formulas: '22 Formulas',
    icon: <TopContributorImg color={bluePaletteMain} />,
    bgColor: `${light}`,
  },

  {
    avatar: 'https://www.thenews.com.pk/assets/uploads/updates/2023-01-20/1032535_9141370_gdgd_updates.jpg',
    name: 'Linda Mazor',
    formulas: '18 Formulas',
    icon: <TopContributorImg color={contrastText} />,
    bgColor: `${redRgbaMain}`,
  },
];

export const TOP_CONTRIBUTOR_COLORS = [main, bluePaletteMain, contrastText, darkGreen, shadowLight];

export const DEFINING_AMOUNT_DATA: SelectType[] = [
  {
    value: `1`,
    name: `1`,
  },
  {
    value: `100`,
    name: `100`,
  },
];

export const CONSENT_DATA = [
  {
    title: 'General Terms',
    description:
      'Minors are not permitted to use this Site.  You must be eighteen (18) years of age or older and a resident of the United States to use this Site.  You represent that all information, data and other materials provided to this Site or to Path Forward Formulator are true, accurate, current and complete.  You are responsible for updating and correcting any information that is not true, accurate, current or complete, as appropriate.',
    paras: [
      'Path Forward Formulator is controlled and operated from its offices within the United States. Path Forward Formulator makes no representation that the information, features, products, and services available on this Site are for use in other locations outside of the United States.',
    ],
  },
  {
    title: 'Privacy Policy',
    description:
      'Path Forward Formulator’s Privacy Policy, which applies to the collection, use, storage, disclosure, and other processing of personal information by Path Forward Formulator, may be accessed by clicking on the following link: Privacy Policy. You consent to any personal information that we may collect, use, store, disclose, and otherwise process in accordance with the terms of our Privacy Policy.',
  },
  {
    title: 'License and Site Access',
    description:
      'All content available through this Site (including, without limitation, text, design, graphics, logos, images, as well as the selection and arrangement thereof) is the exclusive property of Path Forward Formulator, its vendors, suppliers, affiliates, partners or its content providers, and is protected by copyright, trademark and other applicable US intellectual property laws.',
    paras: [
      'Path Forward Formulator grants you a limited license to access and make personal use of this Site.  Unless otherwise indicated, you may access, copy, download and print the content available for this Site for your personal, non-commercial use, provided you do not modify or delete any copyright, trademark or other intellectual property notices that appear in the content.  Path Forward Formulator retains full and complete title to the content available on this Site, including all associated intellectual property rights, and provides this content to you under a limited license that is revocable at any time in Path Forward Formulator’s sole discretion and without notice. Path Forward Formulator strictly prohibits any other use of any content available through this Site. Termination of your use and access of this Site will not waive or affect any other right, relief or remedies to which Path Forward Formulator may be entitled, at law or in equity.',
    ],
  },
  {
    title: 'Information You Submit',
    description:
      'You acknowledge that you are responsible for any information submitted to this Site. As stated above, you represent that all information, data and other materials provided to this Site or to Path Forward Formulator are true, accurate, current and complete. As such, you may not use a false email address or other false identifying information, impersonate any person or entity or other mislead as to the origin of any information.  Some features available on this Site may require registration. By registering, again, you agree to provide true, accurate, current and complete information about yourself.',
  },
  {
    title: 'Member Accounts',
    description:
      'In order to use certain features of the Site, you may need to create an account.  If the Site requires you to create a member account, you may never use another member’s account without permission.  If you are making a purchase on the Site, you may be asked to create a member account and provide credit card information.  In such event, it is our policy to redirect you to a payment processing portal hosted by a third party payment processor.  Please read the privacy policy on our payment processor’s website regarding use, storage and protection of your credit card information before submitting any credit card information.',
  },
  {
    title: 'Third Party Links',
    description:
      'This Site may contain links to other websites, resources, or other online venues that are operated by third parties not affiliated with Path Forward Formulator.  These links are provided as a convenience to you and as an additional avenue of access to the information contained therein. We are in no way responsible or liable for any third party, content, advertising, products, services, or other materials on or available for such websites, resources, or other online venues.  Inclusion of links to other websites, resources, or online venues should not be considered as an endorsement of the content therein.  Different terms and conditions and privacy policies may apply to your use of any linked sites, resources, or online venues.  Path Forward Formulator is not responsible or liable, directly or indirectly, for any damage, loss or liability caused or alleged to be caused by or in connection with any use of or reliance on such third party content, advertising, products, services, or other materials available on or through any such linked website, resource, or other online venue.',
  },
  {
    title: 'Third Party Materials',
    description:
      'The Site may display, or make available third-party content (including data, information, applications, and other products, services, and/or materials) or provide links to third-party websites or services, including through third-party advertising (“Third-Party Materials”). You acknowledge and agree that Path Forward Formulator is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Path Forward Formulator does not assume and will not have any liability or responsibility to you or any other person or entity for any Third-Party Materials. Third-Party Materials and links thereto and provided solely as a convenience to you, and your access and use is entirely at your own risk and subject to such third parties’ terms and conditions and privacy policies.',
  },
  {
    title: 'Social Media Sites',
    description:
      'This Site may include features and functionality that allows you to interact with social media websites (“Social Media Sites”). Path Forward Formulator provides these features, functionality and links to you only as a convenience and does not endorse any Social Media Sites. Path Forward Formulator is not responsible for the contents or transmission of any Social Media Site or for the terms of use or privacy practices of any Social Media Site. You should carefully read the policies of any site you visit.  Also, in the event that you use any Social Media Site to comment upon Path Forward Formulator or any of its products and/or services, you agree that you will always clearly and conspicuously disclose any material connection you may have with Path Forward Formulator (if any) or any consideration you may receive from Path Forward Formulator in connection with your comment (if any). Under no circumstances are you authorized to make any claim regarding Path Forward Formulator or any of its products on any Social Media Site regardless of any material connection you may have with Path Forward Formulator or your receipt of any consideration. IF YOU MAKE ANY CLAIM REGARDING PATH FORWARD FORMULATOR OR ANY OF PATH FORWARD FORMULATOR’S PRODUCTS AND/OR SERVICES ON A SOCIAL MEDIA SITE IN VIOLATION OF THE FOREGOING, YOU, AND NOT PATH FORWARD FORMULATOR, SHALL BE THE SOLE AUTHOR OF SUCH CLAIM AND SHALL BE SOLELY LIABLE THEREFORE.',
  },
  {
    title: 'Disclaimer of Warranties',
    description:
      'EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THESE TERMS OF USE, AND TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, PATH FORWARD FORMULATOR MAKES NO REPRESENTATIONS, COVENANTS OR WARRANTIES AND OFFERS NO OTHER CONDITIONS, EXPRESS OR IMPLIED, REGARDING ANY MATTER, INCLUDING, WITHOUT LIMITATION, THE MERCHANTABILITY, SUITABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, ANY CONTENT ON THIS SITE, OR ANY PRODUCTS OR SERVICES OFFERED OR PURCHASED FROM PATH FORWARD FORMULATOR, AS WELL AS WARRANTIES IMPLIED FROM A COURSE OF PERFORMANCE OR COURSE OF DEALING.',
    paras: [
      'YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. THIS SITE AND THE MATERIALS, INFORMATION, SERVICES AND PRODUCTS ON THIS SITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WE RESERVE THE RIGHT TO RESTRICT OR TERMINATE YOUR ACCESS TO THIS SITE OR ANY FEATURE OR PART THEREOF AT ANY TIME. PATH FORWARD FORMULATOR DISCLAIMS ANY WARRANTIES THAT ACCESS TO THIS SITE WILL BE UNINTERRUPTED OR ERROR-FREE; THAT THIS SITE WILL BE SECURE; THAT THIS SITE OR THE SERVER THAT MAKES THIS SITE AVAILABLE WILL BE VIRUS-FREE; OR THAT INFORMATION ON THIS SITE WILL BE CORRECT, ACCURATE, ADEQUATE, USEFUL, TIMELY, RELIABLE OR OTHERWISE COMPLETE. IF YOU DOWNLOAD ANY CONTENT FROM THIS SITE, YOU DO SO AT YOUR OWN DISCRETION AND RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH CONTENT. NO ADVICE OR INFORMATION OBTAINED BY YOU FROM THIS SITE SHALL CREATE ANY WARRANTY OF ANY KIND.',
      'Certain states or jurisdictions do not allow limitations on implied warranties. If these laws apply to you, some or all of the above disclaimers may not apply and you may have additional rights. To the extent we may not, as a matter of applicable law, disclaim any implied warranty, the scope and duration of such warranty will be the minimum permitted under such law.',
    ],
  },
  {
    title: 'Limitation of Liability',
    description:
      'CERTAIN PROVIDERS OF PRODUCTS AND SERVICES ARE LISTED ON THIS SITE AND THESE PROVIDERS ARE NOT AGENTS OR EMPLOYEES OF PATH FORWARD FORMULATOR. PATH FORWARD FORMULATOR SHALL NOT BE LIABLE FOR THE ACTS, ERRORS, OMISSIONS, REPRESENTATIONS, WARRANTIES, BREACHES OR NEGLIGENCE OF ANY SUCH PROVIDER OR FOR ANY PERSONAL INJURIES, DEATH, PROPERTY DAMAGE, OR OTHER DAMAGES OR EXPENSES RESULTING THEREFROM.',
    paras: [
      'YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY FOR YOUR USE OF THIS SITE, COMMUNICATIONS WITH THIRD PARTIES, AND PURCHASE AND USE OF PRODUCTS AND SERVICES FROM PROVIDERS LISTED ON THIS SITE. YOU ACKNOWLEDGE AND AGREE THAT ANY INFORMATION YOU SEND OR RECEIVE DURING YOUR USE OF THIS SITE MAY NOT BE SECURE AND MAY BE INTERCEPTED BY UNAUTHORIZED PARTIES. YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THIS SITE IS AT YOUR OWN RISK AND THAT THIS SITE IS MADE AVAILABLE TO YOU AT NO CHARGE. RECOGNIZING SUCH, YOU ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW (INCLUDING, WITHOUT LIMITATION, CONSUMER PROTECTION LAW), PATH FORWARD FORMULATOR SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, EXEMPLARY, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR OTHER DAMAGES ARISING OUT OF OR IN ANY WAY RELATED TO THIS SITE; (2) ANY ACTION WE TAKE OR FAIL TO TAKE AS A RESULT OF COMMUNICATIONS YOU SEND TO US; (3) ANY PRODUCTS OR SERVICES MADE AVAILABLE OR PURCHASED THROUGH THE PROVIDERS LISTED ON THIS SITE, INCLUDING ANY DAMAGES OR INJURY ARISING FROM ANY USE OF SUCH PRODUCTS OR SERVICES; (4) ANY DELAY OR INABILITY TO USE THIS SITE OR ANY INFORMATION, PRODUCTS OR SERVICES ADVERTISED IN OR OBTAINED THROUGH THIS SITE; (5) THE MODIFICATION, REMOVAL OR DELETION OF ANY CONTENT SUBMITTED OR POSTED ON THIS SITE; OR (6) ANY USE OF THIS SITE, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF PATH FORWARD FORMULATOR HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. IN NO EVENT SHALL OUR MAXIMUM AGGREGATE LIABILITY EXCEED ONE HUNDRED DOLLARS ($100.00). IT IS THE RESPONSIBILITY OF THE USER (YOU) TO EVALUATE THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY OPINION, ADVICE OR OTHER CONTENT AVAILABLE THROUGH THIS SITE, OR OBTAINED FROM A LINKED SITE OR RESOURCE.',
      'YOU AND PATH FORWARD FORMULATOR AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THIS SITE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES OR THE CAUSE OF ACTION IS PERMANENTLY BARRED.',
      'Certain states or jurisdictions do not allow the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above exclusions or limitations may not apply and you may have additional rights. To the extent we may not, as a matter of applicable law, limit our liabilities, the extent of our liability will be the minimum permitted under such law.',
    ],
  },
  {
    title: 'Indemnification',
    description:
      'You agree to indemnify, defend and hold harmless Path Forward Formulator and its partners, officers, directors, agents, contractors, service providers, subcontractors, suppliers, vendors, employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your breach to these Terms of Use or the agreements they incorporate by reference, or your violation of any law or the rights of a third-party.  We reserve the right to take exclusive control and defense of any claim subject to this indemnification by you, in which event you will cooperate fully with us in asserting any and all available defenses.',
  },
  {
    title: 'Electronic Communications',
    description:
      'When you use this Site or send emails to Path Forward Formulator, you are communicating with Path Forward Formulator electronically. You consent to receive electronically any communications related to your use of this Site. Path Forward Formulator will communicate with you by email or by posting notices on this Site. You agree that all agreements, notices, disclosures and other communications that are provided to you electronically satisfy any legal requirement that such communications be in writing.',
  },
  {
    title: 'Governing Law',
    description:
      'This Agreement is governed by and construed in accordance with the laws of the State of Illinois, without giving effect to any choice or conflict of law provisions or rule. Any legal suit, action, or proceeding arising or related to this Agreement shall be instituted exclusively in the federal courts or state courts located in Cook County, Chicago, Illinois. You waive any and all objects to the exercise of jurisdiction over you by such courts and to venue in such courts.',
  },
  {
    title: 'Survival of Terms',
    description:
      'Notwithstanding any other provisions of these Terms of Use, or any general legal principles to the contrary, any provision of these Terms of Use that imposes or contemplates continuing obligations on a party will survive the expiration or termination of these Terms of Use.',
  },
  {
    title: 'Force Majeure',
    description:
      'Path Forward Formulator shall be excused from performance under these Terms of Use, to the extent it is prevented from or delayed from performing, in whole or in part, as a result of an event or series of events caused by or resulting from (1) weather conditions or other elements of nature or acts of God, (2) disease, epidemics or quarantines; (3) acts of war, acts of terrorism, insurrection, riots, civil disorders or rebellion, (4) embargoes, (5) labor strikes, or (6) other causes beyond the reasonable control of Path Forward Formulator.',
  },
  {
    title: 'Publicity',
    description:
      'During the term of these Terms of Use, you hereby grant to Company the limited right to use the name of your business, trademarks, and related rights in connection with Company’s marketing purposes (including, but not limited to, brochures, websites, and other promotional materials',
  },
  {
    title: 'Assignment',
    description:
      'These Terms of Use, and any rights and licenses granted hereunder, may not be transferred or assigned to you, but may be assigned by Path Forward Formulator without restriction. ',
  },
  {
    title: 'Severability',
    description:
      'In the event that a provision hereof is deemed to be illegal or unenforceable, such a determination shall not affect the validity or enforceability of the remaining provisions, hereof, all which shall remain in full force and effect.',
  },
  {
    title: 'Entire Agreement',
    description:
      'The failure of us to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision. These Terms of Use and any policies or operating rules posted by us on this Site constitutes the entire agreement and understanding between you and us and govern your use of this Site, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Use).  Any ambiguities in the interpretation of these Terms of Use shall not be construed against the drafting party.',
  },
  {
    title: 'Contact Information',
    description: 'Questions about these Terms of Use should be sent to us at info@pathforwardformulator.com.',
  },
];
