import { SelectType } from 'interfaces';
import { UserTabType, UserTypeTab } from 'interfaces/TabTypes';
import { PAGE_LIMIT, selectTypeInitialValues } from 'constants/index';
import {
  FindUserStats,
  OrganizationsUsersCountPayload,
  UserStatus,
  UsersCountByYearPayload,
  UsersPayload,
} from 'generated/graphql';

export type State = {
  page: number;
  count: number;
  search: string;
  rowsPerPage: number;
  activeTab: UserTabType;
  data: UsersPayload['data'];
  userType: UserTypeTab;
  organization: SelectType;
  userStats: FindUserStats;
  fromDate: string;
  toDate: string;
  year: string;
  usersCountByYear: UsersCountByYearPayload['data'];
  users: OrganizationsUsersCountPayload['data'];
};

export const initialState: State = {
  page: 1,
  count: 0,
  data: [],
  users: [],
  search: '',
  toDate: '',
  fromDate: '',
  userType: 'all',
  usersCountByYear: [],
  rowsPerPage: PAGE_LIMIT,
  activeTab: UserStatus.Active,
  year: `${new Date().getFullYear()}`,
  organization: selectTypeInitialValues,
  userStats: {
    staff: 0,
    orgAdmins: 0,
    activeUsers: 0,
  },
};

export enum ActionType {
  SET_PAGE = 'setPage',
  SET_DATA = 'setData',
  SET_COUNT = 'setCount',
  SET_SEARCH = 'setSearch',
  SET_ACTIVE_TAB = 'setActiveTab',
  SET_ROWS_PER_PAGE = 'setRowsPerPage',
  SET_ORGANIZATION = 'setOrganizationId',
  SET_TYPE = 'setType',
  SET_FROM_DATE = 'setFromDate',
  SET_TO_DATE = 'setToDate',
  SET_YEAR = 'setYear',
  SET_USERS_COUNT_BY_YEAR = 'setUsersCountByYear',
  SET_USER_STATS = 'setUserStats',
  SET_ORG_ADMIN = 'setOrgAdmin',
  SET_USER = 'setUser',
}

export type Action =
  | { type: ActionType.SET_PAGE; page: State['page'] }
  | { type: ActionType.SET_DATA; data: State['data'] }
  | { type: ActionType.SET_COUNT; count: State['count'] }
  | { type: ActionType.SET_SEARCH; search: State['search'] }
  | { type: ActionType.SET_TO_DATE; toDate: State['toDate'] }
  | { type: ActionType.SET_TYPE; userType: State['userType'] }
  | { type: ActionType.SET_FROM_DATE; fromDate: State['fromDate'] }
  | { type: ActionType.SET_ACTIVE_TAB; activeTab: State['activeTab'] }
  | { type: ActionType.SET_ROWS_PER_PAGE; rowsPerPage: State['rowsPerPage'] }
  | { type: ActionType.SET_ORGANIZATION; organization: State['organization'] }
  | { type: ActionType.SET_YEAR; year: State['year'] }
  | { type: ActionType.SET_USERS_COUNT_BY_YEAR; usersCountByYear: State['usersCountByYear'] }
  | { type: ActionType.SET_USER_STATS; userStats: State['userStats'] }
  | { type: ActionType.SET_USER; users: State['users'] };

export const userReportReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case ActionType.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
      };

    case ActionType.SET_COUNT:
      return {
        ...state,
        count: action.count,
      };

    case ActionType.SET_DATA:
      return {
        ...state,
        data: action.data,
      };

    case ActionType.SET_SEARCH:
      return {
        ...state,
        search: action.search,
      };

    case ActionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };

    case ActionType.SET_ORGANIZATION:
      return {
        ...state,
        organization: action.organization,
      };

    case ActionType.SET_TYPE:
      return {
        ...state,
        userType: action.userType,
      };

    case ActionType.SET_FROM_DATE:
      return {
        ...state,
        fromDate: action.fromDate,
      };

    case ActionType.SET_TO_DATE:
      return {
        ...state,
        toDate: action.toDate,
      };

    case ActionType.SET_YEAR:
      return {
        ...state,
        year: action.year,
      };

    case ActionType.SET_USERS_COUNT_BY_YEAR:
      return {
        ...state,
        usersCountByYear: action.usersCountByYear,
      };

    case ActionType.SET_USER_STATS:
      return {
        ...state,
        userStats: action.userStats,
      };

    case ActionType.SET_USER:
      return {
        ...state,
        users: action.users,
      };
  }
};
