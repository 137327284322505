import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
// components
import SandComponentListing from './SandComponentTable';
// constant, styles, reducer, graphql
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { ADD_NEW_TEXT, ADD_SAND_COMPONENT_ROUTE, SAND_COMPONENT_TEXT } from 'constants/index';

const SandComponent: FC = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{SAND_COMPONENT_TEXT}</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate(ADD_SAND_COMPONENT_ROUTE)}>
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button>
      </Box>
      <SandComponentListing />
    </Fragment>
  );
};

export default SandComponent;
