// imports
import { FC } from 'react';
import PieChartIcon from '@mui/icons-material/PieChart';
// component
import SidebarSubList from 'components/common/SubSidebar';
//constants
import { REPORT_USERS_ROUTE, REPORTS_TEXT, SUPER_ADMIN_REPORTING_LIST } from 'constants/index';

const ReportingList: FC = () => (
  <SidebarSubList
    defaultRoute={REPORT_USERS_ROUTE}
    mainTitle={REPORTS_TEXT}
    list={SUPER_ADMIN_REPORTING_LIST}
    icon={<PieChartIcon />}
  />
);

export default ReportingList;
