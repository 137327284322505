import { FC, useCallback, useContext, useEffect } from 'react';
import { Avatar, Box, Card, Typography } from '@mui/material';
// components
import { TopContributorImg } from 'assets/svgs';
import DataLoader from 'components/common/DataLoader';
// styles, interfaces, constants
import palette from 'theme/palette';
import { flexCenter } from 'theme/styleConstant';
import { ActionType } from 'reducer/userReportReducer';
import { UserReportContext } from 'contexts/UserReportContext';
import { TOP_CONTRIBUTOR_COLORS } from 'constants/arrayMappedData';
import { MOST_USERS_TEXT, USERS_TEXT, HTTP_STATUS } from 'constants/index';
import { flexCenterBetween, userAvatar } from 'styles/commonComponentStyle';
import {
  OrganizationsUsersCountPayload,
  useFindAllOrganizationsUsersCountLazyQuery,
} from 'generated/graphql';

const MostUsersCard: FC = () => {
  const { state, dispatch } = useContext(UserReportContext);
  const { users } = state;

  const [findAllOrganizationsUsersCount, { loading }] = useFindAllOrganizationsUsersCountLazyQuery({
    onCompleted: (data) => {
      const { findAllOrganizationsUsersCount } = data || {};
      const { data: usersData, response } = findAllOrganizationsUsersCount || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        dispatch({ type: ActionType.SET_USER, users: usersData as OrganizationsUsersCountPayload['data'] });
      } else {
        dispatch({ type: ActionType.SET_USER, users: [] });
      }
    },

    onError: () => {
      dispatch({ type: ActionType.SET_USER, users: [] });
    },
  });

  const fetchUsers = useCallback(async () => {
    await findAllOrganizationsUsersCount();
  }, [findAllOrganizationsUsersCount]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <Card>
      <Typography variant="h6" mb={3}>
        {MOST_USERS_TEXT}
      </Typography>

      {loading ? (
        <DataLoader rows={5} columns={6} />
      ) : (
        users?.map((item, index) => {
          const { id, name, usersCount } = item || {};
          const bgColor = TOP_CONTRIBUTOR_COLORS[index];

          return (
            <Box key={id} sx={flexCenterBetween}>
              <Box sx={flexCenter}>
                <Box width={40} height={40} mr={2} borderRadius="50%" sx={userAvatar}>
                  <Avatar />
                </Box>

                <Box>
                  <Typography variant="subtitle2" mb={0.5} color={palette.common?.white}>
                    {name}
                  </Typography>
                  <Typography variant="body1" color="customGrayColor.main">
                    {usersCount} {USERS_TEXT}
                  </Typography>
                </Box>
              </Box>

              <TopContributorImg color={bgColor} />
            </Box>
          );
        })
      )}
    </Card>
  );
};

export default MostUsersCard;
