import { FC, Fragment, JSX } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
// components
import { CloseIcon } from 'assets/svgs';
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
import CoatingFormulationComponentForm from './components/CoatingFormulationComponentForm';
// Schema, styles, graphql, constants, helper
import {
  ADD_TEXT,
  HTTP_STATUS,
  coatingFormulationComponentInitialValues,
  ADD_COATING_FORMULATION_COMPONENT_TEXT,
  COATING_FORMULATION_IS_CREATING_TEXT,
} from 'constants/index';
import { coatingFormulationComponentSchema } from 'validationSchema';
import {
  CoatingFormulationComponentUnit,
  useCreateCoatingFormulationComponentMutation,
} from 'generated/graphql';
import { AddCoatingFormulationComponentProps, CoatingFormulationComponentFormType } from 'interfaces';

const Add: FC<AddCoatingFormulationComponentProps> = ({
  onClose,
  open,
  fetch,
  sandCoatingFormulation,
}): JSX.Element => {
  const methods = useForm<CoatingFormulationComponentFormType>({
    defaultValues: coatingFormulationComponentInitialValues,
    resolver: yupResolver(coatingFormulationComponentSchema),
  });

  const { handleSubmit, setValue, clearErrors } = methods;

  const [createCoatingFormulationComponent, { loading }] = useCreateCoatingFormulationComponentMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createCoatingFormulationComponent } = data;
      const { response } = createCoatingFormulationComponent || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        fetch();
        onClose();
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });
  const handleClick = () => {
    resetFormValue();
    clearErrors();
    onClose();
  };

  const resetFormValue = () => {
    setValue('sandCoatingFormulation', { value: '', name: '' });
    setValue('sandComponent', { value: '', name: '' });
    setValue('weight', '');
  };
  const onSubmit: SubmitHandler<CoatingFormulationComponentFormType> = async (data) => {
    const { sandComponent, weight } = data || {};
    const { value: sandComponentId } = sandComponent || {};
    const { id } = sandCoatingFormulation || {};

    await createCoatingFormulationComponent({
      variables: {
        createCoatingFormulationComponentInput: {
          sandComponentId,
          sandCoatingFormulationId: id || '',
          weight: parseFloat(weight),
          unit: CoatingFormulationComponentUnit.Mg,
        },
      },
    });
  };

  return (
    <Fragment>
      <Dialog open={open}>
        <DialogTitle variant="h6">{ADD_COATING_FORMULATION_COMPONENT_TEXT}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClick}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent dividers>
              <CoatingFormulationComponentForm loading={loading} />
            </DialogContent>

            <DialogActions>
              <Button type="submit" variant="contained" size="small" disabled={loading}>
                {ADD_TEXT}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      <BackdropLoader open={loading} text={COATING_FORMULATION_IS_CREATING_TEXT} />
    </Fragment>
  );
};

export default Add;
