import { FC } from 'react';
import { Grid } from '@mui/material';
// components
import Selector from 'components/common/Selector';
import DateRangePicker from 'components/common/DateRangePicker';
import ProductTypeSelect from 'components/common/simpleSelectors/ProductType';
import SubProductTypeSelect from 'components/common/simpleSelectors/SubProductType';
// constants, interfaces, reducer
import {
  TOP_TEXT,
  TOP_MAPPED_DATA,
  DOSAGE_FORM_TEXT,
  DOSAGE_TYPE_TEXT,
  selectTypeInitialValues,
} from 'constants/index';
import { ActionType } from 'reducer/ingredientReportReducer';
import { Nullable, SelectType, TableFiltersProps } from 'interfaces';

const TableFilters: FC<TableFiltersProps> = ({
  top,
  toDate,
  fromDate,
  dispatch,
  dosageType,
  formulationType,
}) => {
  const handleProductTypeChange = (value: SelectType) => {
    dispatch({ type: ActionType.SET_DOSAGE_TYPE, dosageType: value });
    dispatch({ type: ActionType.SET_FORMULATION_TYPE, formulationType: selectTypeInitialValues });
  };

  const handleSubProductTypeChange = (value: SelectType) => {
    dispatch({ type: ActionType.SET_FORMULATION_TYPE, formulationType: value });
  };

  const handleFromDateChange = (value: Nullable<string>) => {
    dispatch({ type: ActionType.SET_FROM_DATE, fromDate: value || '' });
  };

  const handleToDateChange = (value: Nullable<string>) => {
    dispatch({ type: ActionType.SET_TO_DATE, toDate: value || '' });
  };

  const handleTop = (value: string) => {
    dispatch({ type: ActionType.SET_TOP, top: value });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
        <Selector
          value={top}
          title={TOP_TEXT}
          id="user-graph-selector"
          options={TOP_MAPPED_DATA}
          onChange={handleTop}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
        <ProductTypeSelect
          isClearable
          name={'dosageForm'}
          value={dosageType}
          title={DOSAGE_FORM_TEXT}
          handleChange={handleProductTypeChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
        <SubProductTypeSelect
          isClearable
          name={'subType'}
          title={DOSAGE_TYPE_TEXT}
          value={formulationType}
          productTypeId={dosageType?.value}
          handleChange={handleSubProductTypeChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={6} xl={6}>
        <DateRangePicker
          fromDate={fromDate}
          toDate={toDate}
          fromDateChange={handleFromDateChange}
          toDateChange={handleToDateChange}
        />
      </Grid>
    </Grid>
  );
};

export default TableFilters;
