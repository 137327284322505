import { useNavigate } from 'react-router-dom';
import { Box, Chip, Grid, TableCell, TableRow } from '@mui/material';
import { FC, Fragment, Reducer, useCallback, useEffect, useReducer } from 'react';
// components
import { Alert } from 'components/common/Alert';
import TableLoader from 'components/common/TableLoader';
import NoDataFound from 'components/common/NoDataFound';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
import TableTabsComponent from 'components/common/TableTabsComponent';
import SandCoatingSelect from 'components/common/simpleSelectors/SandCoating';
import ProductSizeSelect from 'components/common/simpleSelectors/ProductSize';
import ProductTypesSelect from 'components/common/simpleSelectors/ProductType';
import SubProductTypeSelect from 'components/common/simpleSelectors/SubProductType';
// constants, reducers, graphql, styles
import {
  TABS,
  TabEnum,
  ACTIVE_TEXT,
  HTTP_STATUS,
  INACTIVE_TEXT,
  LOADING_TABLE_ROWS,
  SAND_COATING_FORMULATION_TABLE_HEADER,
  EDIT_SAND_COATING_FORMULATION_ROUTE,
  PRODUCT_SIZE_TEXT,
  SAND_COATING_TEXT,
  COATING_FORMULATION_COMPONENT_ROUTE,
  PRODUCT_TYPE_TEXT,
  SUB_PRODUCT_TYPE_TEXT,
  selectTypeInitialValues,
} from 'constants/index';
import { cursorPointer, textWhiteSpace } from 'styles/commonComponentStyle';
import {
  useFindAllSandCoatingFormulationLazyQuery,
  SandCoatingFormulationsPayload,
  useUpdateSandCoatingFormulationMutation,
} from 'generated/graphql';
import {
  State,
  Action,
  ActionType,
  initialState,
  sandCoatingFormulationReducer,
} from 'reducer/sandCoatingFormulationReducer';
import { SelectType } from 'interfaces';

const SandCoatingFormulationTable: FC = () => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(sandCoatingFormulationReducer, initialState);
  const {
    page,
    rowsPerPage,
    search,
    count,
    data,
    activeTab,
    productSize,
    sandCoating,
    productType,
    subProductType,
  } = state;

  const { value: sandCoatingId } = sandCoating;
  const { value: productSizeId } = productSize;
  const { value: productTypeId } = productType;
  const { value: subProductTypeId } = subProductType;
  const navigate = useNavigate();

  const [findAllSandCoatingsFormulations, { loading: fetchLoading, error }] =
    useFindAllSandCoatingFormulationLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { findAllSandCoatingFormulation } = data;
        const { response, data: sandCoatingFormulation, pagination } = findAllSandCoatingFormulation || {};
        const { status } = response || {};
        if (status === HTTP_STATUS.SUCCESS) {
          const { page, totalCount } = pagination || {};
          dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
          dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
          dispatch({
            type: ActionType.SET_DATA,
            data: sandCoatingFormulation as SandCoatingFormulationsPayload['data'],
          });
        } else {
          resetPage();
        }
      },

      onError: () => {
        resetPage();
      },
    });

  const [updateSandCoatingFormulationStatus, { loading: updateLoading }] =
    useUpdateSandCoatingFormulationMutation({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { updateSandCoatingFormulation } = data;
        const { response } = updateSandCoatingFormulation || {};
        const { message, status } = response || {};
        if (status === HTTP_STATUS.SUCCESS) {
          Alert.success(message || '');
          fetchAllSandCoatingFormulations();
        } else {
          Alert.error(message ?? '');
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const onProductChange = (item: SelectType) => {
    dispatch({ type: ActionType.SET_PRODUCT_TYPE, productType: item });
    dispatch({ type: ActionType.SET_SUB_PRODUCT_TYPE, subProductType: selectTypeInitialValues });
    dispatch({ type: ActionType.SET_PRODUCT_SIZE, productSize: selectTypeInitialValues });
  };

  const onSubProductChange = (item: SelectType) => {
    dispatch({ type: ActionType.SET_SUB_PRODUCT_TYPE, subProductType: item });
    dispatch({ type: ActionType.SET_PRODUCT_SIZE, productSize: selectTypeInitialValues });
  };

  const fetchAllSandCoatingFormulations = useCallback(async () => {
    await findAllSandCoatingsFormulations({
      variables: {
        findAllSandCoatingFormulationInput: {
          paginationOptions: {
            limit: rowsPerPage,
            page,
          },
          ...(sandCoatingId && { sandCoatingId }),
          ...(productSizeId && { productSizeId }),
          ...(productTypeId && { productTypeId }),
          ...(subProductTypeId && { subProductTypeId }),
          ...(activeTab !== TabEnum.All && { isActive: activeTab === TabEnum.Active }),
        },
      },
    });
  }, [
    findAllSandCoatingsFormulations,
    rowsPerPage,
    page,
    activeTab,
    sandCoatingId,
    productSizeId,
    productTypeId,
    subProductTypeId,
  ]);

  useEffect(() => {
    (!search.length || search.length > 2) && fetchAllSandCoatingFormulations();
  }, [fetchAllSandCoatingFormulations, search.length]);

  const tabHandler = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab: value as TabEnum });
  };

  const onActiveHandler = async (id: string, status: boolean) => {
    await updateSandCoatingFormulationStatus({
      variables: {
        updateSandCoatingFormulationInput: {
          id,
          isActive: !status,
        },
      },
    });
  };

  const loading = fetchLoading || updateLoading;

  const noData = Boolean((!fetchLoading && data?.length === 0) || error);

  return (
    <TableContainer>
      <TableTabsComponent activeTab={activeTab} tabsList={TABS} tabHandler={tabHandler} />

      <Box m={({ spacing }) => spacing(2, 3)}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SandCoatingSelect
              isClearable
              name="sand-coating"
              value={sandCoating}
              title={SAND_COATING_TEXT}
              handleChange={(item) => dispatch({ type: ActionType.SET_SAND_COATING, sandCoating: item })}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ProductTypesSelect
              isClearable
              value={productType}
              name="product-type"
              title={PRODUCT_TYPE_TEXT}
              handleChange={onProductChange} // {(item) => dispatch({ type: ActionType.SET_PRODUCT_TYPE, productType: item })}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SubProductTypeSelect
              isClearable
              value={subProductType}
              name="sub-product-type"
              title={SUB_PRODUCT_TYPE_TEXT}
              productTypeId={productTypeId}
              handleChange={onSubProductChange}
            />
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ProductSizeSelect
              isClearable
              value={productSize}
              name="product-size"
              productTypeId={productTypeId}
              subProductTypeId={subProductTypeId}
              title={PRODUCT_SIZE_TEXT}
              handleChange={(item) => dispatch({ type: ActionType.SET_PRODUCT_SIZE, productSize: item })}
            />
          </Grid>
        </Grid>
      </Box>

      <TableComponent
        noData={noData}
        page={page - 1}
        count={count}
        rowsPerPage={rowsPerPage}
        tableHeader={SAND_COATING_FORMULATION_TABLE_HEADER}
        setPage={(p: number) => dispatch({ type: ActionType.SET_PAGE, page: p + 1 })}
        setRowsPerPage={(r: number) => dispatch({ type: ActionType.SET_ROWS_PER_PAGE, rowsPerPage: r })}>
        {loading ? (
          <TableLoader columns={8} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const { id, weight, cost, sandCoating, productSize, isActive, subProductType, productType } =
                cell || {};
              const { name: coatingName } = sandCoating || {};
              const { name } = productSize || {};
              const { name: subProductTypeName } = subProductType || {};
              const { name: productTypeName } = productType || {};
              return (
                <TableRow key={id}>
                  <TableCell
                    sx={cursorPointer}
                    onClick={() => navigate(`${COATING_FORMULATION_COMPONENT_ROUTE}/${id}`)}>
                    <b>{coatingName ?? '--'}</b>
                  </TableCell>
                  <TableCell sx={textWhiteSpace}>{productTypeName ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{subProductTypeName ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{name ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{weight ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{`$${cost}`}</TableCell>

                  <TableCell>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={isActive ? ACTIVE_TEXT : INACTIVE_TEXT}
                      color={isActive ? 'success' : 'error'}
                    />
                  </TableCell>

                  <TableCell>
                    <ActionMenuDropdown
                      id={id ?? ''}
                      isActive={!!isActive}
                      editRoute={EDIT_SAND_COATING_FORMULATION_ROUTE}
                      onActiveHandler={onActiveHandler}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default SandCoatingFormulationTable;
