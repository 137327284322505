import { Bar } from 'react-chartjs-2';
import { forwardRef, useMemo } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
// interfaces, constants, utils
import { BarHorizontalProps } from 'interfaces';
import { defaultBarHorizontalDataset, showIntegerValues } from './utils';

const options: ChartOptions<'bar'> = {
  responsive: true,
  indexAxis: 'y',
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        callback: showIntegerValues,
        stepSize: 1, // This will show grid lines at every integer value
      },
      grid: {
        tickLength: 1,
      },
    },
  },
};

const BarHorizontal = forwardRef<ChartJSOrUndefined<'bar'>, BarHorizontalProps>(
  ({ dataSet, labels }, ref) => {
    const datasets = useMemo<ChartData<'bar'>>(() => {
      const chartData: ChartData<'bar'> = {
        labels: [],
        datasets: [defaultBarHorizontalDataset],
      };

      if (dataSet?.length) {
        const datasets: ChartDataset<'bar'>[] = [
          {
            ...defaultBarHorizontalDataset,
            data: dataSet,
          },
        ];

        chartData.datasets = datasets;
        chartData.labels = labels;

        return chartData;
      }
      return chartData;
    }, [dataSet, labels]);

    return <Bar data={datasets} options={options} ref={ref} />;
  },
);

export default BarHorizontal;
