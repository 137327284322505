// helper, graphql, constants, interfaces
import { createEnumMappedArray, formatValue } from 'lib/helper';
import { RadioGroupControllerExtendedProps, SelectType } from 'interfaces';
import {
  VeganType,
  FactBoxType,
  BottleCapType,
  IngredientType,
  ProteinBaseType,
  ServingUnitType,
  TabletCoatingColor,
  SweetenerNatureType,
  FormulaIngredientUnit,
  FormulaIngredientType,
  MiniTabFormulationType,
  LoadAddUnit,
  BottleTypeEnum,
  SweetenerSizeType,
  Scope,
} from 'generated/graphql';

export const SINGLE_TABLET_COLOR_MAPPED: RadioGroupControllerExtendedProps[] = [
  { name: formatValue(TabletCoatingColor.Single), value: TabletCoatingColor.Single },
];

export const TABLET_COLOR_MAPPED: RadioGroupControllerExtendedProps[] = [
  ...SINGLE_TABLET_COLOR_MAPPED,
  { name: formatValue(TabletCoatingColor.Multi), value: TabletCoatingColor.Multi },
];

export const PROTEIN_BASE_MAPPED: RadioGroupControllerExtendedProps[] = [
  {
    name: formatValue(ProteinBaseType.Animal),
    value: ProteinBaseType.Animal,
    subTitle: '(Dairy, Beef, Chicken)',
  },
  {
    name: formatValue(ProteinBaseType.Plant),
    value: ProteinBaseType.Plant,
    subTitle: '(Pea, Rice, Soya)',
  },
];
export const INGREDIENTS_TYPE_MAPPED: SelectType[] = createEnumMappedArray(IngredientType);
export const LOAD_ADD_UNIT_MAPPED: SelectType[] = createEnumMappedArray(LoadAddUnit);
export const VEGAN_MAPPED: SelectType[] = createEnumMappedArray(VeganType);
export const FACT_BOX_MAPPED: SelectType[] = createEnumMappedArray(FactBoxType);
export const BOTTLE_CAP_MAPPED: SelectType[] = createEnumMappedArray(BottleCapType);
export const SERVING_UNIT_MAPPED: SelectType[] = createEnumMappedArray(ServingUnitType);
export const SWEETENER_NATURE_MAPPED: SelectType[] = createEnumMappedArray(SweetenerNatureType);
export const SWEETENER_SIZE_MAPPED: SelectType[] = createEnumMappedArray(SweetenerSizeType);
export const UNIT_SELECT_MAPPED: SelectType[] = createEnumMappedArray(FormulaIngredientUnit, false, true);
export const TABLET_FORMULATION_MAPPED: SelectType[] = createEnumMappedArray(MiniTabFormulationType);
export const FORMULA_INGREDIENT_TYPE_MAPPED: SelectType[] = createEnumMappedArray(FormulaIngredientType);
export const BOTTLE_TYPE_MAPPED: SelectType[] = createEnumMappedArray(BottleTypeEnum, false);
export const SCOPE_MAPPED: SelectType[] = createEnumMappedArray(Scope);

export const TOP_MAPPED_DATA: SelectType[] = [
  {
    name: '10',
    value: '10',
  },
  {
    name: '50',
    value: '50',
  },
  {
    name: '100',
    value: '100',
  },
  {
    name: '200',
    value: '200',
  },
];
