import { Grid } from '@mui/material';
import { FC, useCallback, useContext, useEffect } from 'react';
// components
import StatsCard from '../components/StatsCard';
// constants
import {
  HTTP_STATUS,
  TOTAL_STAFF_TEXT,
  TOTAL_ORG_ADMINS_TEXT,
  TOTAL_ACTIVE_USERS_TEXT,
} from 'constants/index';
import { UserReportContext } from 'contexts/UserReportContext';
import { useFindUsersStatsLazyQuery } from 'generated/graphql';
import { ActionType, initialState } from 'reducer/userReportReducer';

const UserStatsCard: FC = () => {
  const { state, dispatch } = useContext(UserReportContext);
  const { organization, fromDate, toDate, userStats } = state;
  const { activeUsers, orgAdmins, staff } = userStats || {};

  const [findUsersStats, { loading }] = useFindUsersStatsLazyQuery({
    onCompleted: (res) => {
      const { findUsersStats } = res || {};
      const { data, response } = findUsersStats || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        dispatch({ type: ActionType.SET_USER_STATS, userStats: data });
      } else {
        dispatch({ type: ActionType.SET_USER_STATS, userStats: initialState.userStats });
      }
    },

    onError: () => {
      dispatch({ type: ActionType.SET_USER_STATS, userStats: initialState.userStats });
    },
  });

  const fetchUsersStats = useCallback(async () => {
    await findUsersStats({
      variables: {
        findUsersStatsInput: {
          ...(toDate && { toDate }),
          ...(fromDate && { fromDate }),
          ...(organization?.value && { organizationId: organization.value }),
        },
      },
    });
  }, [findUsersStats, fromDate, organization.value, toDate]);

  useEffect(() => {
    fetchUsersStats();
  }, [fetchUsersStats]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <StatsCard
          loading={loading}
          stats={`${activeUsers}`}
          title={TOTAL_ACTIVE_USERS_TEXT}
          // downfall={false}
          // description={`${0.5}% ${INCREASE_THIS_MONTH_TEXT}`}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <StatsCard
          loading={loading}
          stats={`${orgAdmins}`}
          title={TOTAL_ORG_ADMINS_TEXT}
          // downfall={true}
          // description={`-${0.7}% ${DECREASE_THIS_MONTH_TEXT}`}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <StatsCard
          loading={loading}
          stats={`${staff}`}
          title={TOTAL_STAFF_TEXT}
          // downfall={false}
          // description={`${0.5}% ${INCREASE_THIS_MONTH_TEXT}`}
        />
      </Grid>
    </Grid>
  );
};

export default UserStatsCard;
