import { Box, Button, Grid, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FC, JSX, Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
// components
import DosagePdf from './DosagePdf';
import StackBarCard from './StackBarCard';
import DosageBarCard from './DosageBarCard';
import PdfViewer from '../components/Pdf/PdfViewer';
import FormulationBarCard from './FormulationBarCard';
import DosageFormPercentage from './DosageFormPercentage';
import DateRangePicker from 'components/common/DateRangePicker';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import OrgUserSelect from 'components/common/simpleSelectors/OrgUser';
// constants, styles, graphql, interfaces, contexts, reducer
import { formatMomentStamp } from 'lib/helper';
import { Nullable, SelectType } from 'interfaces';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { DosageReportContext } from 'contexts/DosageReportContext';
import { DosageFormulasPayload, useFindAllDosageTypeFormulasLazyQuery } from 'generated/graphql';
import { Action, ActionType, dosageReportReducer, initialState, State } from 'reducer/dosageReportReducer';
import {
  ALL_TEXT,
  HTTP_STATUS,
  ALL_TIME_TEXT,
  DOWNLOAD_REPORT_TEXT,
  DOSAGE_FORM_REPORT_TEXT,
  ORG_OR_ADMIN_TEXT,
} from 'constants/index';

const DosageForm: FC = (): JSX.Element => {
  const pieRef = useRef<ChartJSOrUndefined<'pie'>>(null);
  const dosageRef = useRef<ChartJSOrUndefined<'bar'>>(null);
  const stackedRef = useRef<ChartJSOrUndefined<'bar'>>(null);
  const horizontalRef = useRef<ChartJSOrUndefined<'bar'>>(null);

  const [dosageState, dosageDispatch] = useReducer<Reducer<State, Action>>(dosageReportReducer, initialState);

  const { data, open, dosageCanvas, formCanvas, formulationCanvas, pieCanvas } = dosageState || {};

  const { state, dispatch } = useContext(DosageReportContext);
  const { fromDate, toDate, organization, productType, subProductType } = state;
  const { value: organizationId, name } = organization || {};
  const { name: productTypeName } = productType || {};
  const { name: subProductTypeName } = subProductType || {};

  const [findDosageFormula, { loading }] = useFindAllDosageTypeFormulasLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllDosageTypeFormulas } = data;
      const { response, data: formulas } = findAllDosageTypeFormulas || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        dosageDispatch({ type: ActionType.SET_DATA, data: formulas as DosageFormulasPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const { dataSet, labels, total } = useMemo(() => {
    if (data?.length) {
      const total = data?.reduce((prev, curr) => {
        const { count } = curr || {};
        prev = prev + (count || 0);

        return prev;
      }, 0);
      const labels = data?.map((item) => {
        const { name } = item || {};
        return name || '';
      });

      const dataSet = data?.map((item) => {
        const { count } = item || {};
        return count || 0;
      });

      return { labels, dataSet, total };
    }
    return {
      labels: [],
      dataSet: [],
      total: 0,
    };
  }, [data]);

  const resetPage = () => {
    dosageDispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchFormulas = useCallback(async () => {
    await findDosageFormula({
      variables: {
        findAllDosageTypeFormulasInput: {
          ...(toDate && { toDate }),
          ...(fromDate && { fromDate }),
          ...(organizationId && { organizationId }),
        },
      },
    });
  }, [findDosageFormula, fromDate, organizationId, toDate]);

  useEffect(() => {
    fetchFormulas();
  }, [fetchFormulas]);

  const handleClose = () => {
    dosageDispatch({ type: ActionType.SET_OPEN, open: false });
  };

  const handleChange = (value: SelectType) => {
    dispatch({ type: ActionType.SET_ORGANIZATION, organization: value });
  };

  const fromDateChange = (value: Nullable<string>) => {
    dispatch({ type: ActionType.SET_FROM_DATE, fromDate: value || '' });
  };

  const toDateChange = (value: Nullable<string>) => {
    dispatch({ type: ActionType.SET_TO_DATE, toDate: value || '' });
  };

  const handlePieCanvas = (val: string) => {
    dosageDispatch({ type: ActionType.SET_PIE_CANVAS, pieCanvas: val });
  };

  const handleDosageCanvas = (val: string) => {
    dosageDispatch({ type: ActionType.SET_DOSAGE_CANVAS, dosageCanvas: val });
  };

  const handleFormulationCanvas = (val: string) => {
    dosageDispatch({ type: ActionType.SET_FORMULATION_CANVAS, formulationCanvas: val });
  };

  const handleFormCanvas = (val: string) => {
    dosageDispatch({ type: ActionType.SET_FORM_CANVAS, formCanvas: val });
  };

  const handleOpen = () => {
    const pieRefCanvas = pieRef?.current?.toBase64Image('image/png');
    const dosageRefCanvas = dosageRef?.current?.toBase64Image('image/png');
    const stackedRefCanvas = stackedRef?.current?.toBase64Image('image/png');
    const horizontalRefCanvas = horizontalRef?.current?.toBase64Image('image/png');
    handlePieCanvas(pieRefCanvas || '');
    handleDosageCanvas(dosageRefCanvas || '');
    handleFormCanvas(stackedRefCanvas || '');
    handleFormulationCanvas(horizontalRefCanvas || '');
    setTimeout(() => {
      dosageDispatch({ type: ActionType.SET_OPEN, open: true });
    }, 0);
  };

  return (
    <>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{DOSAGE_FORM_REPORT_TEXT}</Typography>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          <FileDownloadIcon />
          {DOWNLOAD_REPORT_TEXT}
        </Button>
      </Box>

      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <DateRangePicker
              fromDate={fromDate}
              toDate={toDate}
              fromDateChange={fromDateChange}
              toDateChange={toDateChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <OrgUserSelect
              isClearable
              name="org-admin"
              value={organization}
              title={ORG_OR_ADMIN_TEXT}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DosageFormPercentage
            data={data}
            labels={labels}
            dataSet={dataSet}
            loading={loading}
            totalFormulas={total}
            ref={pieRef}
          />
        </Grid>

        <Grid item xs={12}>
          <DosageBarCard dataSet={dataSet} labels={labels} loading={loading} ref={horizontalRef} />
        </Grid>

        <Grid item xs={12}>
          <StackBarCard ref={stackedRef} />
        </Grid>

        <Grid item xs={12}>
          <FormulationBarCard ref={dosageRef} />
        </Grid>
      </Grid>

      <PdfViewer
        open={open}
        title={DOSAGE_FORM_REPORT_TEXT}
        handleClose={handleClose}
        document={
          open ? (
            <DosagePdf
              total={total}
              data={data}
              pieCanvas={pieCanvas}
              formCanvas={formCanvas}
              dosageCanvas={dosageCanvas}
              productTypeName={productTypeName}
              organizationName={name || ALL_TEXT}
              formulationCanvas={formulationCanvas}
              subProductTypeName={subProductTypeName}
              dateRange={
                fromDate
                  ? `${formatMomentStamp(fromDate)} - ${
                      toDate ? formatMomentStamp(toDate) : formatMomentStamp()
                    }`
                  : ALL_TIME_TEXT
              }
            />
          ) : (
            <></>
          )
        }
      />
    </>
  );
};

export default DosageForm;
