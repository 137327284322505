// components
import AdminGeneralList from 'components/Layouts/AdminSidebar/GeneraList';
import StaffGeneralList from 'components/Layouts/StaffSidebar/GeneralList';
import ReportingList from 'components/Layouts/SuperAdminSidebar/ReportingList';
import StaffManagementList from 'components/Layouts/StaffSidebar/ManagementList';
import AdminManagementList from 'components/Layouts/AdminSidebar/ManagementList';
import SuperAdminGeneralList from 'components/Layouts/SuperAdminSidebar/GeneralList';
import SuperAdminManagementList from 'components/Layouts/SuperAdminSidebar/ManagementList';
import { DashboardIcon, FormulaIcon, IngredientsIcon, SupplierIcon, UsersIcon } from 'assets/svgs';
// constants
import {
  USERS_TEXT,
  STAFF_TEXT,
  STAFF_ROUTE,
  USERS_ROUTE,
  GENERAL_TEXT,
  FORMULA_TEXT,
  FORMULA_ROUTE,
  DASHBOARD_TEXT,
  SUPPLIERS_TEXT,
  SUPPLIER_ROUTE,
  MANAGEMENT_TEXT,
  DASHBOARD_ROUTE,
  PRODUCT_SIZE_TEXT,
  INGREDIENTS_TEXT,
  INGREDIENTS_ROUTE,
  PRODUCT_TYPE_TEXT,
  PRODUCT_TYPE_ROUTE,
  PRODUCT_SIZE_ROUTE,
  SUB_PRODUCT_TYPE_TEXT,
  SUB_PRODUCT_TYPE_ROUTE,
  PRODUCT_FORMULATION_ROUTE,
  PRODUCT_FORMULATION_TYPE_TEXT,
  EXCIPIENT_ROUTE,
  EXCIPIENT_LABEL_TEXT,
  EXCIPIENT_LABEL_ROUTE,
  EXCIPIENT_TYPES_TEXT,
  EXCIPIENT_TYPES_ROUTE,
  BOTTLE_SIZE_ROUTE,
  BOTTLE_SIZE_TEXT,
  BOTTLE_PRICE_TEXT,
  BOTTLE_PRICE_ROUTE,
  BOTTLE_CAPACITY_TEXT,
  BOTTLE_CAPACITY_ROUTE,
  SWEETENER_SYSTEM_TEXT,
  SWEETENER_ROUTE,
  SWEETENER_COMPONENT_TEXT,
  SWEETENER_COMPONENT_ROUTE,
  EXCIPIENT_AND_CARRIER_TEXT,
  PRODUCT_INGREDIENT_TEXT,
  PRODUCT_INGREDIENT_ROUTE,
  FORMULATION_SYRUP_TYPE_TEXT,
  PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE,
  REPORT_USERS_ROUTE,
  REPORT_DOSAGE_FORM_ROUTE,
  REPORT_INGREDIENTS_ROUTE,
  REPORT_PLATFORM_ROUTE,
  DOSAGE_FORM_TEXT,
  PLATFORM_TEXT,
  REPORTS_TEXT,
  SAND_COATING_TEXT,
  SAND_COATING_ROUTE,
  SAND_COATING_FORMULATION_TEXT,
  SAND_COATING_FORMULATION_ROUTE,
  SAND_COMPONENT_TEXT,
  SAND_COMPONENT_ROUTE,
} from 'constants/index';
import { SubSidebarListType } from '../interfaces';

// super admin sidebar
export const SUPER_ADMIN_SIDEBAR_LIST = [
  {
    id: 1,
    heading: GENERAL_TEXT,
    children: <SuperAdminGeneralList />,
  },
  {
    id: 2,
    heading: MANAGEMENT_TEXT,
    children: <SuperAdminManagementList />,
  },
  {
    id: 3,
    heading: REPORTS_TEXT,
    children: <ReportingList />,
  },
];

export const SUPER_ADMIN_MANAGEMENT_LIST = [
  {
    icon: IngredientsIcon,
    text: INGREDIENTS_TEXT,
    route: INGREDIENTS_ROUTE,
  },
];

export const SUPER_ADMIN_PRODUCT_LIST = [
  {
    text: PRODUCT_TYPE_TEXT,
    route: PRODUCT_TYPE_ROUTE,
  },
  {
    text: SUB_PRODUCT_TYPE_TEXT,
    route: SUB_PRODUCT_TYPE_ROUTE,
  },
  {
    text: PRODUCT_SIZE_TEXT,
    route: PRODUCT_SIZE_ROUTE,
  },
  {
    text: PRODUCT_FORMULATION_TYPE_TEXT,
    route: PRODUCT_FORMULATION_ROUTE,
  },
];

export const SUPER_ADMIN_EXCIPIENT_LIST = [
  {
    text: EXCIPIENT_AND_CARRIER_TEXT,
    route: EXCIPIENT_ROUTE,
  },
  {
    text: EXCIPIENT_LABEL_TEXT,
    route: EXCIPIENT_LABEL_ROUTE,
  },
  {
    text: EXCIPIENT_TYPES_TEXT,
    route: EXCIPIENT_TYPES_ROUTE,
  },
];

export const SUPER_ADMIN_BOTTLE_LIST = [
  {
    text: BOTTLE_SIZE_TEXT,
    route: BOTTLE_SIZE_ROUTE,
  },
  {
    text: BOTTLE_PRICE_TEXT,
    route: BOTTLE_PRICE_ROUTE,
  },
  {
    text: BOTTLE_CAPACITY_TEXT,
    route: BOTTLE_CAPACITY_ROUTE,
  },
];

export const SUPER_ADMIN_SWEETENER_LIST = [
  {
    text: SWEETENER_COMPONENT_TEXT,
    route: SWEETENER_COMPONENT_ROUTE,
  },
  {
    text: SWEETENER_SYSTEM_TEXT,
    route: SWEETENER_ROUTE,
  },
];

export const SUPER_ADMIN_SYRUP_BASE_LIST = [
  {
    text: PRODUCT_INGREDIENT_TEXT,
    route: PRODUCT_INGREDIENT_ROUTE,
  },
  {
    text: FORMULATION_SYRUP_TYPE_TEXT,
    route: PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE,
  },
];

export const SUPER_ADMIN_SAND_COATING_LIST = [
  {
    text: SAND_COATING_TEXT,
    route: SAND_COATING_ROUTE,
  },
  {
    text: SAND_COMPONENT_TEXT,
    route: SAND_COMPONENT_ROUTE,
  },
  {
    text: SAND_COATING_FORMULATION_TEXT,
    route: SAND_COATING_FORMULATION_ROUTE,
  },
];

export const SUPER_ADMIN_GENERAL_LIST = [
  {
    icon: DashboardIcon,
    text: DASHBOARD_TEXT,
    route: DASHBOARD_ROUTE,
  },
  {
    icon: UsersIcon,
    text: USERS_TEXT,
    route: USERS_ROUTE,
  },
  {
    icon: SupplierIcon,
    text: SUPPLIERS_TEXT,
    route: SUPPLIER_ROUTE,
  },
];

export const SUPER_ADMIN_REPORTING_LIST: SubSidebarListType[] = [
  {
    text: USERS_TEXT,
    route: REPORT_USERS_ROUTE,
  },
  {
    text: DOSAGE_FORM_TEXT,
    route: REPORT_DOSAGE_FORM_ROUTE,
  },
  {
    text: INGREDIENTS_TEXT,
    route: REPORT_INGREDIENTS_ROUTE,
  },
  {
    text: PLATFORM_TEXT,
    route: REPORT_PLATFORM_ROUTE,
  },
];

// admin sidebar

export const ADMIN_SIDEBAR_LIST = [
  {
    id: 1,
    heading: GENERAL_TEXT,
    children: <AdminGeneralList />,
  },
  {
    id: 2,
    heading: MANAGEMENT_TEXT,
    children: <AdminManagementList />,
  },
];

export const ADMIN_GENERAL_LIST = [
  {
    icon: DashboardIcon,
    text: DASHBOARD_TEXT,
    route: DASHBOARD_ROUTE,
  },
  {
    icon: FormulaIcon,
    text: FORMULA_TEXT,
    route: FORMULA_ROUTE,
  },
  {
    icon: SupplierIcon,
    text: SUPPLIERS_TEXT,
    route: SUPPLIER_ROUTE,
  },
];

export const ADMIN_MANAGEMENT_LIST = [
  {
    icon: UsersIcon,
    text: STAFF_TEXT,
    route: STAFF_ROUTE,
  },
  {
    icon: IngredientsIcon,
    text: INGREDIENTS_TEXT,
    route: INGREDIENTS_ROUTE,
  },
];

// staff sidebar

export const STAFF_SIDEBAR_LIST = [
  {
    id: 1,
    heading: GENERAL_TEXT,
    children: <StaffGeneralList />,
  },
  {
    id: 2,
    heading: MANAGEMENT_TEXT,
    children: <StaffManagementList />,
  },
];

export const STAFF_GENERAL_LIST = [
  {
    icon: DashboardIcon,
    text: DASHBOARD_TEXT,
    route: DASHBOARD_ROUTE,
  },
  {
    icon: FormulaIcon,
    text: FORMULA_TEXT,
    route: FORMULA_ROUTE,
  },
];

export const STAFF_MANAGEMENT_LIST = [
  {
    icon: IngredientsIcon,
    text: INGREDIENTS_TEXT,
    route: INGREDIENTS_ROUTE,
  },
];
