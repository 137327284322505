import { SelectType } from 'interfaces';
import { UserTabType, UserTypeTab } from 'interfaces/TabTypes';
import { UserStatus, UsersPayload } from 'generated/graphql';
import { PAGE_LIMIT, selectTypeInitialValues } from 'constants/index';

export type State = {
  page: number;
  count: number;
  search: string;
  rowsPerPage: number;
  activeTab: UserTabType;
  data: UsersPayload['data'];
  userType: UserTypeTab;
  organizationId: SelectType;
  fromDate: string;
  toDate: string;
};

export const initialState: State = {
  page: 1,
  count: 0,
  data: [],
  search: '',
  rowsPerPage: PAGE_LIMIT,
  activeTab: UserStatus.Active,
  organizationId: selectTypeInitialValues,
  userType: 'all',
  fromDate: '',
  toDate: '',
};

export enum ActionType {
  SET_PAGE = 'setPage',
  SET_DATA = 'setData',
  SET_COUNT = 'setCount',
  SET_SEARCH = 'setSearch',
  SET_ACTIVE_TAB = 'setActiveTab',
  SET_ROWS_PER_PAGE = 'setRowsPerPage',
  SET_ORGANIZATION_ID = 'setOrganizationId',
  SET_TYPE = 'setType',
  SET_FROM_DATE = 'setFromDate',
  SET_TO_DATE = 'setToDate',
}

export type Action =
  | { type: ActionType.SET_PAGE; page: State['page'] }
  | { type: ActionType.SET_DATA; data: State['data'] }
  | { type: ActionType.SET_COUNT; count: State['count'] }
  | { type: ActionType.SET_SEARCH; search: State['search'] }
  | { type: ActionType.SET_TO_DATE; toDate: State['toDate'] }
  | { type: ActionType.SET_TYPE; userType: State['userType'] }
  | { type: ActionType.SET_FROM_DATE; fromDate: State['fromDate'] }
  | { type: ActionType.SET_ACTIVE_TAB; activeTab: State['activeTab'] }
  | { type: ActionType.SET_ROWS_PER_PAGE; rowsPerPage: State['rowsPerPage'] }
  | { type: ActionType.SET_ORGANIZATION_ID; organizationId: State['organizationId'] };

export const userReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case ActionType.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
      };

    case ActionType.SET_COUNT:
      return {
        ...state,
        count: action.count,
      };

    case ActionType.SET_DATA:
      return {
        ...state,
        data: action.data,
      };

    case ActionType.SET_SEARCH:
      return {
        ...state,
        search: action.search,
      };

    case ActionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };

    case ActionType.SET_ORGANIZATION_ID:
      return {
        ...state,
        organizationId: action.organizationId,
      };

    case ActionType.SET_TYPE:
      return {
        ...state,
        userType: action.userType,
      };

    case ActionType.SET_FROM_DATE:
      return {
        ...state,
        fromDate: action.fromDate,
      };

    case ActionType.SET_TO_DATE:
      return {
        ...state,
        toDate: action.toDate,
      };
  }
};
