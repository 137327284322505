import { FC } from 'react';
import { Box, Grid } from '@mui/material';
// components
import RowView from 'components/common/RowView';
import DataLoader from 'components/common/DataLoader';
// interfaces, constants
import { SandCoatingFormulationCardProps } from 'interfaces';
import {
  SAND_COATING_TEXT,
  WEIGHT_TEXT,
  PRODUCT_SIZE_TEXT,
  UNIT_TEXT,
  PRODUCT_TYPE_TEXT,
  SUB_PRODUCT_TYPE_TEXT,
} from 'constants/index';

const SandCoatingFormulationCard: FC<SandCoatingFormulationCardProps> = ({
  sandCoatingFormulation,
  loading,
}) => {
  const { productSize, sandCoating, weight, unit, productType, subProductType } =
    sandCoatingFormulation || {};
  const { name: coatingName } = sandCoating || {};
  const { name: productSizeName } = productSize || {};
  const { name: productTypeName } = productType || {};
  const { name: subProductTypeName } = subProductType || {};

  return (
    <Box m={4}>
      {loading ? (
        <DataLoader columns={6} rows={3} />
      ) : (
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={6}>
            <RowView name={SAND_COATING_TEXT} value={coatingName || '-'} isInfo={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <RowView name={PRODUCT_TYPE_TEXT} value={productTypeName || '-'} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView name={SUB_PRODUCT_TYPE_TEXT} value={subProductTypeName || '-'} isInfo={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <RowView name={PRODUCT_SIZE_TEXT} value={productSizeName || '-'} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView name={WEIGHT_TEXT} value={`${weight || ''}`} isInfo={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <RowView name={UNIT_TEXT} value={unit || ''} isInfo={false} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SandCoatingFormulationCard;
