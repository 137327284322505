import { TableCell, TableRow, Box } from '@mui/material';
import { FC, Fragment, useCallback, useContext, useEffect } from 'react';
// components
import TableLoader from 'components/common/TableLoader';
import NoDataFound from 'components/common/NoDataFound';
import TableComponent from 'components/common/TableComponent';
import TableContainer from 'components/common/TableContainer';
import TableTabsComponent from 'components/common/TableTabsComponent';
// constants, reducers, graphql, styles, interfaces
import { formatTimeStamp } from 'lib/helper';
import { UserTypeTab } from 'interfaces/TabTypes';
import { ActionType } from 'reducer/userReportReducer';
import { textWhiteSpace } from 'styles/commonComponentStyle';
import { UserReportContext } from 'contexts/UserReportContext';
import { UsersPayload, useFindAllUsersLazyQuery } from 'generated/graphql';
import { USER_REPORT_TABS, HTTP_STATUS, USER_REPORT_TABLE_HEADER, LOADING_TABLE_ROWS } from 'constants/index';

const UsersTable: FC = () => {
  const { state, dispatch } = useContext(UserReportContext);
  const { page, rowsPerPage, search, count, data, organization, userType, fromDate, toDate } = state;
  const { value: organizationId } = organization || {};

  const [findAllUsers, { loading, error }] = useFindAllUsersLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllUsers } = data;
      const { pagination, response, data: usersData } = findAllUsers || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { page, totalCount } = pagination || {};
        dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
        dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
        dispatch({ type: ActionType.SET_DATA, data: usersData as UsersPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchUsers = useCallback(async () => {
    await findAllUsers({
      variables: {
        findAllUsersInput: {
          paginationOptions: {
            limit: rowsPerPage,
            page,
          },
          search,
          ...(userType !== 'all' && { type: userType }),
          ...(organizationId && { organizationId }),
          ...(fromDate && { fromDate }),
          ...(toDate && { toDate }),
        },
      },
    });
  }, [findAllUsers, search, rowsPerPage, page, userType, organizationId, fromDate, toDate]);

  useEffect(() => {
    (!search.length || search.length > 2) && fetchUsers();
  }, [fetchUsers, search.length]);

  const tabHandler = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    dispatch({ type: ActionType.SET_TYPE, userType: value as UserTypeTab });
  };

  const noData = Boolean((!loading && data?.length === 0) || error);

  return (
    <TableContainer>
      <TableTabsComponent tabsList={USER_REPORT_TABS} tabHandler={tabHandler} activeTab={userType} />
      <Box my={2} mx={3} />

      <TableComponent
        noData={noData}
        page={page - 1}
        count={count}
        rowsPerPage={rowsPerPage}
        tableHeader={USER_REPORT_TABLE_HEADER}
        setPage={(p: number) => dispatch({ type: ActionType.SET_PAGE, page: p + 1 })}
        setRowsPerPage={(r: number) => dispatch({ type: ActionType.SET_ROWS_PER_PAGE, rowsPerPage: r })}>
        {loading ? (
          <TableLoader columns={5} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const { id, email, organization, firstName, lastName, createdAt } = cell || {};
              const { name } = organization || {};

              return (
                <TableRow key={id}>
                  <TableCell>
                    {firstName ?? ''} {lastName ?? '--'}
                  </TableCell>
                  <TableCell sx={textWhiteSpace}>{name ?? '--'}</TableCell>
                  <TableCell>{email ?? '--'}</TableCell>

                  <TableCell>{formatTimeStamp(createdAt, 'MM/DD/YY')}</TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default UsersTable;
