import { Grid } from '@mui/material';
import { FC, Fragment } from 'react';
// components
import StatsCard from '../components/StatsCard';
import DataLoader from 'components/common/DataLoader';
// constants
import { FormulaCountsProps } from 'interfaces';
import { FORMULAS_TEXT, TOTAL_TEXT } from 'constants/constant';

const FormulaCounts: FC<FormulaCountsProps> = ({ data, loading }) => {
  return (
    <Fragment>
      {loading ? (
        <DataLoader columns={12} rows={4} />
      ) : (
        <Grid container spacing={2}>
          {data?.map((item) => {
            const { count, name } = item || {};
            return (
              <Grid item xs={6} key={`${name}-${count}`}>
                <StatsCard
                  loading={false}
                  stats={`${count || 0}`}
                  title={`${TOTAL_TEXT} ${name || ''} ${FORMULAS_TEXT}`}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Fragment>
  );
};

export default FormulaCounts;
