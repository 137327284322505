import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import SandComponentForm from './FormCard';
import { Alert } from 'components/common/Alert';
import DataLoader from 'components/common/DataLoader';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { sandComponentSchema } from 'validationSchema';
import { ParamType, SandComponentFormType } from 'interfaces';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  useCreateSandComponentMutation,
  useUpdateSandComponentMutation,
  useGetSandComponentLazyQuery,
  SandComponentPayload,
} from 'generated/graphql';
import { ADD_TEXT, HTTP_STATUS, SAND_COMPONENT_TEXT, sandComponentInitialValue } from 'constants/index';
import { useAddTitleText, useUpdateTitleText, useAddLoadingText, useUpdateLoadingText } from '../../../hooks';

const Edit: FC = (): JSX.Element => {
  const addText = useAddTitleText(SAND_COMPONENT_TEXT);
  const updateText = useUpdateTitleText(SAND_COMPONENT_TEXT);
  const creatingText = useAddLoadingText(SAND_COMPONENT_TEXT);
  const updatingText = useUpdateLoadingText(SAND_COMPONENT_TEXT);

  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const methods = useForm<SandComponentFormType>({
    defaultValues: sandComponentInitialValue,
    resolver: yupResolver(sandComponentSchema),
  });

  const { handleSubmit, setValue } = methods;

  const [createSandComponent, { loading: createLoading }] = useCreateSandComponentMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createSandComponent } = data;
      const { response } = createSandComponent || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updateSandComponent, { loading: updateLoading }] = useUpdateSandComponentMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateSandComponent } = data;
      const { response } = updateSandComponent || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getSandComponent, { loading: getLoading }] = useGetSandComponentLazyQuery({
    onCompleted: (data) => {
      const { getSandComponent } = data || {};
      const { response, sandComponent } = getSandComponent || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(sandComponent as SandComponentPayload['sandComponent']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onSubmit: SubmitHandler<SandComponentFormType> = async (data) => {
    const { name } = data || {};

    if (id) {
      await updateSandComponent({
        variables: {
          updateSandComponentInput: {
            id: id || '',
            name,
          },
        },
      });
    } else {
      await createSandComponent({
        variables: {
          createSandComponentInput: {
            name,
          },
        },
      });
    }
  };

  const fetchSandComponent = useCallback(async () => {
    id &&
      (await getSandComponent({
        variables: {
          getSandComponentInput: {
            id,
          },
        },
      }));
  }, [id, getSandComponent]);

  const setValues = (params: SandComponentPayload['sandComponent']) => {
    const { name } = params || {};

    setValue('name', name || '');
  };

  useEffect(() => {
    id && fetchSandComponent();
  }, [id, fetchSandComponent]);

  const loading = updateLoading || createLoading;

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{id ? updateText : addText}</Typography>
            {!id && (
              <Button variant="contained" type="submit">
                {ADD_TEXT}
              </Button>
            )}
          </Box>

          {getLoading ? (
            <DataLoader columns={12} rows={6} />
          ) : (
            <SandComponentForm loading={loading} isEdit={!!id} />
          )}
        </form>
      </FormProvider>

      <BackdropLoader open={loading} text={id ? updatingText : creatingText} />
    </Fragment>
  );
};

export default Edit;
