import { FC } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
// interfaces
import { LegendLabelProps } from 'interfaces/PropsTypes';

const LegendLabel: FC<LegendLabelProps> = ({ label, bgColor, icon, subLabel }) => {
  const title = (
    <Box display="flex" alignItems="center" gap={1}>
      {icon ? (
        <Avatar sx={{ backgroundColor: bgColor }}>{icon}</Avatar>
      ) : (
        <Box bgcolor={bgColor} sx={{ width: 12, height: 12, borderRadius: '100%' }} />
      )}
      <Typography variant="body1">{label}</Typography>
    </Box>
  );

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
      {title}
      <Typography variant="body1" color="text.secondary">
        {subLabel}
      </Typography>
    </Box>
  );
};

export default LegendLabel;
