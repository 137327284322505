import { FC } from 'react';
// components
import UserListing from 'components/main/Reports/Users';
// contexts
import { UserReportContextProvider } from 'contexts/UserReportContext';

const User: FC = () => (
  <UserReportContextProvider>
    <UserListing />
  </UserReportContextProvider>
);

export default User;
