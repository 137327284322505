import { createContext, FC, Reducer, useReducer } from 'react';
// interfaces, reducer
import { ChildrenType, DosageReportContextType } from 'interfaces';
import { initialState, dosageReportReducer, Action, State } from 'reducer/dosageReportReducer';

export const DosageReportContext = createContext<DosageReportContextType>({
  state: initialState,
  dispatch: () => {},
});

export const DosageReportContextProvider: FC<ChildrenType> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(dosageReportReducer, initialState);

  return (
    <DosageReportContext.Provider
      value={{
        state,
        dispatch,
      }}>
      {children}
    </DosageReportContext.Provider>
  );
};
