import { Grid } from '@mui/material';
import { FC, useState } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker as MuiDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// components
import DateField from '../DateField';
// interfaces
import { DateRangePickerProps } from 'interfaces';

const DateRangePicker: FC<DateRangePickerProps> = ({
  fromLabel = 'From',
  toLabel = 'To',
  fromDate,
  toDate,
  fromDateChange,
  toDateChange,
}) => {
  const [open, setOpen] = useState(false);
  const [toOpen, setToOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MuiDatePicker
            value={fromDate ? fromDate : null}
            open={open}
            onClose={() => setOpen(false)}
            label={fromLabel}
            inputFormat="MM/DD/YYYY"
            disableFuture
            onChange={(value) => fromDateChange(value)}
            renderInput={(params) => (
              <DateField {...params} onDateChange={fromDateChange} setOpen={setOpen} open={open} />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiDatePicker
            open={toOpen}
            label={toLabel}
            disabled={!fromDate}
            inputFormat="MM/DD/YYYY"
            onClose={() => setToOpen(false)}
            value={toDate ? toDate : null}
            maxDate={new Date().toISOString()}
            onChange={(value) => toDateChange(value)}
            minDate={fromDate ? new Date(fromDate).toISOString() : undefined}
            renderInput={(params) => (
              <DateField {...params} onDateChange={toDateChange} setOpen={setToOpen} open={toOpen} />
            )}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default DateRangePicker;
