import { FC, JSX } from 'react';
import { Grid } from '@mui/material';
// components
import SandComponentForm from './Form';
// interfaces
import { SandCoatingFormProps } from 'interfaces';

const AddSandComponentForm: FC<SandCoatingFormProps> = ({ loading, isEdit = false }): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={12}>
        <SandComponentForm loading={loading} isEdit={isEdit} />
      </Grid>
    </Grid>
  );
};

export default AddSandComponentForm;
