// imports
import { CloseRounded, KeyboardArrowDownRounded } from '@mui/icons-material';
import { FC, Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Chip, TextField, Typography, Autocomplete, CircularProgress } from '@mui/material';
// interfaces, constants, helper
import { InfiniteSelectorProps } from 'interfaces';
import { useDebounce } from 'hooks/useDebounce';
import { ACTIVE_TEXT, INACTIVE_TEXT, selectTypeInitialValues } from 'constants/index';

const InfiniteSelector: FC<InfiniteSelectorProps> = ({
  name,
  title,
  handleChange,
  loading = false,
  getLoading = false,
  margin = 'dense',
  isClearable = false,
  value,
  options,
  fetchData,
  setOptions,
  totalPages,
}) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const debounceValue = useDebounce(searchQuery);

  const lastElementRef = (node: HTMLLIElement) => {
    if (loading) return;
    if (observer?.current) observer?.current?.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      // fetch the coming suppliers if the last node and page is less than or equal to the total page.
      if (entries[0].isIntersecting && page < totalPages) {
        setPage((prev) => {
          const newPage = prev + 1;
          fetchData(newPage, searchQuery);
          return newPage;
        });
      }
    });
    if (node) observer.current.observe(node);
  };

  const onSearch = useCallback(
    async (val: string) => {
      try {
        setPage(1);
        setOptions([]);
        await fetchData(1, val);
      } catch (error) {
        console.log('error', error);
      }
    },
    [fetchData, setOptions, setPage],
  );

  useEffect(() => {
    onSearch(debounceValue);
  }, [onSearch, debounceValue]);

  const onClose = () => {
    setPage(1);
    setOptions([]);
    setSearchQuery('');
    fetchData(1);
  };

  return (
    <Autocomplete
      id={name}
      multiple={false}
      disableClearable={!isClearable}
      options={getLoading ? [] : options}
      onClose={onClose}
      loading={loading}
      value={value}
      onChange={(_, value) => {
        const selectedValue = value ?? selectTypeInitialValues;
        handleChange(selectedValue);
      }}
      clearIcon={<CloseRounded />}
      popupIcon={<KeyboardArrowDownRounded />}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={({ value: option }, { value }) => value === option}
      renderOption={(props, option) => {
        const { name, isActive, value: val, description } = option || {};
        return (
          <Box component="li" sx={{ '& > div': { width: '100%' } }} key={val} ref={lastElementRef} {...props}>
            <Box display={'flex'} flexDirection={'column'}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant="caption">{name || '--'}</Typography>
                {typeof isActive === 'boolean' && (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={isActive ? ACTIVE_TEXT : INACTIVE_TEXT}
                    color={isActive ? 'success' : 'error'}
                  />
                )}
              </Box>
              {description && (
                <Typography color="primary" fontStyle={'italic'}>
                  {description}
                </Typography>
              )}
            </Box>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          margin={margin}
          variant="outlined"
          label={title}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default memo(InfiniteSelector);
