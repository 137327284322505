import { Card, CardHeader, CardContent } from '@mui/material';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
// components
import DataLoader from 'components/common/DataLoader';
import StackBarChart from '../components/Charts/StackBarChart';
// constants, interfaces
import { StackBarCardProps } from 'interfaces';
import { DosageReportContext } from 'contexts/DosageReportContext';
import { FORMULATION_TYPES_TEXT, HTTP_STATUS } from 'constants/index';
import { useFindAllDosageFormulasLazyQuery, DosageFormulasPayload } from 'generated/graphql';

const StackBarCard = forwardRef<ChartJSOrUndefined<'bar'>, StackBarCardProps>((_, ref) => {
  const [data, setData] = useState<DosageFormulasPayload['data']>([]);

  const { state } = useContext(DosageReportContext);
  const { organization, toDate, fromDate } = state;
  const { value: organizationId } = organization || {};

  const [findDosageFormula, { loading }] = useFindAllDosageFormulasLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllDosageFormulas } = data;
      const { response, data: formulas } = findAllDosageFormulas || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setData(formulas as DosageFormulasPayload['data']);
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const { dataSet, labels } = useMemo(() => {
    if (data?.length) {
      const labels = data?.map((item) => {
        const { name } = item || {};
        return name || '';
      });

      const dataSet = data?.map((item) => {
        const { count } = item || {};
        return count || 0;
      });

      return { labels, dataSet };
    }
    return {
      labels: [],
      dataSet: [],
    };
  }, [data]);

  const resetPage = () => {
    setData([]);
  };

  const fetchFormulas = useCallback(async () => {
    await findDosageFormula({
      variables: {
        findAllDosageFormulasInputs: {
          ...(toDate && { toDate }),
          ...(fromDate && { fromDate }),
          ...(organizationId && { organizationId }),
        },
      },
    });
  }, [findDosageFormula, fromDate, organizationId, toDate]);

  useEffect(() => {
    fetchFormulas();
  }, [fetchFormulas]);

  return (
    <Card>
      <CardHeader title={FORMULATION_TYPES_TEXT} />
      <CardContent>
        {loading ? (
          <DataLoader columns={12} rows={1} height={400} />
        ) : (
          <StackBarChart dataSet={dataSet} labels={labels} ref={ref} />
        )}
      </CardContent>
    </Card>
  );
});

export default StackBarCard;
