import { PlatformUsersCount } from 'generated/graphql';

type DataType = {
  feature: string;
  enhancement: string;
};

const initialData: DataType = {
  feature: '100',
  enhancement: '100',
};

export type State = {
  pieCanvas: string;
  lineCanvas: string;

  year: string;
  open: boolean;
  data: DataType;
  openDialog: boolean;
  platformUsers: PlatformUsersCount;
};

export const initialState: State = {
  pieCanvas: '',
  lineCanvas: '',

  open: false,
  openDialog: false,
  data: initialData,
  year: `${new Date().getFullYear()}`,

  platformUsers: {
    ios: 0,
    web: 0,
    android: 0,
  },
};

export enum ActionType {
  SET_OPEN = 'setOpen',
  SET_YEAR = 'setYear',
  SET_DATA = 'setData',
  SET_PLATFORM_USER = 'setPlatformUser',
  SET_PIE_CANVAS = 'setPieCanvas',
  SET_LINE_CANVAS = 'setLineCanvas',
  SET_OPEN_DIALOG = 'setOpenDialog',
}

export type Action =
  | { type: ActionType.SET_OPEN; open: State['open'] }
  | { type: ActionType.SET_YEAR; year: State['year'] }
  | { type: ActionType.SET_DATA; data: State['data'] }
  | { type: ActionType.SET_PIE_CANVAS; pieCanvas: State['pieCanvas'] }
  | { type: ActionType.SET_OPEN_DIALOG; openDialog: State['openDialog'] }
  | { type: ActionType.SET_PLATFORM_USER; platformUsers: State['platformUsers'] }
  | { type: ActionType.SET_LINE_CANVAS; lineCanvas: State['lineCanvas'] };

export const platformReportReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_OPEN:
      return {
        ...state,
        open: action.open,
      };

    case ActionType.SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case ActionType.SET_PIE_CANVAS:
      return {
        ...state,
        pieCanvas: action.pieCanvas,
      };

    case ActionType.SET_LINE_CANVAS:
      return {
        ...state,
        lineCanvas: action.lineCanvas,
      };

    case ActionType.SET_YEAR:
      return {
        ...state,
        year: action.year,
      };

    case ActionType.SET_OPEN_DIALOG:
      return {
        ...state,
        openDialog: action.openDialog,
      };

    case ActionType.SET_PLATFORM_USER:
      return {
        ...state,
        platformUsers: action.platformUsers,
      };
  }
};
