import { FC } from 'react';
import { View, Text } from '@react-pdf/renderer';
// styles
import { pdfStyles } from 'styles/PdfStyles';
// interfaces
import { PdfTableHeaderProps } from 'interfaces';

const PdfTableHeader: FC<PdfTableHeaderProps> = ({ headers }) => {
  return (
    <View style={pdfStyles.tr}>
      {headers?.map((header, index) => {
        const isLast = index === headers.length - 1;
        return (
          <View style={isLast ? pdfStyles.thLast : pdfStyles.th}>
            <Text style={pdfStyles.thText}>{header}</Text>
          </View>
        );
      })}
    </View>
  );
};

export default PdfTableHeader;
