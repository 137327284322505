import { useFormContext } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
// components
import { Alert } from 'components/common/Alert';
import PackageNCost from '../Add/components/PackageNCost';
import FactSheetModal from '../Add/components/FactSheetModal';
import IngredientsList from '../Add/components/IngredientsList';
import FormulaInfoCard from '../Add/components/FormulaInfoCard';
//  styles, constant, interfaces, context, helper
import {
  ProductTypes,
  BottleCapType,
  CalcFormulaCostPayload,
  useCalcFormulaCostLazyQuery,
} from 'generated/graphql';
import { ActionType } from 'reducer/addFormulaReducer';
import { FormulaContext } from 'contexts/FormulaContext';
import { formatCalcFormulaIngredients } from 'lib/helper';
import { forInputLabelBg } from 'styles/commonComponentStyle';
import { AddNewFormulaFormType, BlendItemType, CalcFormulaCost } from 'interfaces';
import { HTTP_STATUS, INGREDIENTS_VALUE_ERROR_MESSAGE_TEXT } from 'constants/index';

const Step2: FC = () => {
  const { state, dispatch } = useContext(FormulaContext);
  const { watch, trigger } = useFormContext<AddNewFormulaFormType>();
  const { formulaIngredients } = watch();
  // local states
  const [open, setOpen] = useState(false);
  const [formulaCost, setFormulaCost] = useState<CalcFormulaCost>(null);
  const isValid = formulaIngredients.every((item) => item?.value && item?.type);

  const { productType, subProductType, productFormulationType, formulation, ingredientsBlend } = state || {};

  const {
    bottleCap,

    factBoxType,

    excipientType,

    flavorSystem,
    sweetenerSystem,

    capsuleType,
    innerCapsuleType,
    outerCapsuleType,

    servingUnit,
    desiredServingSize,
    servingPerContainer,

    sandCoating,
    tabletCoating,

    productSubType,

    productSize: productSizeType,
  } = formulation || {};

  const { value: productSizeId } = productSizeType || {};
  const { value: excipientTypeId } = excipientType || {};

  const { value: sweetenerSystemId } = sweetenerSystem || {};
  const { value: flavorSystemId } = flavorSystem || {};

  const { value: productTypeId, type } = productType || {};
  const { value: subProductTypeId } = subProductType || {};
  const { value: productFormulationTypeId } = productFormulationType || {};

  const [getFormulaCost, { loading: getFormulaCostLoading }] = useCalcFormulaCostLazyQuery({
    onCompleted: (res) => {
      const { calcFormulaCost } = res || {};
      const { formulaCostSuggestion, response } = calcFormulaCost || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setFormulaCost(formulaCostSuggestion as CalcFormulaCostPayload['formulaCostSuggestion']);
      } else {
        setFormulaCost(null);
      }
    },
    onError: ({ message }) => {
      setFormulaCost(null);
      Alert.error(message);
    },
  });

  const fetchFormulaCost = useCallback(async () => {
    const ingredients = formatCalcFormulaIngredients(formulaIngredients);
    const ingredientBlends = ingredientsBlend?.map(({ ingredients, name: blendName }) => {
      return {
        name: blendName,
        formulaIngredients: formatCalcFormulaIngredients(ingredients),
      };
    });

    const Valid = await trigger('formulaIngredients');
    if (!Valid) {
      Alert.error(INGREDIENTS_VALUE_ERROR_MESSAGE_TEXT);
      return;
    }
    if (formulaIngredients.length || ingredientsBlend.length) {
      await getFormulaCost({
        variables: {
          calcFormulaCostInput: {
            formulaIngredients: ingredients,
            formulaBlends: ingredientBlends,

            bottleCap: bottleCap as BottleCapType,

            servingSize: Number(desiredServingSize),
            servingContainer: Number(servingPerContainer),

            productTypeId: productTypeId || '',
            productFormulationTypeId: productFormulationTypeId || '',
            ...(productSubType && { subProductTypeId: productSubType }),

            ...(excipientTypeId && { excipientTypeId: excipientTypeId }),

            ...(sandCoating && { sandCoatingId: sandCoating }),
            ...(tabletCoating && { tabletCoatingId: tabletCoating }),

            ...(productSizeId && { productSizeId: productSizeId }),

            ...(flavorSystemId && { flavorSystemId: flavorSystemId }),
            ...(sweetenerSystemId && { sweetenerSystemId: sweetenerSystemId }),

            ...(capsuleType && { capsuleTypeId: capsuleType }),
            ...(innerCapsuleType && { innerCapsuleTypeId: innerCapsuleType }),
            ...(outerCapsuleType && { outerCapsuleTypeId: outerCapsuleType }),
          },
        },
      });
    } else {
      setFormulaCost(null);
    }
  }, [
    formulaIngredients,
    ingredientsBlend,
    getFormulaCost,
    bottleCap,
    servingPerContainer,
    desiredServingSize,
    productTypeId,
    productFormulationTypeId,
    productSubType,
    excipientTypeId,
    sandCoating,
    tabletCoating,
    productSizeId,
    flavorSystemId,
    sweetenerSystemId,
    capsuleType,
    innerCapsuleType,
    outerCapsuleType,
    trigger,
  ]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isValid) {
      timeoutId = setTimeout(() => {
        fetchFormulaCost();
      }, 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [fetchFormulaCost, isValid]);

  const handleFactSheet = async () => {
    const isValid = await trigger('formulaIngredients');
    if (isValid) {
      setOpen(!open);
    } else {
      Alert.error(INGREDIENTS_VALUE_ERROR_MESSAGE_TEXT);
    }
  };

  const loading = getFormulaCostLoading;

  const onBlendAdd = (data: BlendItemType[]) => {
    dispatch({ type: ActionType.SET_INGREDIENTS_BLEND, ingredientsBlend: data });
  };

  const calculateCost = async () => {
    const isValid = await trigger('formulaIngredients');
    if (isValid) {
      await fetchFormulaCost();
    } else {
      Alert.error(INGREDIENTS_VALUE_ERROR_MESSAGE_TEXT);
    }
  };

  return (
    <Fragment>
      <Grid container sx={forInputLabelBg} spacing={2}>
        <Grid item xs={12} xl={8}>
          <Box mb={2}>
            <FormulaInfoCard />
          </Box>

          <IngredientsList
            onBlendAdd={onBlendAdd}
            handleFactSheet={handleFactSheet}
            productType={type as ProductTypes}
            ingredientsBlend={ingredientsBlend}
            formulaIngredients={formulaIngredients}
            productFormulationType={productFormulationType}
          />
        </Grid>

        <Grid item xs={12} xl={4}>
          <PackageNCost
            loading={loading}
            formulaCost={formulaCost}
            calculateCost={calculateCost}
            bottleCap={bottleCap}
            productType={productType}
            subProductType={subProductType}
            productFormulationType={productFormulationType}
            formulaIngredients={formulaIngredients}
          />
        </Grid>
      </Grid>

      <FactSheetModal
        open={open}
        servingUnit={servingUnit}
        factBoxType={factBoxType}
        productSizeId={productSizeId}
        sandCoatingId={sandCoating}
        capsuleTypeId={capsuleType}
        tabletCoating={tabletCoating}
        productTypeId={productTypeId}
        onClose={() => setOpen(!open)}
        excipientTypeId={excipientTypeId}
        subProductTypeId={subProductTypeId}
        ingredientsBlend={ingredientsBlend}
        sweetenerSystemId={sweetenerSystemId}
        innerCapsuleTypeId={innerCapsuleType}
        outerCapsuleTypeId={outerCapsuleType}
        formulaIngredients={formulaIngredients}
        desiredServingSize={desiredServingSize}
        servingPerContainer={servingPerContainer}
        productFormulationTypeId={productFormulationTypeId}
      />
    </Fragment>
  );
};

export default Step2;
