import { forwardRef, memo } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
// components
import DataLoader from 'components/common/DataLoader';
import BarHorizontal from '../components/Charts/BarHorizontal';
// constants, contexts, generated
import { DosageBarCardProps } from 'interfaces';
import { DOSAGE_FORM_TEXT, NUMBERS_TEXT } from 'constants/index';

const DosageBarCard = forwardRef<ChartJSOrUndefined<'bar'>, DosageBarCardProps>(
  ({ dataSet, labels, loading = false }, ref) => {
    return (
      <Card>
        <CardHeader title={`${DOSAGE_FORM_TEXT} (${NUMBERS_TEXT})`} />
        <CardContent>
          {loading ? (
            <DataLoader columns={12} rows={5} />
          ) : (
            <BarHorizontal dataSet={dataSet} labels={labels} ref={ref} />
          )}
        </CardContent>
      </Card>
    );
  },
);

export default memo(DosageBarCard);
