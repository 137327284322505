import { useForm } from 'react-hook-form';
import { FC, useCallback, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// component
import DuplicateIngredientForm from './DuplicateIngredientForm';
// interfaces, graphql, helper, reducer, context, schema
import {
  convertHtmlToEditorState,
  formatIngredientCarriers,
  formatIngredientSubComponents,
} from 'lib/helper';
import { addNewIngredientSchema } from 'validationSchema';
import { addNewIngredientInitialValues } from 'constants/index';
import { IngredientType, SupplierType } from 'generated/graphql';
import {
  AddNewIngredientFormType,
  CarrierSelectType,
  EditIngredientFormProps,
  SelectType,
  SubComponentSelectType,
} from 'interfaces';

const SetDuplicateFormulaFormValues: FC<EditIngredientFormProps> = ({
  ingredient,
  fetchIngredient,
  loading: getLoading,
}) => {
  const methods = useForm<AddNewIngredientFormType>({
    defaultValues: addNewIngredientInitialValues,
    resolver: yupResolver(addNewIngredientSchema),
  });

  const { setValue } = methods;

  const setValues = useCallback(() => {
    const {
      ingredientInfo,
      ingredientAllergens,
      name,
      type,
      amount,
      cost,
      potency,
      category,
      latinName,
      shelfLife,
      subCategory,
      gummyOverage,
      tradeMarkName,
      scientificName,
      suggestedOverage,
      ingredientSuppliers,
      avergageParticleSize,
      manufacturingCountry,
      originCountry,
      tappedDensity,
      lossOnDrying,
      bulkDensity,
      nutrients,
      ingredientProductTypes,
      showB3,
      nutrientName,
      ingredientCarriers,
      ingredientSubComponents,
    } = ingredient || {};
    const allergensList = ingredientAllergens?.map((item) => item?.allergen?.id || '') || [];
    const carriersList = formatIngredientCarriers(ingredientCarriers ?? []);
    const subComponentList = formatIngredientSubComponents(ingredientSubComponents ?? []);

    const {
      addedSugar,
      arsenic,
      biotin,
      boron,
      cadmium,
      calcium,
      calories,
      carbohydrates,
      cholesterol,
      choline,
      dietaryFiber,
      folate,
      iodine,
      iron,
      lead,
      magnesium,
      mercury,
      moisture,
      monounsaturatedFat,
      niacin,
      organic,
      polyunsaturatedFat,
      potassium,
      protein,
      riboflavin,
      saturatedFat,
      sodium,
      thiamin,
      totalFat,
      totalSugars,
      transFat,
      vitaminA,
      vitaminB12,
      vitaminB6,
      vitaminC,
      vitaminD,
      vitaminE,
      vitaminK2,
      zinc,
      chloride,
      copper,
      chromium,
      manganese,
      molybdenum,
      pantothenicAcid,
      phosphorus,
      selenium,
    } = ingredientInfo || {};

    // setting allergens values
    ingredientAllergens && setValue('allergens', allergensList);
    setValue('name', name || '');
    // setValue('latinName', latinName || '');
    setValue('amount', `${amount}`);
    const { id, name: categoryName } = category || {};
    const { id: subCategoryId, name: subCategoryName } = subCategory || {};

    const categoryObj: SelectType = {
      value: id ?? '',
      name: categoryName ?? '',
    };

    const subCategoryObj: SelectType = {
      value: subCategoryId ?? '',
      name: subCategoryName ?? '',
    };

    const primarySupplier = ingredientSuppliers?.find((item) => item?.type === SupplierType.Primary);
    const secondarySupplier = ingredientSuppliers?.find((item) => item?.type === SupplierType.Secondary);
    const tertiarySupplier = ingredientSuppliers?.find((item) => item?.type === SupplierType.Tertiary);

    const IngredientProductType = Array.from(
      new Set(ingredientProductTypes?.map((item) => item?.productTypeId)),
    );
    const labelName = convertHtmlToEditorState(latinName ?? '');

    // setting basic info card values
    setValue('name', name ?? '');
    setValue('latinName', labelName);
    setValue('category', categoryObj);
    setValue('subCategory', subCategoryObj);
    setValue('tradeMarkName', tradeMarkName ?? '');
    setValue('scientificName', scientificName ?? '');
    setValue('type', (type as IngredientType[]) ?? []);
    setValue('productTypes', IngredientProductType as string[]);

    // setting cost info card values

    const potencyInPercentage = Number(potency) * 100;
    setValue('cost', `${cost ?? ''}`);
    setValue('nutrients', nutrients ?? false);
    setValue('showB3', showB3 ?? false);
    setValue('nutrientName', nutrientName ?? false);
    setValue('potency', `${potencyInPercentage}`);
    setValue('amount', `${amount ?? ''}`);
    setValue('shelfLife', `${shelfLife ?? ''}`);
    setValue('gummyOverage', `${gummyOverage ?? ''}`);
    setValue('suggestedOverage', `${suggestedOverage ?? ''}`);

    // setting supplier info card values

    if (primarySupplier) {
      const { supplier } = primarySupplier || {};
      const { id, name, description } = supplier || {};

      setValue('primarySupplier', {
        value: id ?? '',
        name: description ? `${name ?? ''} : ${description}` : name ?? '',
        str: id ?? '',
      });
    }
    if (secondarySupplier) {
      const { supplier } = secondarySupplier || {};
      const { id, name, description } = supplier || {};

      setValue('secondarySupplier', {
        value: id ?? '',
        name: description ? `${name ?? ''} : ${description}` : name ?? '',
        str: id ?? '',
      });
    }

    if (tertiarySupplier) {
      const { supplier } = tertiarySupplier || {};
      const { id, name, description } = supplier || {};

      setValue('tertiarySupplier', {
        value: id ?? '',
        name: description ? `${name ?? ''} : ${description}` : name ?? '',
        str: id ?? '',
      });
    }

    // setting country info card values
    setValue('manufacturingCountry', {
      name: manufacturingCountry ?? '',
      value: manufacturingCountry ?? '',
    });
    setValue('originCountry', { name: originCountry ?? '', value: originCountry ?? '' });

    // setting country info card values
    setValue('averageParticleSize', avergageParticleSize ?? '');
    setValue('bulkDensity', bulkDensity ?? '');
    setValue('lossOnDrying', lossOnDrying ?? '');
    setValue('tappedDensity', tappedDensity ?? '');

    // setting nutrients values
    lead && setValue('lead', `${lead}`);
    arsenic && setValue('arsenic', `${arsenic}`);
    mercury && setValue('mercury', `${mercury}`);
    cadmium && setValue('cadmium', `${cadmium}`);
    addedSugar && setValue('ingredientInfo.addedSugar', `${addedSugar}`);
    organic && setValue('ingredientInfo.organic', `${organic}`);
    biotin && setValue('ingredientInfo.biotin', `${biotin}`);
    boron && setValue('ingredientInfo.boron', `${boron}`);
    calcium && setValue('ingredientInfo.calcium', `${calcium}`);
    cholesterol && setValue('ingredientInfo.cholesterol', `${cholesterol}`);
    carbohydrates && setValue('ingredientInfo.carbohydrates', `${carbohydrates}`);
    choline && setValue('ingredientInfo.choline', `${choline}`);
    dietaryFiber && setValue('ingredientInfo.dietaryFiber', `${dietaryFiber}`);
    folate && setValue('ingredientInfo.folate', `${folate}`);
    iodine && setValue('ingredientInfo.iodine', `${iodine}`);
    iron && setValue('ingredientInfo.iron', `${iron}`);
    magnesium && setValue('ingredientInfo.magnesium', `${magnesium}`);
    moisture && setValue('ingredientInfo.moisture', `${moisture}`);
    monounsaturatedFat && setValue('ingredientInfo.monounsaturatedFat', `${monounsaturatedFat}`);
    niacin && setValue('ingredientInfo.niacin', `${niacin}`);
    polyunsaturatedFat && setValue('ingredientInfo.polyunsaturatedFat', `${polyunsaturatedFat}`);
    potassium && setValue('ingredientInfo.potassium', `${potassium}`);
    protein && setValue('ingredientInfo.protein', `${protein}`);
    riboflavin && setValue('ingredientInfo.riboflavin', `${riboflavin}`);
    saturatedFat && setValue('ingredientInfo.saturatedFat', `${saturatedFat}`);
    sodium && setValue('ingredientInfo.sodium', `${sodium}`);
    calories && setValue('ingredientInfo.calories', `${calories}`);
    thiamin && setValue('ingredientInfo.thiamin', `${thiamin}`);
    totalFat && setValue('ingredientInfo.totalFat', `${totalFat}`);
    totalSugars && setValue('ingredientInfo.totalSugars', `${totalSugars}`);
    transFat && setValue('ingredientInfo.transFat', `${transFat}`);
    vitaminA && setValue('ingredientInfo.vitaminA', `${vitaminA}`);
    vitaminB12 && setValue('ingredientInfo.vitaminB12', `${vitaminB12}`);
    vitaminB6 && setValue('ingredientInfo.vitaminB6', `${vitaminB6}`);
    vitaminC && setValue('ingredientInfo.vitaminC', `${vitaminC}`);
    vitaminD && setValue('ingredientInfo.vitaminD', `${vitaminD}`);
    vitaminE && setValue('ingredientInfo.vitaminE', `${vitaminE}`);
    vitaminK2 && setValue('ingredientInfo.vitaminK2', `${vitaminK2}`);
    zinc && setValue('ingredientInfo.zinc', `${zinc}`);
    chloride && setValue('ingredientInfo.chloride', `${chloride}`);
    selenium && setValue('ingredientInfo.selenium', `${selenium}`);
    molybdenum && setValue('ingredientInfo.molybdenum', `${molybdenum}`);
    chromium && setValue('ingredientInfo.chromium', `${chromium}`);
    copper && setValue('ingredientInfo.copper', `${copper}`);
    phosphorus && setValue('ingredientInfo.phosphorus', `${phosphorus}`);
    manganese && setValue('ingredientInfo.manganese', `${manganese}`);
    pantothenicAcid && setValue('ingredientInfo.pantothenicAcid', `${pantothenicAcid}`);

    // setting carriers values
    ingredientCarriers?.length && setValue('carriers', carriersList as CarrierSelectType[]);
    // setting subComponents values
    ingredientSubComponents?.length &&
      setValue('subComponents', subComponentList as SubComponentSelectType[]);
  }, [ingredient, setValue]);

  useEffect(() => {
    ingredient && setValues();
  }, [setValues, ingredient]);

  return <DuplicateIngredientForm loading={getLoading} fetchIngredient={fetchIngredient} methods={methods} />;
};

export default SetDuplicateFormulaFormValues;
