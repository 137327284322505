import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
// components
import DataLoader from 'components/common/DataLoader';
import NoDataFound from 'components/common/NoDataFound';
import BarHorizontalX from '../components/Charts/BarHorizontalX';
import ProductTypeSelect from 'components/common/simpleSelectors/ProductType';
import SubProductTypeSelect from 'components/common/simpleSelectors/SubProductType';
// constants, contexts, interfaces, reducer
import { FormulationBarCardProps, SelectType } from 'interfaces';
import { DosageReportContext } from 'contexts/DosageReportContext';
import { ActionType, initialState } from 'reducer/dosageReportReducer';
import {
  DOSAGE_FORM_BREAKDOWN_TEXT,
  DOSAGE_FORM_TEXT,
  HTTP_STATUS,
  PLEASE_SELECT_DOSAGE_FORM_OR_TYPE,
  TYPE_TEXT,
} from 'constants/index';
import { DosageFormulasPayload, useFindAllDosageFormulasLazyQuery } from 'generated/graphql';

const FormulationBarCard = forwardRef<ChartJSOrUndefined<'bar'>, FormulationBarCardProps>((_, ref) => {
  const { state, dispatch } = useContext(DosageReportContext);
  const { productType, subProductType, organization, fromDate, toDate } = state;
  const { value: organizationId } = organization || {};
  const { value: productTypeId } = productType || {};
  const { value: subProductTypeId } = subProductType || {};

  const [data, setData] = useState<DosageFormulasPayload['data']>([]);

  const [findDosageFormula, { loading }] = useFindAllDosageFormulasLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllDosageFormulas } = data;
      const { response, data: formulas } = findAllDosageFormulas || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setData(formulas as DosageFormulasPayload['data']);
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const { dataSet, labels } = useMemo(() => {
    if (data?.length) {
      const labels = data?.map((item) => {
        const { name } = item || {};
        return name || '';
      });

      const dataSet = data?.map((item) => {
        const { count } = item || {};
        return count || 0;
      });

      return { labels, dataSet };
    }
    return {
      labels: [],
      dataSet: [],
    };
  }, [data]);

  const resetPage = () => {
    setData([]);
  };

  const fetchFormulas = useCallback(async () => {
    if (productTypeId || subProductTypeId) {
      await findDosageFormula({
        variables: {
          findAllDosageFormulasInputs: {
            ...(toDate && { toDate }),
            ...(fromDate && { fromDate }),
            ...(productTypeId && { productTypeId }),
            ...(organizationId && { organizationId }),
            ...(subProductTypeId && { subProductTypeId }),
          },
        },
      });
    }
  }, [findDosageFormula, fromDate, organizationId, productTypeId, subProductTypeId, toDate]);

  useEffect(() => {
    fetchFormulas();
  }, [fetchFormulas]);

  const handleProductTypeChange = (value: SelectType) => {
    dispatch({ type: ActionType.SET_PRODUCT_TYPE, productType: value });
    dispatch({ type: ActionType.SET_SUB_PRODUCT_TYPE, subProductType: initialState.subProductType });
  };

  const handleSubProductTypeChange = (value: SelectType) => {
    dispatch({ type: ActionType.SET_SUB_PRODUCT_TYPE, subProductType: value });
  };

  return (
    <Card>
      <CardHeader title={DOSAGE_FORM_BREAKDOWN_TEXT} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <ProductTypeSelect
              isActive
              isClearable
              name={'dosageForm'}
              value={productType}
              title={DOSAGE_FORM_TEXT}
              handleChange={handleProductTypeChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <SubProductTypeSelect
              isActive
              isClearable
              name={'subType'}
              title={TYPE_TEXT}
              value={subProductType}
              productTypeId={productTypeId}
              handleChange={handleSubProductTypeChange}
            />
          </Grid>
        </Grid>
        {productTypeId || subProductTypeId ? (
          <Box mt={4}>
            {loading ? (
              <DataLoader columns={12} rows={5} />
            ) : (
              <BarHorizontalX dataSet={dataSet} labels={labels} ref={ref} />
            )}
          </Box>
        ) : (
          <NoDataFound noData description={PLEASE_SELECT_DOSAGE_FORM_OR_TYPE} />
        )}
      </CardContent>
    </Card>
  );
});

export default FormulationBarCard;
