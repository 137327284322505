import { Card, CardContent, CardHeader } from '@mui/material';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { forwardRef, useCallback, useContext, useEffect } from 'react';
// components
import Selector from 'components/common/Selector';
import DataLoader from 'components/common/DataLoader';
import LineChart from '../components/Charts/LineChart';
// constants, helper, interfaces, contexts, reducer, graphql
import { getLastFiveYears } from 'lib/helper';
import { UserGraphCardProps } from 'interfaces';
import { ActionType } from 'reducer/userReportReducer';
import { UserReportContext } from 'contexts/UserReportContext';
import { useFindUsersCountByYearLazyQuery } from 'generated/graphql';
import { HTTP_STATUS, PER_YEAR_TEXT, USERS_TEXT, YEAR_TEXT } from 'constants/index';

const UserGraphCard = forwardRef<ChartJSOrUndefined<'line'>, UserGraphCardProps>((_, ref) => {
  const { state, dispatch } = useContext(UserReportContext);
  const { year, usersCountByYear } = state;

  const [findUsersCountByYear, { loading }] = useFindUsersCountByYearLazyQuery({
    onCompleted: (data) => {
      const { findUsersCountByYear } = data || {};
      const { data: usersCountByYearData, response } = findUsersCountByYear || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        dispatch({ type: ActionType.SET_USERS_COUNT_BY_YEAR, usersCountByYear: usersCountByYearData });
      } else {
        dispatch({ type: ActionType.SET_USERS_COUNT_BY_YEAR, usersCountByYear: [] });
      }
    },

    onError: () => {
      dispatch({ type: ActionType.SET_USERS_COUNT_BY_YEAR, usersCountByYear: [] });
    },
  });

  const fetchUsersCountByYear = useCallback(async () => {
    await findUsersCountByYear({
      variables: {
        findUsersCountByYearInput: {
          year: Number(year),
        },
      },
    });
  }, [year, findUsersCountByYear]);

  useEffect(() => {
    fetchUsersCountByYear();
  }, [fetchUsersCountByYear]);

  return (
    <Card>
      <CardHeader
        action={
          <Selector
            value={year}
            title={YEAR_TEXT}
            id="user-graph-selector"
            options={getLastFiveYears()}
            onChange={(val) => dispatch({ type: ActionType.SET_YEAR, year: val })}
          />
        }
        title={USERS_TEXT}
        subheader={PER_YEAR_TEXT}
      />
      <CardContent>
        {loading ? (
          <DataLoader rows={1} columns={12} height={500} />
        ) : (
          <LineChart id={'user-line-chart'} data={usersCountByYear} ref={ref} />
        )}
      </CardContent>
    </Card>
  );
});

export default UserGraphCard;
