import { Edit, FileDownload } from '@mui/icons-material';
import { FC, Reducer, useCallback, useEffect, useReducer, useRef } from 'react';
import { Box, Grid, Typography, Button, IconButton } from '@mui/material';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
// components
import Statics from './Statics';
import EditDialog from './EditDialog';
import UpTimeGraph from './UpTimeGraph';
import PlatformPdf from './PlatformPdf';
import PlatformUser from './PlatformUser';
import PdfViewer from '../components/Pdf/PdfViewer';
// styles, constants
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { DOWNLOAD_REPORT_TEXT, HTTP_STATUS, PLATFORM_TEXT } from 'constants/index';
import {
  Action,
  State,
  ActionType,
  initialState,
  platformReportReducer,
} from 'reducer/platformReportReducer';
import { useGetPlatformUsersCountLazyQuery } from 'generated/graphql';

const Platform: FC = () => {
  const pieRef = useRef<ChartJSOrUndefined<'pie'>>(null);
  const lineRef = useRef<ChartJSOrUndefined<'line'>>(null);

  const [state, dispatch] = useReducer<Reducer<State, Action>>(platformReportReducer, initialState);
  const { year, data, open, lineCanvas, pieCanvas, openDialog, platformUsers } = state;

  const [getPlatformUsersCount, { loading }] = useGetPlatformUsersCountLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (res) => {
      const { getPlatformUsersCount } = res;
      const { response, data: formulas } = getPlatformUsersCount || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        dispatch({ type: ActionType.SET_PLATFORM_USER, platformUsers: formulas });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PLATFORM_USER, platformUsers: initialState.platformUsers });
  };

  const handleOpen = () => {
    const pieCanvasPng = pieRef.current?.toBase64Image('image/png');
    const lineCanvasPng = lineRef.current?.toBase64Image('image/png');
    dispatch({ type: ActionType.SET_PIE_CANVAS, pieCanvas: pieCanvasPng || '' });
    dispatch({ type: ActionType.SET_LINE_CANVAS, lineCanvas: lineCanvasPng || '' });
    dispatch({ type: ActionType.SET_OPEN, open: true });
  };

  const handleClose = () => dispatch({ type: ActionType.SET_OPEN, open: false });

  const handleDialogClose = () => dispatch({ type: ActionType.SET_OPEN_DIALOG, openDialog: false });

  const handleDialogOpen = () => {
    dispatch({ type: ActionType.SET_OPEN_DIALOG, openDialog: true });
  };

  const fetchPlatformUsersCount = useCallback(async () => {
    await getPlatformUsersCount();
  }, [getPlatformUsersCount]);

  useEffect(() => {
    fetchPlatformUsersCount();
  }, [fetchPlatformUsersCount]);

  return (
    <>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{PLATFORM_TEXT}</Typography>

        <Box>
          <IconButton onClick={handleDialogOpen} sx={{ mr: 1 }}>
            <Edit />
          </IconButton>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            <FileDownload />
            {DOWNLOAD_REPORT_TEXT}
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={8} md={8}>
          <Statics dispatch={dispatch} enhancementCount={data.enhancement} featureCount={data.feature} />
          <UpTimeGraph dispatch={dispatch} year={year} ref={lineRef} />
        </Grid>
        <Grid item xs={4} md={4}>
          <PlatformUser data={platformUsers} ref={pieRef} loading={loading} />
        </Grid>
      </Grid>

      <PdfViewer
        open={open}
        handleClose={handleClose}
        title={PLATFORM_TEXT}
        document={
          open ? (
            <PlatformPdf
              data={data}
              year={year}
              pieCanvas={pieCanvas}
              lineCanvas={lineCanvas}
              platformUsers={platformUsers}
            />
          ) : (
            <></>
          )
        }
      />

      <EditDialog data={data} dispatch={dispatch} handleClose={handleDialogClose} open={openDialog} />
    </>
  );
};

export default Platform;
