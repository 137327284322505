import { FC } from 'react';

import DosageFormListing from 'components/main/Reports/DosageForm';
// contexts
import { DosageReportContextProvider } from 'contexts/DosageReportContext';

const DosageForm: FC = () => (
  <DosageReportContextProvider>
    <DosageFormListing />
  </DosageReportContextProvider>
);

export default DosageForm;
