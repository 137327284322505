import { FC, useCallback, useMemo, useState } from 'react';
// components
import InfiniteSelect from './InfiniteSelect';
// interfaces, constants, graphql
import { HTTP_STATUS } from 'constants/index';
import { OrgUserSelectProps, SelectType } from 'interfaces';
import { useFindOrgUserSelectLazyQuery, UsersPayload } from 'generated/graphql';

const OrgUserSelect: FC<OrgUserSelectProps> = ({
  name,
  title,
  value,
  handleChange,
  margin = 'dense',
  isClearable = false,
}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [getLoading, setGetLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<UsersPayload['data']>([]);

  const updatedOptions = useMemo<SelectType[]>(() => {
    if (options?.length) {
      return options?.map((user) => {
        const { firstName, lastName, organization } = user || {};
        const { name, id } = organization || {};
        return {
          value: id || '',
          name: `${name || ''}`,
          description: `${firstName || ''} ${lastName || ''}`,
        };
      });
    }

    return [];
  }, [options]);

  const [findAllUsers, { loading }] = useFindOrgUserSelectLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllAdmin } = data;
      const { data: organizations, response, pagination } = findAllAdmin || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { totalPages: pages } = pagination || {};
        setTotalPages(pages);
        setOptions([
          ...(getLoading ? [] : options || []),
          ...((organizations as UsersPayload['data']) ?? []),
        ]);
        setGetLoading(false);
      } else {
        setGetLoading(false);
      }
    },
    onError: () => {
      setOptions([]);
      setGetLoading(false);
    },
  });

  const fetchAllUsers = useCallback(
    async (page?: number, query?: string) => {
      if (!page) setGetLoading(true);
      await findAllUsers({
        variables: {
          findAllAdminInput: {
            ...(query && { search: query }),
            paginationOptions: { page: page || 1, limit: 10 },
          },
        },
      });
    },
    [findAllUsers],
  );

  return (
    <InfiniteSelect
      name={name}
      title={title}
      value={value}
      margin={margin}
      loading={loading}
      fetchData={fetchAllUsers}
      setOptions={setOptions}
      totalPages={totalPages}
      getLoading={getLoading}
      options={updatedOptions}
      isClearable={isClearable}
      handleChange={handleChange}
    />
  );
};

export default OrgUserSelect;
