import { FC } from 'react';
import { Box, Card, Skeleton, Typography } from '@mui/material';
// components
import { GrowthIcon, DownFallIcon } from 'assets/svgs';
// interfaces, theme,
import { StatsCardProps } from 'interfaces';

const StatsCard: FC<StatsCardProps> = ({ stats, title, loading, downfall, description }) => {
  return (
    <Card>
      <Typography variant="body1" mb={2} color={'white'}>
        {title}
      </Typography>
      <Typography variant="h3" mb={2}>
        {loading ? <Skeleton variant="rectangular" /> : stats}
      </Typography>
      {typeof downfall === 'boolean' && (
        <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
          {downfall ? <DownFallIcon /> : <GrowthIcon />}
          <Typography color={'white'} ml={2}>
            {description}
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default StatsCard;
