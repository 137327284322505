import { forwardRef } from 'react';
import IosIcon from '@mui/icons-material/Apple';
import WebIcon from '@mui/icons-material/Language';
import AndroidIcon from '@mui/icons-material/Android';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material';
// components
import PieChart from '../components/Charts/PieChart';
import DataLoader from 'components/common/DataLoader';
import LegendLabel from '../components/Charts/LegendLabel';
// constants, constants
import { PlatformUserProps } from 'interfaces';
import { CHART_COLORS } from '../components/Charts/utils';
import { ANDROID_TEXT, IOS_TEXT, USERS_TEXT, WEB_TEXT } from 'constants/index';

const PlatformUser = forwardRef<ChartJSOrUndefined<'pie'>, PlatformUserProps>((props, ref) => {
  const { data, loading } = props;

  const web = Number(data.web);
  const ios = Number(data.ios);
  const android = Number(data.android);
  const total = web + ios + android;

  return (
    <>
      <Card>
        <CardHeader title={USERS_TEXT} />
        <CardContent>
          {loading ? (
            <DataLoader columns={12} rows={1} height={400} />
          ) : (
            <PieChart
              dataSet={[Number(data.web), Number(data.android), Number(data.ios)]}
              labels={[WEB_TEXT, ANDROID_TEXT, IOS_TEXT]}
              total={total}
              ref={ref}
            />
          )}
          <Box>
            {loading ? (
              <DataLoader columns={6} rows={3} />
            ) : (
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                  <LegendLabel
                    label={WEB_TEXT}
                    bgColor={CHART_COLORS.blue}
                    icon={<WebIcon />}
                    subLabel={`${data.web} ${USERS_TEXT}`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LegendLabel
                    label={IOS_TEXT}
                    bgColor={CHART_COLORS.yellow}
                    icon={<IosIcon />}
                    subLabel={`${data.ios} ${USERS_TEXT}`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LegendLabel
                    label={ANDROID_TEXT}
                    bgColor={CHART_COLORS.purple}
                    icon={<AndroidIcon />}
                    subLabel={`${data.android} ${USERS_TEXT}`}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
});

export default PlatformUser;
