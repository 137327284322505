import { Pie } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { forwardRef, useMemo } from 'react';
import { SimplePaletteColorOptions } from '@mui/material';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
// theme, interfaces
import palette from 'theme/palette';
import { chartPieColors } from './utils';
import { PieChartProps } from 'interfaces';
import { getUpto2Decimal } from 'lib/helper';

const PieChart = forwardRef<ChartJSOrUndefined<'pie'>, PieChartProps>(
  ({ dataSet, labels, total = 1 }, ref) => {
    const { customBlackColor } = palette;
    const { dark } = (customBlackColor as SimplePaletteColorOptions) || {};

    const dataPie = useMemo(() => {
      if (dataSet?.length) {
        return {
          labels: labels,
          datasets: [
            {
              data: dataSet,
              backgroundColor: chartPieColors,
            },
          ],
          borderColor: dark,
        };
      }
      return {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: chartPieColors,
          },
        ],
        borderColor: dark,
      };
    }, [dataSet, labels, dark]);

    const options: ChartOptions<'pie'> = {
      responsive: true,
      plugins: {
        legend: {
          display: false, // Enable or disable legend
          labels: {
            color: '#fff',
            font: {
              size: 14,
            },
          },
        },
        datalabels: {
          offset: 10,
          color: '#fff', // White labels
          formatter: (value: number) => {
            return `${getUpto2Decimal((value / total) * 100, 0)} %`;
          }, // Show bar values
          font: {
            size: 14,
          },
        },
      },
    };

    return <Pie data={dataPie} options={options} ref={ref} />;
  },
);

export default PieChart;
