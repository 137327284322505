import { useParams } from 'react-router-dom';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
import { FC, Fragment, JSX, Reducer, useCallback, useEffect, useReducer } from 'react';
import { Box, Button, Chip, TableCell, TableRow, Typography } from '@mui/material';
// components
import AddCoatingFormulationComponent from './Add';
import EditCoatingFormulationComponent from './Edit';
import { Alert } from 'components/common/Alert';
import NoDataFound from 'components/common/NoDataFound';
import TableLoader from 'components/common/TableLoader';
import EmptyTableTab from 'components/common/EmptyTableTab';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
import SandCoatingFormulationCard from './components/CoatingFormulationComponentCard';
// constant, styles, interfaces
import {
  HTTP_STATUS,
  ADD_NEW_TEXT,
  LOADING_TABLE_ROWS,
  COATING_FORMULATION_COMPONENT_TEXT,
  COATING_FORMULATION_COMPONENT_TABLE_HEADER,
  INACTIVE_TEXT,
} from 'constants/index';
import {
  useFindAllCoatingFormulationComponentLazyQuery,
  SandCoatingFormulationPayload,
  CoatingFormulationComponentsPayload,
  CoatingFormulationComponent,
  useRemoveCoatingFormulationComponentMutation,
} from 'generated/graphql';
import {
  State,
  Action,
  ActionType,
  initialState,
  CoatingFormulationComponentReducer,
} from 'reducer/coatingFormulationComponent';
import { ParamType } from 'interfaces';
import { flexCenterBetween, textWhiteSpace } from 'styles/commonComponentStyle';

const CoatingFormulationTable: FC = (): JSX.Element => {
  const params = useParams<ParamType>();
  const { id: sandCoatingFormulationId } = params || {};

  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    CoatingFormulationComponentReducer,
    initialState,
  );
  const { data, addOpen, editOpen, coatingFormulationComponent, sandCoatingFormulation } = state;

  const [findAllSandCoatingFormulation, { loading: fetchLoading, error }] =
    useFindAllCoatingFormulationComponentLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { getSandCoatingFormulation } = data;
        const { response, sandCoatingFormulation } = getSandCoatingFormulation || {};
        const { status } = response || {};
        if (status === HTTP_STATUS.SUCCESS) {
          const { coatingFormulationComponent, ...rest } = sandCoatingFormulation || {};
          dispatch({
            type: ActionType.SET_DATA,
            data: coatingFormulationComponent as CoatingFormulationComponentsPayload['data'],
          });
          dispatch({
            type: ActionType.SET_SAND_COATING_FORMULATION,
            sandCoatingFormulation: rest as SandCoatingFormulationPayload['sandCoatingFormulation'],
          });
        } else {
          resetPage();
        }
      },

      onError: () => {
        resetPage();
      },
    });

  const [removeCoatingFormulationComponent, { loading: delLoading }] =
    useRemoveCoatingFormulationComponentMutation({
      onCompleted: (data) => {
        const { removeCoatingFormulationComponent } = data;
        const { response } = removeCoatingFormulationComponent || {};
        const { status, message } = response || {};
        if (status === HTTP_STATUS.SUCCESS) {
          Alert.success(message ?? '');
          fetchSandCoatingFormulation();
        } else {
          Alert.error(message ?? '');
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const onDelete = async (delId: string) => {
    try {
      await removeCoatingFormulationComponent({
        variables: {
          removeCoatingFormulationComponentInput: {
            id: delId,
          },
        },
      });
    } catch (error) {}
  };

  const resetPage = () => {
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchSandCoatingFormulation = useCallback(async () => {
    if (sandCoatingFormulationId) {
      await findAllSandCoatingFormulation({
        variables: {
          getSandCoatingFormulationInput: {
            id: sandCoatingFormulationId,
          },
        },
      });
    }
  }, [findAllSandCoatingFormulation, sandCoatingFormulationId]);

  useEffect(() => {
    sandCoatingFormulationId && fetchSandCoatingFormulation();
  }, [fetchSandCoatingFormulation, sandCoatingFormulationId]);

  const loading = fetchLoading || delLoading;

  const noData = Boolean((!fetchLoading && data?.length === 0) || error);

  const onAddOpen = () => {
    dispatch({ type: ActionType.SET_ADD_OPEN, addOpen: true });
  };

  const onAddClose = () => {
    dispatch({ type: ActionType.SET_ADD_OPEN, addOpen: false });
  };

  const onEditOpen = (item: CoatingFormulationComponent) => {
    dispatch({ type: ActionType.SET_COATING_FORMULATION_COMPONENT, coatingFormulationComponent: item });
    dispatch({ type: ActionType.SET_EDIT_OPEN, editOpen: true });
  };

  const onEditClose = () => {
    dispatch({ type: ActionType.SET_EDIT_OPEN, editOpen: false });
    dispatch({ type: ActionType.SET_COATING_FORMULATION_COMPONENT, coatingFormulationComponent: null });
  };

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{COATING_FORMULATION_COMPONENT_TEXT}</Typography>
        <Button variant="contained" color="primary" onClick={onAddOpen}>
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button>
      </Box>

      <TableContainer>
        <EmptyTableTab />

        <SandCoatingFormulationCard sandCoatingFormulation={sandCoatingFormulation} loading={loading} />

        <TableComponent noData tableHeader={COATING_FORMULATION_COMPONENT_TABLE_HEADER}>
          {loading ? (
            <TableLoader columns={3} rows={LOADING_TABLE_ROWS} />
          ) : (
            <Fragment>
              {data?.map((cell) => {
                const { id, weight, sandComponent } = cell || {};
                const { name: componentName, isActive } = sandComponent || {};

                return (
                  <TableRow key={id}>
                    {/* <TableCell sx={textWhiteSpace}>{componentName ?? '--'}</TableCell> */}
                    <TableCell
                      sx={{
                        ...textWhiteSpace,
                        ...(isActive ? {} : { fontStyle: 'italic' }),
                      }}>
                      {componentName ?? '--'}{' '}
                      {!isActive && (
                        <Chip size="small" variant="outlined" label={INACTIVE_TEXT} color={'error'} />
                      )}
                    </TableCell>
                    <TableCell sx={textWhiteSpace}>{weight ? `${weight}` : '--'}</TableCell>
                    <TableCell>
                      <ActionMenuDropdown
                        id={id ?? ''}
                        isActive={false}
                        onDelete={onDelete}
                        onEdit={() => onEditOpen(cell)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </Fragment>
          )}
        </TableComponent>

        <NoDataFound noData={noData} />
      </TableContainer>

      <AddCoatingFormulationComponent
        open={addOpen}
        onClose={onAddClose}
        sandCoatingFormulation={sandCoatingFormulation}
        fetch={fetchSandCoatingFormulation}
      />

      <EditCoatingFormulationComponent
        open={editOpen}
        onClose={onEditClose}
        coatingFormulationComponent={coatingFormulationComponent}
        sandCoatingFormulation={sandCoatingFormulation}
        fetch={fetchSandCoatingFormulation}
      />
    </Fragment>
  );
};

export default CoatingFormulationTable;
