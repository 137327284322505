import { FC } from 'react';
import { Box, Card, Skeleton, Typography } from '@mui/material';
// styles, interfaces
import palette from 'theme/palette';
import { DashboardStatsCardProps } from 'interfaces';
import { flexJustifyBetween } from 'theme/styleConstant';

const DashboardStatsCard: FC<DashboardStatsCardProps> = ({ icon, stats, title, loading }) => {
  return (
    <Card sx={flexJustifyBetween}>
      <Box>
        <Typography variant="subtitle2" mb={2} color={palette.common?.white}>
          {title}
        </Typography>
        <Typography variant="h3">{loading ? <Skeleton variant="rectangular" /> : stats}</Typography>
      </Box>

      <Box>{icon}</Box>
    </Card>
  );
};

export default DashboardStatsCard;
