import * as yup from 'yup';
// interfaces, helper
import {
  BlendItemType,
  YupSchemaShape,
  AddNewFormulaFormType,
  FormulaInfoCardFormType,
  DosageFormulationFormType,
  FactBoxFormulationFormType,
  FormulaIngredientFormType,
  SaveFormulaAsDraftFormType,
} from 'interfaces';
import {
  TYPE_TEXT,
  UNIT_TEXT,
  FORM_TEXT,
  VALUE_TEXT,
  COLOR_TEXT,
  TABLET_TEXT,
  BEADLETS_TEXT,
  FACT_BOX_TEXT,
  EXCIPIENT_TEXT,
  BOTTLE_CAP_TEXT,
  SYRUP_BASE_TEXT,
  BLEND_NAME_TEXT,
  FORMULATION_TEXT,
  PROTEIN_BASE_TEXT,
  FORMULA_NAME_TEXT,
  PRODUCT_TYPE_TEXT,
  SERVING_UNIT_TEXT,
  FLAVOR_SYSTEM_TEXT,
  DESIRED_SERVING_SIZE,
  SWEETENER_SYSTEM_TEXT,
  SERVING_PER_CONTAINER,
  MINI_TABLET_COLOR_TEXT,
  PRODUCT_FORMULATION_TEXT,
  EXCIPIENT_GENERATOR_TEXT,
  INNER_CAPSULE_POLYMER_TEXT,
  OUTER_CAPSULE_POLYMER_TEXT,
  TYPE_OF_CAPSULE_POLYMER_TEXT,
  MINI_TABLETS_FORMULATION_TEXT,
  TABLET_COLOR_TEXT,
} from 'constants/index';
import { requiredMessage } from 'lib/helper';
import { ProductFormulationTypes } from 'generated/graphql';
import { requiredPositiveNumber, selectorSchema, positiveNumberMax } from './commonValidations';

export const formulaIngredientsSchema = (isTypeOptional: boolean = false) =>
  yup.object().shape({
    value: requiredPositiveNumber(VALUE_TEXT, 5),
    unit: yup.string().required(requiredMessage(UNIT_TEXT)),
    type: isTypeOptional ? yup.string() : yup.string().required(requiredMessage(TYPE_TEXT)),
  });

export const excipientSchema = yup.object().shape({
  excipientType: selectorSchema(EXCIPIENT_TEXT),
  excipientLabel: yup.string().required(requiredMessage(EXCIPIENT_GENERATOR_TEXT)),
});

export const addFormulaSchema = yup.object().shape<YupSchemaShape<AddNewFormulaFormType>>({
  name: yup.string().trim().required(requiredMessage(FORMULA_NAME_TEXT)),
  formulaIngredients: yup.array().of(formulaIngredientsSchema()),
});

export const formulaInfoCardSchema = yup.object().shape<YupSchemaShape<FormulaInfoCardFormType>>({
  name: yup.string().trim().required(requiredMessage(FORMULA_NAME_TEXT)),
});

export const subProductSchema = yup.object().shape<YupSchemaShape<DosageFormulationFormType>>({
  productSubType: yup.string().required(requiredMessage(FORM_TEXT)),
});

const dosageSchema = yup.object().shape<YupSchemaShape<DosageFormulationFormType>>({
  productType: yup.string().required(requiredMessage(PRODUCT_TYPE_TEXT)),
  productFormulationType: selectorSchema(PRODUCT_FORMULATION_TEXT),
  servingPerContainer: positiveNumberMax(SERVING_PER_CONTAINER, 1000),
  desiredServingSize: positiveNumberMax(DESIRED_SERVING_SIZE, 100),
  servingUnit: yup.string().required(requiredMessage(SERVING_UNIT_TEXT)),
  bottleCap: yup.string().required(requiredMessage(BOTTLE_CAP_TEXT)),
});

export const factBoxSchema = (isTypeOptional: boolean = false) => {
  return yup.object().shape<YupSchemaShape<FactBoxFormulationFormType>>({
    factBoxType: yup.string().required(requiredMessage(FACT_BOX_TEXT)),
    formulaIngredients: yup.array().of(formulaIngredientsSchema(isTypeOptional)),
  });
};

export const formulaIngredientSchema = (isTypeOptional: boolean = false) => {
  return yup.object().shape<YupSchemaShape<FormulaIngredientFormType>>({
    formulaIngredients: yup.array().of(formulaIngredientsSchema(isTypeOptional)),
  });
};

export const coatingSchema = yup.object().shape<YupSchemaShape<DosageFormulationFormType>>({
  tabletCoating: yup.string(),
});

const flavorAndSweetenerSchema = yup.object().shape<YupSchemaShape<DosageFormulationFormType>>({
  flavorType: yup.string(),
  flavorSystem: selectorSchema(FLAVOR_SYSTEM_TEXT, false),

  sweetenerType: yup.string(),
  sweetenerSystem: selectorSchema(SWEETENER_SYSTEM_TEXT, false),
});

const powderSchema = (isFactBox: boolean = false) => {
  return yup
    .object()
    .shape<YupSchemaShape<DosageFormulationFormType>>({
      factBoxType: isFactBox ? yup.string() : yup.string().required(requiredMessage(FACT_BOX_TEXT)),
    })
    .concat(flavorAndSweetenerSchema)
    .concat(dosageSchema);
};

const gummiesSchema = (isFactBox: boolean = false) => {
  return yup
    .object()
    .shape<YupSchemaShape<DosageFormulationFormType>>({
      factBoxType: isFactBox ? yup.string() : yup.string().required(requiredMessage(FACT_BOX_TEXT)),
      sandCoating: yup.string(),
      productSize: selectorSchema(SYRUP_BASE_TEXT),
    })
    .concat(dosageSchema)
    .concat(coatingSchema)
    .concat(subProductSchema)
    .concat(flavorAndSweetenerSchema);
};

const tabletSchema = yup.object().shape<YupSchemaShape<DosageFormulationFormType>>({}).concat(dosageSchema);

const capsuleSchema = yup
  .object()
  .shape<YupSchemaShape<DosageFormulationFormType>>({
    capsuleType: yup.string().required(requiredMessage(TYPE_OF_CAPSULE_POLYMER_TEXT)),
  })
  .concat(subProductSchema);

const colorCoatingSchema = yup
  .object()
  .shape<YupSchemaShape<DosageFormulationFormType>>({
    coatingColor: yup.string().required(requiredMessage(COLOR_TEXT)),
    // colorsHex: yup.array().of(yup.string()),
  })
  .concat(coatingSchema);

export const formulationSchema = (type: ProductFormulationTypes, isFactBox: boolean = false) => {
  switch (type) {
    // capsule cases

    case ProductFormulationTypes.PowderFilled:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({})
        .concat(capsuleSchema)
        .concat(dosageSchema);

    case ProductFormulationTypes.LiquidFilled:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({})
        .concat(capsuleSchema)
        .concat(dosageSchema);

    case ProductFormulationTypes.CapInCap:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({
          innerCapsuleType: yup.string().required(requiredMessage(INNER_CAPSULE_POLYMER_TEXT)),
          outerCapsuleType: yup.string().required(requiredMessage(OUTER_CAPSULE_POLYMER_TEXT)),
        })
        .concat(dosageSchema);

    case ProductFormulationTypes.LiquidFilledWithBeadlets:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({
          beadlets: yup.string().required(requiredMessage(BEADLETS_TEXT)),
        })
        .concat(capsuleSchema)
        .concat(dosageSchema);

    case ProductFormulationTypes.LiquidFilledWithTablet:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({
          tabletType: yup.string().required(requiredMessage(TABLET_TEXT)),
          tabletCoating: yup.string(),
          coatingColor: yup.string().required(requiredMessage(TABLET_COLOR_TEXT)),
        })
        .concat(capsuleSchema)
        .concat(dosageSchema);

    case ProductFormulationTypes.LiquidFilledWithMiniTabs:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({
          miniTabFormulation: yup.string().required(requiredMessage(MINI_TABLETS_FORMULATION_TEXT)),
          tabletCoating: yup.string(),
          coatingColor: yup.string().required(requiredMessage(MINI_TABLET_COLOR_TEXT)),
        })
        .concat(capsuleSchema)
        .concat(dosageSchema);

    // tablet cases

    case ProductFormulationTypes.SingleLayerTablet:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({})
        .concat(tabletSchema)
        .concat(colorCoatingSchema);

    case ProductFormulationTypes.BiLayeredTablet:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({
          coatingColor2: yup.string().required(requiredMessage(COLOR_TEXT)),
        })
        .concat(tabletSchema)
        .concat(colorCoatingSchema);

    case ProductFormulationTypes.TriLayerTablet:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({
          coatingColor2: yup.string().required(requiredMessage(COLOR_TEXT)),
          coatingColor3: yup.string().required(requiredMessage(COLOR_TEXT)),
        })
        .concat(tabletSchema)
        .concat(colorCoatingSchema);

    case ProductFormulationTypes.MiniTablet:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({
          miniTabFormulation: yup.string().required(requiredMessage(FORMULATION_TEXT)),
        })
        .concat(tabletSchema)
        .concat(colorCoatingSchema);

    case ProductFormulationTypes.ChewableTablet:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({})
        .concat(tabletSchema)
        .concat(flavorAndSweetenerSchema);

    case ProductFormulationTypes.EffervescentTablet:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({})
        .concat(tabletSchema)
        .concat(flavorAndSweetenerSchema);

    // powder cases

    case ProductFormulationTypes.ProteinPowder:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({
          proteinBase: yup.string().required(requiredMessage(PROTEIN_BASE_TEXT)),
        })
        .concat(powderSchema(isFactBox));

    case ProductFormulationTypes.PreOrPostPowder:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({
          veganType: yup.string(),
        })
        .concat(powderSchema(isFactBox));

    case ProductFormulationTypes.HydrationPowder:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({})
        .concat(powderSchema(isFactBox));

    case ProductFormulationTypes.GreensOrRedsPowder:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({})
        .concat(powderSchema(isFactBox));

    case ProductFormulationTypes.BcaaPowder:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({
          veganType: yup.string(),
        })
        .concat(powderSchema(isFactBox));

    case ProductFormulationTypes.AllOthers:
      return yup
        .object()
        .shape<YupSchemaShape<DosageFormulationFormType>>({
          veganType: yup.string(),
        })
        .concat(powderSchema(isFactBox));

    // gummies

    case ProductFormulationTypes.NaturalSugarFree:
      return gummiesSchema(isFactBox);

    case ProductFormulationTypes.NonGmoSugar:
      return gummiesSchema(isFactBox);

    case ProductFormulationTypes.OrganicSugar:
      return gummiesSchema(isFactBox);

    case ProductFormulationTypes.SugarAlcohol:
      return gummiesSchema(isFactBox);

    case ProductFormulationTypes.SugarBased:
      return gummiesSchema(isFactBox);

    default:
      return dosageSchema;
  }
};

export const blendItemSchema = yup.object().shape<YupSchemaShape<BlendItemType>>({
  name: yup.string().trim().required(requiredMessage(BLEND_NAME_TEXT)),
});

export const saveFormulaAsDraftSchema = yup.object().shape<YupSchemaShape<SaveFormulaAsDraftFormType>>({
  name: yup.string().trim().required(requiredMessage(FORMULA_NAME_TEXT)),
});
