// packages block
import { FC, Fragment } from 'react';
import { Typography, Box } from '@mui/material';
// components
import { NoDataIcon } from 'assets/svgs';
// constant, svg, interface
import { NoDataFoundProps } from 'interfaces';
import { NO_DATA_FOUND } from 'constants/index';
import { flexCenter } from 'theme/styleConstant';

const NoDataFound: FC<NoDataFoundProps> = ({ noData, title = NO_DATA_FOUND, description }): JSX.Element => {
  return (
    <Fragment>
      {noData && (
        <Box sx={flexCenter} flexDirection="column" py={7}>
          <Box>
            <NoDataIcon />
          </Box>

          <Typography variant="h5" color={`customGrayColor.light`} textAlign={`center`} gutterBottom>
            {title}
          </Typography>

          {description && (
            <Typography variant="body2" color={`customGrayColor.light`} textAlign={'center'} gutterBottom>
              {description}
            </Typography>
          )}
        </Box>
      )}
    </Fragment>
  );
};

export default NoDataFound;
