import { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
import IngredientForm from 'components/main/Ingredients/Add/IngredientForm';
// interfaces, schema, graphql, constants, helpers
import { formatIngredientInput } from 'lib/helper';
import { AddNewIngredientFormType } from 'interfaces';
import { addNewIngredientSchema } from 'validationSchema';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { useCreateIngredientMutation } from 'generated/graphql';
import {
  HTTP_STATUS,
  ADD_INGREDIENT_TEXT,
  INGREDIENT_IS_CREATING_TEXT,
  addNewIngredientInitialValues,
} from 'constants/index';

const AddIngredient: FC = () => {
  const navigate = useNavigate();

  const methods = useForm<AddNewIngredientFormType>({
    defaultValues: addNewIngredientInitialValues,
    resolver: yupResolver(addNewIngredientSchema),
  });

  const { handleSubmit } = methods;

  const [createIngredient, { loading: createIngredientLoading }] = useCreateIngredientMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createIngredient } = data;
      const { response } = createIngredient || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        navigate(-1);
        Alert.success(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<AddNewIngredientFormType> = async (data) => {
    const createIngredientInput = formatIngredientInput(data);
    await createIngredient({
      variables: {
        createIngredientInput,
      },
    });
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{ADD_INGREDIENT_TEXT}</Typography>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              id="create-ingredient-btn"
              disabled={createIngredientLoading}>
              {ADD_INGREDIENT_TEXT}
            </Button>
          </Box>
          <IngredientForm loading={createIngredientLoading} />
        </form>
      </FormProvider>
      <BackdropLoader open={createIngredientLoading} text={INGREDIENT_IS_CREATING_TEXT} />
    </Fragment>
  );
};

export default AddIngredient;
