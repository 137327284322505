import { Grid } from '@mui/material';
import { FC } from 'react';
// components
import StatsCard from '../components/StatsCard';
// constants
import { ENHANCEMENTS_ADDED_TEXT, FEATURES_ADDED_TEXT } from 'constants/index';
import { StaticsProps } from 'interfaces';

const Statics: FC<StaticsProps> = ({ featureCount, enhancementCount }) => {
  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <StatsCard title={FEATURES_ADDED_TEXT} stats={featureCount} loading={false} />
        </Grid>

        <Grid item xs={12} md={6}>
          <StatsCard title={ENHANCEMENTS_ADDED_TEXT} stats={enhancementCount} loading={false} />
        </Grid>
      </Grid>
    </>
  );
};

export default Statics;
