import { FC } from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { Dialog, Button, IconButton, DialogTitle, DialogActions, DialogContent } from '@mui/material';
// components
import { CloseIcon } from 'assets/svgs';
// interfaces, constants
import { PdfViewerProps } from 'interfaces';
import { CLOSE_TEXT, DOWNLOAD_TEXT } from 'constants/index';

const PdfViewer: FC<PdfViewerProps> = ({ open, handleClose, document, title }) => {
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}>
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: '100%', height: '80vh' }} dividers>
        <PDFViewer style={{ width: '100%', height: '100%' }}>{document}</PDFViewer>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose} sx={{ mr: 2 }}>
          {CLOSE_TEXT}
        </Button>

        <PDFDownloadLink document={document} fileName={`${title}_${new Date().toLocaleDateString()}.pdf`}>
          <Button variant="contained" color="primary">
            {DOWNLOAD_TEXT}
          </Button>
        </PDFDownloadLink>
      </DialogActions>
    </Dialog>
  );
};

export default PdfViewer;
