// auth routes
export const AUTH_LINKS = {
  LOGIN_LINK: '/login',
  FORGOT_PASSWORD_LINK: '/forgot-password',
  REQUEST_SENT_SUCCESSFULLY: '/request-sent',
  SET_PASSWORD_LINK: '/set-password',
  PASSWORD_SUCCESS: '/password-success',
};

// General routes

export const DUPLICATE_ROUTE = '/duplicate';

// Routes
export const ROOT_ROUTE = '/';
export const DASHBOARD_ROUTE = '/dashboard';
// Users routes
export const USERS_ROUTE = '/users';
export const PROFILE_ROUTE = '/profile';
export const SETTINGS_ROUTE = '/settings';
export const ADD_USER_ROUTE = `${USERS_ROUTE}/add`;
export const EDIT_USER_ROUTE = `${USERS_ROUTE}/update`;
export const EDIT_USER_WITH_ID_ROUTE = `${USERS_ROUTE}/update/:id`;

// supplier routes

// Ingredients routes
export const SUPPLIER_ROUTE = '/supplier';
export const ADD_SUPPLIER_ROUTE = `${SUPPLIER_ROUTE}/add`;
export const EDIT_SUPPLIER_ROUTE = `${SUPPLIER_ROUTE}/update`;
export const DUPLICATE_SUPPLIER_ROUTE = `${SUPPLIER_ROUTE}${DUPLICATE_ROUTE}`;
export const DUPLICATE_SUPPLIER_WITH_ID_ROUTE = `${SUPPLIER_ROUTE}${DUPLICATE_ROUTE}/:id`;
export const EDIT_SUPPLIER_WITH_ID_ROUTE = `${SUPPLIER_ROUTE}/update/:id`;

// Ingredients routes
export const INGREDIENTS_ROUTE = '/ingredients';
export const ADD_INGREDIENT_ROUTE = `${INGREDIENTS_ROUTE}/add`;
export const EDIT_INGREDIENT_ROUTE = `${INGREDIENTS_ROUTE}/update`;
export const EDIT_INGREDIENT_WITH_ID_ROUTE = `${INGREDIENTS_ROUTE}/update/:id`;
export const DUPLICATE_INGREDIENT_ROUTE = `${INGREDIENTS_ROUTE}${DUPLICATE_ROUTE}`;
export const DUPLICATE_INGREDIENT_WITH_ID_ROUTE = `${DUPLICATE_INGREDIENT_ROUTE}/:id`;
// formula routes
const DOSAGE_TEXT = 'dosage';
const FACT_BOX_TEXT = 'fact-box';
const INGREDIENT_TEXT = 'ingredient';

export const FORMULA_ROUTE = '/formula';
export const EDIT_FORMULA_ROUTE = `${FORMULA_ROUTE}/update`;

export const ADD_FORMULA_BY_DOSAGE_ROUTE = `${FORMULA_ROUTE}/${DOSAGE_TEXT}/add`;
export const ADD_FORMULA_BY_FACT_BOX_ROUTE = `${FORMULA_ROUTE}/${FACT_BOX_TEXT}/add`;
export const ADD_FORMULA_BY_INGREDIENTS_ROUTE = `${FORMULA_ROUTE}/${INGREDIENT_TEXT}/add`;

export const DUPLICATE_FORMULA_ROUTE = `${FORMULA_ROUTE}${DUPLICATE_ROUTE}`;
export const EDIT_FORMULA_WITH_ID_ROUTE = `${FORMULA_ROUTE}/update/:id`;
export const DUPLICATE_FORMULA_WITH_ID_ROUTE = `${FORMULA_ROUTE}${DUPLICATE_ROUTE}/:id`;
export const FORMULA_DETAIL_ROUTE = `${FORMULA_ROUTE}/formula-detail`;
export const FORMULA_DETAIL_ROUTE_WITH_ID = `${FORMULA_ROUTE}/formula-detail/:id`;
// staff routes
export const STAFF_ROUTE = '/staff';
export const ADD_STAFF_ROUTE = `${STAFF_ROUTE}/add`;
export const EDIT_STAFF_ROUTE = `${STAFF_ROUTE}/update`;
export const EDIT_STAFF_WITH_ID_ROUTE = `${STAFF_ROUTE}/update/:id`;

// Product Type routes
export const PRODUCT_TYPE_ROUTE = '/product-type';
export const ADD_PRODUCT_TYPE_ROUTE = `${PRODUCT_TYPE_ROUTE}/add`;
export const EDIT_PRODUCT_TYPE_ROUTE = `${PRODUCT_TYPE_ROUTE}/update`;
export const EDIT_PRODUCT_TYPE_WITH_ID_ROUTE = `${PRODUCT_TYPE_ROUTE}/update/:id`;

// sub Product Type routes
export const SUB_PRODUCT_TYPE_ROUTE = '/sub-product-type';
export const ADD_SUB_PRODUCT_TYPE_ROUTE = `${SUB_PRODUCT_TYPE_ROUTE}/add`;
export const EDIT_SUB_PRODUCT_TYPE_ROUTE = `${SUB_PRODUCT_TYPE_ROUTE}/update`;
export const EDIT_SUB_PRODUCT_TYPE_WITH_ID_ROUTE = `${SUB_PRODUCT_TYPE_ROUTE}/update/:id`;

// product Size routes
export const PRODUCT_SIZE_ROUTE = '/product-size';
export const ADD_PRODUCT_SIZE_ROUTE = `${PRODUCT_SIZE_ROUTE}/add`;
export const EDIT_PRODUCT_SIZE_ROUTE = `${PRODUCT_SIZE_ROUTE}/update`;
export const EDIT_PRODUCT_SIZE_WITH_ID_ROUTE = `${PRODUCT_SIZE_ROUTE}/update/:id`;

// product formulation routes
export const PRODUCT_FORMULATION_ROUTE = '/product-formulation';
export const ADD_PRODUCT_FORMULATION_ROUTE = `${PRODUCT_FORMULATION_ROUTE}/add`;
export const EDIT_PRODUCT_FORMULATION_ROUTE = `${PRODUCT_FORMULATION_ROUTE}/update`;
export const EDIT_PRODUCT_FORMULATION_WITH_ID_ROUTE = `${PRODUCT_FORMULATION_ROUTE}/update/:id`;

// Excipient routes
export const EXCIPIENT_ROUTE = '/excipient';
export const ADD_EXCIPIENT_ROUTE = `${EXCIPIENT_ROUTE}/add`;
export const EDIT_EXCIPIENT_ROUTE = `${EXCIPIENT_ROUTE}/update`;
export const EDIT_EXCIPIENT_WITH_ID_ROUTE = `${EXCIPIENT_ROUTE}/update/:id`;

// Excipient Label routes
export const EXCIPIENT_LABEL_ROUTE = '/excipient-label';
export const ADD_EXCIPIENT_LABEL_ROUTE = `${EXCIPIENT_LABEL_ROUTE}/add`;
export const EDIT_EXCIPIENT_LABEL_ROUTE = `${EXCIPIENT_LABEL_ROUTE}/update`;
export const EDIT_EXCIPIENT_LABEL_WITH_ID_ROUTE = `${EXCIPIENT_LABEL_ROUTE}/update/:id`;

// Excipient Type routes
export const EXCIPIENT_TYPES_ROUTE = '/excipient-types';
export const ADD_EXCIPIENT_TYPES_ROUTE = `${EXCIPIENT_TYPES_ROUTE}/add`;
export const EDIT_EXCIPIENT_TYPES_ROUTE = `${EXCIPIENT_TYPES_ROUTE}/update`;
export const EDIT_EXCIPIENT_TYPES_WITH_ID_ROUTE = `${EXCIPIENT_TYPES_ROUTE}/update/:id`;

// Product Excipient routes
export const PRODUCT_EXCIPIENT_ROUTE = '/product-excipient';
export const PRODUCT_EXCIPIENT_WITH_ID_ROUTE = `${PRODUCT_EXCIPIENT_ROUTE}/:excipientTypeId`;

// Bottle size routes
export const BOTTLE_SIZE_ROUTE = '/bottle-size';
export const ADD_BOTTLE_SIZE_ROUTE = `${BOTTLE_SIZE_ROUTE}/add`;
export const EDIT_BOTTLE_SIZE_ROUTE = `${BOTTLE_SIZE_ROUTE}/update`;
export const EDIT_BOTTLE_SIZE_WITH_ID_ROUTE = `${BOTTLE_SIZE_ROUTE}/update/:id`;

// Bottle Price routes
export const BOTTLE_PRICE_ROUTE = '/bottle-price';
export const ADD_BOTTLE_PRICE_ROUTE = `${BOTTLE_PRICE_ROUTE}/add`;
export const EDIT_BOTTLE_PRICE_ROUTE = `${BOTTLE_PRICE_ROUTE}/update`;
export const EDIT_BOTTLE_PRICE_WITH_ID_ROUTE = `${BOTTLE_PRICE_ROUTE}/update/:id`;

// Bottle Capacity routes
export const BOTTLE_CAPACITY_ROUTE = '/bottle-capacity';
export const ADD_BOTTLE_CAPACITY_ROUTE = `${BOTTLE_CAPACITY_ROUTE}/add`;
export const EDIT_BOTTLE_CAPACITY_ROUTE = `${BOTTLE_CAPACITY_ROUTE}/update`;
export const EDIT_BOTTLE_CAPACITY_WITH_ID_ROUTE = `${BOTTLE_CAPACITY_ROUTE}/update/:id`;

// Sweetener routes
export const SWEETENER_ROUTE = '/sweetener';
export const ADD_SWEETENER_ROUTE = `${SWEETENER_ROUTE}/add`;
export const EDIT_SWEETENER_ROUTE = `${SWEETENER_ROUTE}/update`;
export const EDIT_SWEETENER_WITH_ID_ROUTE = `${SWEETENER_ROUTE}/update/:id`;

// Sweetener Elements routes
export const SWEETENER_ELEMENT_ROUTES = '/sweetener-element';
export const SWEETENER_ELEMENT_WITH_ID_ROUTE = `${SWEETENER_ELEMENT_ROUTES}/:sweetenerId`;
// Sweetener Component routes
export const SWEETENER_COMPONENT_ROUTE = '/sweetener-component';
export const ADD_SWEETENER_COMPONENT_ROUTE = `${SWEETENER_COMPONENT_ROUTE}/add`;
export const EDIT_SWEETENER_COMPONENT_ROUTE = `${SWEETENER_COMPONENT_ROUTE}/update`;
export const EDIT_SWEETENER_COMPONENT_WITH_ID_ROUTE = `${SWEETENER_COMPONENT_ROUTE}/update/:id`;

// product ingredient routes
export const PRODUCT_INGREDIENT_ROUTE = '/product-ingredient';
export const ADD_PRODUCT_INGREDIENT_ROUTE = `${PRODUCT_INGREDIENT_ROUTE}/add`;
export const EDIT_PRODUCT_INGREDIENT_ROUTE = `${PRODUCT_INGREDIENT_ROUTE}/update`;
export const EDIT_PRODUCT_INGREDIENT_WITH_ID_ROUTE = `${PRODUCT_INGREDIENT_ROUTE}/update/:id`;

// product ingredient syrup type routes
export const PRODUCT_INGREDIENT_SYRUP_TYPE_ROUTE = '/product-ingredient-syrup-type';
export const ADD_PRODUCT_INGREDIENT_SYRUP_TYPE_ROUTE = `${PRODUCT_INGREDIENT_SYRUP_TYPE_ROUTE}/add`;
export const EDIT_PRODUCT_INGREDIENT_SYRUP_TYPE_ROUTE = `${PRODUCT_INGREDIENT_SYRUP_TYPE_ROUTE}/update`;
export const EDIT_PRODUCT_INGREDIENT_SYRUP_TYPE_WITH_ID_ROUTE = `${PRODUCT_INGREDIENT_SYRUP_TYPE_ROUTE}/update/:id`;

// product formulation syrup type routes
export const PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE = '/product-formulation-syrup-type';
export const ADD_PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE = `${PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE}/add`;
export const VIEW_PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE = `${PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE}/view`;
export const VIEW_PRODUCT_FORMULATION_SYRUP_TYPE_WITH_ID_ROUTE = `${PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE}/view/:id`;
export const EDIT_PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE = `${PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE}/update`;
export const EDIT_PRODUCT_FORMULATION_SYRUP_TYPE_WITH_ID_ROUTE = `${PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE}/update/:id`;

// reporting routes

const REPORT_ROUTE = '/report';
export const REPORT_USERS_ROUTE = `${REPORT_ROUTE}-users`;
export const REPORT_PLATFORM_ROUTE = `${REPORT_ROUTE}-platform`;
export const REPORT_DOSAGE_FORM_ROUTE = `${REPORT_ROUTE}-dosage-form`;
export const REPORT_INGREDIENTS_ROUTE = `${REPORT_ROUTE}-ingredients`;
// sand coating system routes
export const SAND_COATING_ROUTE = '/sand-coating';
export const ADD_SAND_COATING_ROUTE = `${SAND_COATING_ROUTE}/add`;
export const EDIT_SAND_COATING_ROUTE = `${SAND_COATING_ROUTE}/update`;
export const EDIT_SAND_COATING_WITH_ID_ROUTE = `${SAND_COATING_ROUTE}/update/:id`;

// sand coating formulation system routes
export const SAND_COATING_FORMULATION_ROUTE = '/sand-coating-formulation';
export const ADD_SAND_COATING_FORMULATION_ROUTE = `${SAND_COATING_FORMULATION_ROUTE}/add`;
export const EDIT_SAND_COATING_FORMULATION_ROUTE = `${SAND_COATING_FORMULATION_ROUTE}/update`;
export const EDIT_SAND_COATING_FORMULATION_WITH_ID_ROUTE = `${SAND_COATING_FORMULATION_ROUTE}/update/:id`;

// sand Component routes
export const SAND_COMPONENT_ROUTE = '/sand-component';
export const ADD_SAND_COMPONENT_ROUTE = `${SAND_COMPONENT_ROUTE}/add`;
export const EDIT_SAND_COMPONENT_ROUTE = `${SAND_COMPONENT_ROUTE}/update`;
export const EDIT_SAND_COMPONENT_WITH_ID_ROUTE = `${SAND_COMPONENT_ROUTE}/update/:id`;

// Coating Formulation Component routes
export const COATING_FORMULATION_COMPONENT_ROUTE = '/coating-formulation-component';
export const COATING_FORMULATION_COMPONENT_WITH_ID_ROUTE = `${COATING_FORMULATION_COMPONENT_ROUTE}/:id`;
