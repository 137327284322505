import { FC, Fragment, memo } from 'react';
import { Box, TableCell, TableRow } from '@mui/material';
// components
import TableFilters from './TableFilters';
import NoDataFound from 'components/common/NoDataFound';
import TableLoader from 'components/common/TableLoader';
import TableComponent from 'components/common/TableComponent';
import TableContainer from 'components/common/TableContainer';
// constants, styles, interfaces
import { FormulaIngredientTableProps } from 'interfaces';
import { textWhiteSpace } from 'styles/commonComponentStyle';
import { FORMULA_INGREDIENT_TABLE_HEADER, LOADING_TABLE_ROWS } from 'constants/index';

const FormulaIngredientTable: FC<FormulaIngredientTableProps> = ({
  top,
  data,
  toDate,
  loading,
  fromDate,
  dispatch,
  dosageType,
  formulationType,
}) => {
  const noData = Boolean(!loading && data?.length === 0);

  return (
    <TableContainer>
      <Box my={2} mx={3}>
        <TableFilters
          top={top}
          toDate={toDate}
          dispatch={dispatch}
          fromDate={fromDate}
          dosageType={dosageType}
          formulationType={formulationType}
        />
      </Box>

      <TableComponent
        noData
        page={1}
        count={10}
        rowsPerPage={10}
        tableHeader={FORMULA_INGREDIENT_TABLE_HEADER}>
        {loading ? (
          <TableLoader columns={5} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const { name, usage, category, subCategory, rank } = cell || {};

              return (
                <TableRow key={name}>
                  <TableCell sx={textWhiteSpace}>{rank ?? '--'}</TableCell>
                  <TableCell>{name ?? '--'}</TableCell>
                  <TableCell>{category ?? '--'}</TableCell>
                  <TableCell>{subCategory ?? '--'}</TableCell>
                  <TableCell>{usage ?? '--'}</TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default memo(FormulaIngredientTable);
