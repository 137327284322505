import { formatValue } from 'lib/helper';
import { FormulaStatus, SupplierStatus, UserStatus, UserType } from 'generated/graphql';
import {
  COST_TEXT,
  WEIGHT_TEXT,
  GENERAL_TEXT,
  METRICS_TEXT,
  ALLERGENS_TEXT,
  NUTRIENTS_TEXT,
  HEAVY_METALS_TEXT,
  COST_PERCENTAGE_TEXT,
  PACKAGE_ESTIMATOR_TEXT,
  WEIGHTS_PERCENTAGE_TEXT,
  ING_COST_CONTRIBUTION_TEXT,
  NUTRIENTS_WEIGHTS_TEXT,
  CARRIERS_TEXT,
  SUB_COMPONENT_TEXT,
} from 'constants/index';

export enum IngredientTabsEnum {
  GENERAL = 'general',
  ALLERGENS = 'allergens',
  NUTRIENTS = 'nutrients',
  HEAVY_METALS = 'heavy_metals',
  CARRIERS = 'carriers',
  SUB_COMPONENT = 'sub_component',
}

export enum FormulaTabEnum {
  COST = 'cost',
  WEIGHTS = 'weights',
  COST_PERCENTAGE = 'cost_percentage',
  WEIGHTS_PERCENTAGE = 'weight_percentage',
  ING_COST_CONTRIBUTION = 'ing_cost_contribution',
  NUTRIENTS_WEIGHTS = 'nutrients_weights',
}

export enum FormulaMetricsEnum {
  COST = 'cost',
  PACKAGE = 'weights',
}

export const FORMULA_COST_TABS = [
  { name: PACKAGE_ESTIMATOR_TEXT, value: FormulaMetricsEnum.PACKAGE },
  { name: METRICS_TEXT, value: FormulaMetricsEnum.COST },
];

export const FORMULA_INFO_TABS = [
  { name: COST_TEXT, value: FormulaTabEnum.COST },
  { name: WEIGHT_TEXT, value: FormulaTabEnum.WEIGHTS },
  { name: COST_PERCENTAGE_TEXT, value: FormulaTabEnum.COST_PERCENTAGE },
  { name: WEIGHTS_PERCENTAGE_TEXT, value: FormulaTabEnum.WEIGHTS_PERCENTAGE },
  { name: ING_COST_CONTRIBUTION_TEXT, value: FormulaTabEnum.ING_COST_CONTRIBUTION },
  { name: NUTRIENTS_WEIGHTS_TEXT, value: FormulaTabEnum.NUTRIENTS_WEIGHTS },
];

export const INGREDIENT_TABS = [
  { name: GENERAL_TEXT, value: IngredientTabsEnum.GENERAL },
  { name: NUTRIENTS_TEXT, value: IngredientTabsEnum.NUTRIENTS },
  { name: HEAVY_METALS_TEXT, value: IngredientTabsEnum.HEAVY_METALS },
  { name: ALLERGENS_TEXT, value: IngredientTabsEnum.ALLERGENS },
  { name: CARRIERS_TEXT, value: IngredientTabsEnum.CARRIERS },
  { name: SUB_COMPONENT_TEXT, value: IngredientTabsEnum.SUB_COMPONENT },
];

export const STAFF_TABS = [
  { name: formatValue(UserStatus.Active), value: UserStatus.Active },
  { name: formatValue(UserStatus.Inactive), value: UserStatus.Inactive },
  { name: 'All', value: 'all' },
];

export const SUPPLIER_TABS = [
  { name: formatValue(SupplierStatus.Active), value: SupplierStatus.Active },
  { name: formatValue(SupplierStatus.Inactive), value: SupplierStatus.Inactive },
  { name: 'All', value: 'all' },
];

export const USER_TABS = [
  { name: formatValue(UserStatus.Active), value: UserStatus.Active },
  { name: formatValue(UserStatus.Inactive), value: UserStatus.Inactive },
  { name: 'All', value: 'all' },
];

export const USER_REPORT_TABS = [
  { name: 'All', value: 'all' },
  { name: formatValue(UserType.Admin), value: UserType.Admin },
  { name: formatValue(UserType.Staff), value: UserType.Staff },
];

export const FORMULA_TABS = [
  { name: 'Active', value: FormulaStatus.Active },
  { name: 'Inactive', value: FormulaStatus.Inactive },
  { name: 'Draft', value: FormulaStatus.Draft },
  { name: 'All', value: 'all' },
];

// Define a common enum for Active and Inactive states
export enum TabEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  All = 'ALL',
}

// Define a common array for Active and Inactive states
export const TABS = [
  { name: 'Active', value: TabEnum.Active },
  { name: 'Inactive', value: TabEnum.Inactive },
  { name: 'All', value: TabEnum.All },
];
