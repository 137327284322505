import { FC } from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
// components
import Header from '../components/Pdf/Header';
import TableHeader from '../components/Pdf/TableHeader';
// interfaces, constants, styles
import {
  TOTAL_TEXT,
  NUMBERS_TEXT,
  FORMULAS_TEXT,
  DATE_RANGE_TEXT,
  DOSAGE_FORM_TEXT,
  ORGANIZATION_TEXT,
  TOTAL_FORMULAS_TEXT,
  FORMULATION_TYPES_TEXT,
  DOSAGE_FORM_REPORT_TEXT,
  DOSAGE_FORM_BREAKDOWN_TEXT,
} from 'constants/index';
import { DosagePdfProps } from 'interfaces';
import { pageSize, pdfStyles } from 'styles/PdfStyles';
import { chartPieColors } from '../components/Charts/utils';

const DosagePdf: FC<DosagePdfProps> = ({
  data,
  total,
  dateRange,
  pieCanvas,
  formCanvas,
  dosageCanvas,
  productTypeName,
  organizationName,
  formulationCanvas,
  subProductTypeName,
}) => {
  return (
    <Document title={DOSAGE_FORM_REPORT_TEXT}>
      {/* 1st page */}
      <Page size={pageSize} style={pdfStyles.page}>
        <Header title={DOSAGE_FORM_REPORT_TEXT} />
        <View style={pdfStyles.container}>
          {/* user filters */}
          <View style={pdfStyles.table}>
            <TableHeader headers={[DATE_RANGE_TEXT, ORGANIZATION_TEXT]} />
            <View style={pdfStyles.trBody}>
              <View style={pdfStyles.td}>
                <Text style={pdfStyles.tdText}>{dateRange}</Text>
              </View>
              <View style={pdfStyles.tdLast}>
                <Text style={pdfStyles.tdText}>{organizationName}</Text>
              </View>
            </View>
          </View>

          {/* dosage form pie chart + stats container */}
          <View style={pdfStyles.flexRow}>
            {/* dosage form pie chart */}
            <View style={[pdfStyles.border1, pdfStyles.flex1]}>
              <View style={[pdfStyles.flexRow, pdfStyles.p10]}>
                {pieCanvas && (
                  <View style={pdfStyles.flex1}>
                    <View style={pdfStyles.mb10}>
                      <View>
                        <Text style={pdfStyles.thText}>{DOSAGE_FORM_TEXT} %</Text>
                      </View>
                    </View>
                    <Image src={pieCanvas} style={pdfStyles.graph} />
                  </View>
                )}
                <View style={[pdfStyles.flex1, pdfStyles.ml5]}>
                  {/* total formulas table */}
                  <View style={pdfStyles.table}>
                    <TableHeader headers={[TOTAL_FORMULAS_TEXT]} />

                    <View style={pdfStyles.trBody}>
                      <View style={pdfStyles.tdLast}>
                        <Text style={pdfStyles.tdText}>{total}</Text>
                      </View>
                    </View>
                  </View>

                  {/* dosage form pie chart labels */}
                  <View style={[pdfStyles.w100, pdfStyles.flexRow, pdfStyles.flexWrap]}>
                    {data?.map((item, index) => {
                      const { name } = item || {};
                      return (
                        <View style={pdfStyles.w50}>
                          <View style={[pdfStyles.mb5, pdfStyles.flexCenter]}>
                            <View
                              style={[
                                pdfStyles.circleIcon,
                                {
                                  backgroundColor: chartPieColors[index],
                                },
                              ]}
                            />
                            <View>
                              <Text style={pdfStyles.tdText}>{name || ''} </Text>
                            </View>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            </View>

            {/* formula stats table */}
            <View style={[pdfStyles.ml10, pdfStyles.flex1]}>
              <View style={pdfStyles.table}>
                {data?.map((item, index) => {
                  const isLast = index === data.length - 1;
                  const { count, name } = item || {};
                  return (
                    <View style={pdfStyles.trBody}>
                      <View style={isLast ? pdfStyles.thVLast : pdfStyles.thV}>
                        <Text style={pdfStyles.thText}>{`${TOTAL_TEXT} ${name || ''} ${FORMULAS_TEXT}`}</Text>
                      </View>
                      <View style={isLast ? pdfStyles.tdVLast : pdfStyles.tdV}>
                        <Text style={pdfStyles.tdText}>{count || ''}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>

          <View style={[pdfStyles.flexRow, pdfStyles.mt10]}>
            {/* dosage form numbers */}
            <View style={[pdfStyles.border1, pdfStyles.p10, pdfStyles.flex1]}>
              <View style={pdfStyles.mb10}>
                <View>
                  <Text style={pdfStyles.thText}>{`${DOSAGE_FORM_TEXT} (${NUMBERS_TEXT})`}</Text>
                </View>
              </View>
              {formulationCanvas && <Image src={formulationCanvas} style={pdfStyles.graph} />}
            </View>

            {/* dosage formulation types */}
            <View style={[pdfStyles.ml10, pdfStyles.border1, pdfStyles.p10, pdfStyles.flex1]}>
              <View style={pdfStyles.mb10}>
                <View>
                  <Text style={pdfStyles.thText}>{FORMULATION_TYPES_TEXT}</Text>
                </View>
              </View>
              {formCanvas && <Image src={formCanvas} style={pdfStyles.graph} />}
            </View>
          </View>

          {/* dosage form breakdown */}
          <View style={[pdfStyles.border1, pdfStyles.p10, pdfStyles.mt10]}>
            <View style={pdfStyles.graphHeader}>
              <View>
                <Text style={pdfStyles.thText}>{DOSAGE_FORM_BREAKDOWN_TEXT}</Text>
              </View>

              <View>
                <Text style={pdfStyles.tdText}>
                  {productTypeName}
                  {subProductTypeName ? `, ${subProductTypeName}` : ''}
                </Text>
              </View>
            </View>
            {dosageCanvas && <Image src={dosageCanvas} style={pdfStyles.graph} />}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DosagePdf;
