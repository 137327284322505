import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
// components
import PieChart from '../components/Charts/PieChart';
import DataLoader from 'components/common/DataLoader';
import NoDataFound from 'components/common/NoDataFound';
import LegendLabel from '../components/Charts/LegendLabel';
import IngredientSelect from 'components/common/simpleSelectors/Ingredient';
// constants, interfaces, styles, utils, graphql
import { chartPieColors } from '../components/Charts/utils';
import { ActionType } from 'reducer/ingredientReportReducer';
import { SelectType, UsageGraphCardProps } from 'interfaces';
import { DosageFormulasPayload, useGetDosageFormulasCountLazyQuery } from 'generated/graphql';
import { HTTP_STATUS, USAGE_GRAPH_TEXT, PLEASE_SELECT_INGREDIENT, INGREDIENT_TEXT } from 'constants/index';

const UsageGraphCard = forwardRef<ChartJSOrUndefined<'pie'>, UsageGraphCardProps>(
  ({ dispatch, ingredient, supplierId, organizationId }, ref) => {
    const [data, setData] = useState<DosageFormulasPayload['data']>([]);
    const { value: ingredientId } = ingredient || {};

    const [getDosageFormulasCount, { loading }] = useGetDosageFormulasCountLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { getDosageFormulasCount } = data;
        const { response, data: formulas } = getDosageFormulasCount || {};
        const { status } = response || {};
        if (status === HTTP_STATUS.SUCCESS) {
          setData(formulas as DosageFormulasPayload['data']);
        } else {
          resetPage();
        }
      },

      onError: () => {
        resetPage();
      },
    });

    const { dataSet, labels, total } = useMemo(() => {
      if (!data?.length) {
        return { labels: [], dataSet: [], total: 0 };
      }

      let total = 0;
      const labels: string[] = [];
      const dataSet: number[] = [];

      data.forEach((item) => {
        const { name = '', count = 0 } = item || {};
        labels.push(name);
        dataSet.push(count);
        total += count;
      });
      dispatch({ type: ActionType.SET_LABELS, labels });

      return { labels, dataSet, total };
    }, [data, dispatch]);

    const resetPage = () => {
      setData([]);
    };

    const handleIngredientChange = (item: SelectType) => {
      dispatch({ type: ActionType.SET_INGREDIENT_USAGE, ingredientUsage: item });
    };

    const fetchDosageFormulasCount = useCallback(async () => {
      if (ingredientId) {
        await getDosageFormulasCount({
          variables: {
            getDosageFormulasCountInput: {
              ingredientId,
              ...(supplierId && { supplierId }),
              ...(organizationId && { organizationId }),
            },
          },
        });
      } else {
        setData([]);
      }
    }, [getDosageFormulasCount, ingredientId, organizationId, supplierId]);

    useEffect(() => {
      fetchDosageFormulasCount();
    }, [fetchDosageFormulasCount]);

    return (
      <Card>
        <CardHeader title={USAGE_GRAPH_TEXT} />
        <CardContent>
          <Box mb={2}>
            <IngredientSelect
              isClearable
              name="ingredient"
              title={INGREDIENT_TEXT}
              handleChange={handleIngredientChange}
              value={ingredient}
            />
          </Box>
          {ingredientId ? (
            loading ? (
              <DataLoader columns={12} rows={1} height={300} />
            ) : (
              <>
                <PieChart dataSet={dataSet} labels={labels} total={total} ref={ref} />
                <Box>
                  {/* legends */}
                  <Grid container spacing={2} mt={2}>
                    {labels?.map((label, index) => (
                      <Grid item xs={6} key={index}>
                        <LegendLabel label={label} bgColor={chartPieColors[index]} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </>
            )
          ) : (
            <NoDataFound noData description={PLEASE_SELECT_INGREDIENT} />
          )}
        </CardContent>
      </Card>
    );
  },
);

export default memo(UsageGraphCard);
