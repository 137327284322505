import { FC } from 'react';
import { Page, View, Document, Image, Text, Font } from '@react-pdf/renderer';
// components
import Header from '../components/Pdf/Header';
import TableHeader from '../components/Pdf/TableHeader';
// interfaces, constants, styles
import { UserPdfProps } from 'interfaces';
import { pageSize, pdfStyles, userPdfStyles } from 'styles/PdfStyles';
import { formatTimeStamp, handleLongWord } from 'lib/helper';
import {
  ROLE_TEXT,
  NAME_TEXT,
  EMAIL_TEXT,
  USERS_TEXT,
  MOST_USERS_TEXT,
  DATE_RANGE_TEXT,
  TOTAL_STAFF_TEXT,
  USERS_REPORT_TEXT,
  JOINING_DATE_TEXT,
  ORGANIZATION_TEXT,
  TOTAL_ORG_ADMINS_TEXT,
  ORGANIZATION_NAME_TEXT,
  TOTAL_ACTIVE_USERS_TEXT,
  YEAR_TEXT,
} from 'constants/index';

Font.registerHyphenationCallback((text) => {
  return handleLongWord(text);
});

const UserPdf: FC<UserPdfProps> = ({
  year,
  userData,
  mostUsers,
  dateRange,
  userStats,
  userCanvas,
  organizationName,
}) => {
  const { activeUsers, orgAdmins, staff } = userStats || {};
  return (
    <Document title={USERS_REPORT_TEXT}>
      <Page size={pageSize} style={pdfStyles.page}>
        <Header title={USERS_REPORT_TEXT} />

        <View style={pdfStyles.container}>
          {/* user filters */}
          <View style={pdfStyles.table}>
            <TableHeader headers={[DATE_RANGE_TEXT, ORGANIZATION_TEXT]} />
            <View style={pdfStyles.trBody}>
              <View style={pdfStyles.td}>
                <Text style={pdfStyles.tdText}>{dateRange}</Text>
              </View>
              <View style={pdfStyles.tdLast}>
                <Text style={pdfStyles.tdText}>{organizationName}</Text>
              </View>
            </View>
          </View>

          {/* user stats */}
          <View style={pdfStyles.table}>
            <TableHeader headers={[TOTAL_ACTIVE_USERS_TEXT, TOTAL_ORG_ADMINS_TEXT, TOTAL_STAFF_TEXT]} />
            <View style={pdfStyles.trBody}>
              <View style={pdfStyles.td}>
                <Text style={pdfStyles.tdText}>{activeUsers}</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text style={pdfStyles.tdText}>{orgAdmins}</Text>
              </View>
              <View style={pdfStyles.tdLast}>
                <Text style={pdfStyles.tdText}>{staff}</Text>
              </View>
            </View>
          </View>

          {/* user graph */}
          <View style={userPdfStyles.container}>
            <View style={userPdfStyles.graphContainer}>
              <View style={pdfStyles.graphHeader}>
                <View>
                  <Text style={pdfStyles.thText}>{USERS_TEXT}</Text>
                </View>

                <View>
                  <Text style={pdfStyles.tdText}>{YEAR_TEXT}</Text>
                  <Text style={pdfStyles.tdText}>{year}</Text>
                </View>
              </View>
              <Image src={userCanvas} style={pdfStyles.graph} />
            </View>
            <View style={pdfStyles.flex1}>
              <View style={pdfStyles.table}>
                <TableHeader headers={[MOST_USERS_TEXT]} />
                {mostUsers?.map((item) => {
                  const { name, usersCount } = item || {};

                  return (
                    <View style={pdfStyles.trBody}>
                      <View style={pdfStyles.td}>
                        <Text style={pdfStyles.tdText}>{name || ''}</Text>
                      </View>

                      <View style={pdfStyles.tdLast}>
                        <Text style={pdfStyles.tdText}>{usersCount || ''}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>

          {/* user table */}
          <View style={pdfStyles.table}>
            <TableHeader
              headers={[NAME_TEXT, ROLE_TEXT, EMAIL_TEXT, JOINING_DATE_TEXT, ORGANIZATION_NAME_TEXT]}
            />
            {userData?.map((user) => {
              const { firstName, lastName, email, createdAt, organization, userRoles } = user || {};
              const { name: orgName } = organization || {};
              const name = `${firstName} ${lastName}`;
              const role = userRoles
                ?.map((item) => {
                  const { role } = item || {};
                  const { name } = role || {};
                  return name || '';
                })
                ?.join(', ');
              const joiningDate = formatTimeStamp(createdAt, 'MMMM DD, yyyy');

              return (
                <View style={pdfStyles.trBody}>
                  <View style={pdfStyles.td}>
                    <Text style={pdfStyles.tdText}>{name || ''}</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text style={pdfStyles.tdText}>{role || ''}</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text style={pdfStyles.tdText}>{`${email || ''}`}</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text style={pdfStyles.tdText}>{joiningDate || ''}</Text>
                  </View>
                  <View style={pdfStyles.tdLast}>
                    <Text style={pdfStyles.tdText}>{orgName || ''}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default UserPdf;
