// imports
import { FC, useCallback, useMemo, useState } from 'react';
// components
import InfiniteSelect from './InfiniteSelect';
// interfaces, constants, graphql
import { HTTP_STATUS } from 'constants/index';
import { SelectType, SupplierSelectProps } from 'interfaces';
import { SuppliersPayload, useFindAllSuppliersSelectLazyQuery } from 'generated/graphql';

const SupplierSelect: FC<SupplierSelectProps> = ({
  name,
  title,
  value,
  handleChange,
  margin = 'dense',
  isClearable = false,
}) => {
  const [options, setOptions] = useState<SuppliersPayload['data']>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [getLoading, setGetLoading] = useState<boolean>(false);

  const updatedOptions = useMemo(() => {
    if (options?.length) {
      return options?.map<SelectType>((item) => {
        const { id, name } = item || {};

        return {
          value: id,
          name: name || '',
        };
      });
    }
    return [];
  }, [options]);

  const [fetchSelectorData, { loading }] = useFindAllSuppliersSelectLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllSuppliers } = data;
      const { data: suppliers, response, pagination } = findAllSuppliers || {};
      const { status } = response || {};

      if (status === HTTP_STATUS.SUCCESS) {
        const { totalPages: pages } = pagination || {};
        setTotalPages(pages);
        setOptions([
          ...(getLoading ? [] : options || []),
          ...((suppliers as SuppliersPayload['data']) ?? []),
        ]);
        setGetLoading(false);
      } else {
        setGetLoading(false);
      }
    },
    onError: () => {
      setGetLoading(false);
      setOptions([]);
    },
  });

  const fetchData = useCallback(
    async (page?: number, query?: string) => {
      if (!page) setGetLoading(true);
      await fetchSelectorData({
        variables: {
          findAllSuppliersInput: {
            ...(query && { search: query }),
            paginationOptions: { page: page || 1, limit: 10 },
          },
        },
      });
    },
    [fetchSelectorData],
  );

  return (
    <InfiniteSelect
      name={name}
      title={title}
      value={value}
      margin={margin}
      loading={loading}
      setOptions={setOptions}
      totalPages={totalPages}
      getLoading={getLoading}
      options={updatedOptions}
      isClearable={isClearable}
      handleChange={handleChange}
      fetchData={fetchData}
    />
  );
};

export default SupplierSelect;
